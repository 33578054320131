import { v4 as uuidv4 } from 'uuid';
import { IPRFIItem, IRFIInReviewStep } from "../api-generated";
import RFIRejectDetails from "./rfiRejectDetail";
import RFIUpdateBase from "./rfiUpdateBase";
import RFIStatus from "./rfiStatus";

export default class RFIInReviewStep
  extends RFIUpdateBase
  implements IRFIInReviewStep
{
  public QAQCCompliance?: boolean;
  public InternalDueDate?: string | null;
  public DueDate?: string | null;
  public ManagerId?: string | null;
  public CoordinatorId?: string | null;
  public rfi_reject_detail?: RFIRejectDetails;
  public rfi_reviewer?: string[];
  public rfi_watcher?: string[];
  public tempId?: string;

  constructor(contractId: string, item: IPRFIItem) {
    super(contractId, item);
    const isQaqc = item.Status === RFIStatus.Submitted;
    this.ManagerId = !isQaqc ? undefined : item.ManagerId;
    this.CoordinatorId = !isQaqc ? undefined : item.CoordinatorId;
    this.InternalDueDate = isQaqc ? undefined : item.InternalDueDate;
    this.DueDate = isQaqc ? undefined : item.DueDate;
    this.QAQCCompliance = item.IsDraft === false ? true : item.QAQCCompliance !== false;
    this.rfi_reviewer =
      item.rfi_reviewer?.map((i) => {
        return i.ReviewerId ?? "";
      }) ?? [];

    this.rfi_watcher =
      item.rfi_watcher?.map((i) => {
        return i.WatcherId ?? "";
      }) ?? [];

    if (!this.rfi_reject_detail) {
      this.tempId = uuidv4();
      this.rfi_reject_detail = new RFIRejectDetails();
    }
    
  }
}
