/* eslint-disable import/no-extraneous-dependencies */
import { Inject, Injectable } from "@angular/core";
import {
  MsalBroadcastService,
  MsalService,
  MSAL_INSTANCE,
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionStatus,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { BehaviorSubject, Observable, from } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { UserService } from "src/app/api-generated";
import LocalStorageService from "src/app/services/local-storage.service";
import environment from "src/environments/environment";
import LoginUser from "../../models/loginUser";

@Injectable({
  providedIn: "root",
})
export default class AuthService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);

  loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

  private activePermissionSubject = new BehaviorSubject<number>(0);

  activePermission: Observable<number> =
    this.activePermissionSubject.asObservable();

  private currentUserSubject = new BehaviorSubject<LoginUser>(null);

  currentUser$: Observable<LoginUser> = this.currentUserSubject.asObservable();

  public user: LoginUser;

  public attemptedUrl: string;

  public token: string = null;

  private reloading = false;

  private loginInProcess = false;

  constructor(
    @Inject(MSAL_INSTANCE) private config: IPublicClientApplication,
    private msalService: MsalService,
    private localStorageService: LocalStorageService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService,
  ) {
    this.loggedInSubject.next(
      this.msalService.instance.getActiveAccount() !== null,
    );
    this.msalBroadcastService.msalSubject$.subscribe((val) => {
      if (
        val.eventType === EventType.SSO_SILENT_FAILURE ||
        val.eventType === EventType.ACQUIRE_TOKEN_FAILURE ||
        val.eventType === EventType.LOGIN_FAILURE
      ) {
        from(this.getToken(true)).pipe(mergeMap(this.setUser.bind(this)));
      } else if (
        val.eventType === EventType.LOGIN_SUCCESS &&
        (val.payload as AuthenticationResult).account
      ) {
        const { account } = val.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(account);
        this.setUser();
      } else if (val.eventType === EventType.HANDLE_REDIRECT_END) {
        this.setUser();
      }
    });
  }

  public getUser(): Observable<LoginUser> {
    if (!this.user) {
      localStorage.clear();
      const account = this.getAccount();
      if (account && !AuthService.isExpired(account)) {
        return this.setUser();
      }
      if (
        !this.loginInProcess &&
        (
          this.msalBroadcastService.inProgress$
            .source as BehaviorSubject<InteractionStatus>
        ).value !== InteractionStatus.HandleRedirect
      ) {
        return from(this.getToken()).pipe(mergeMap(this.setUser.bind(this)));
      }
    }

    return from(this.waitForLogin().then(() => this.user));
  }

  async login(): Promise<LoginUser | void> {
    // console.log(this.user);
    if (!this.user) {
      this.msalBroadcastService.inProgress$.pipe(
        map((status: InteractionStatus) => {
          if (status === InteractionStatus.None) {
            this.getUser.bind(this);
          }
        }),
      );
    }

    await this.waitForLogin();
    return Promise.resolve(this.user);
  }

  private getAccount(): AccountInfo {
    return this.msalService.instance
      .getAllAccounts()
      .filter((a) => a.tenantId === environment.azureNew.tenantId)[0];
  }

  private static isExpired(account: AccountInfo, marginSeconds = 60): boolean {
    const expirationDateWithMargin = new Date(
      ((account.idTokenClaims as { exp: number }).exp - 60) * 1000,
    );
    const currentDate = new Date();
    return currentDate > expirationDateWithMargin;
  }

  private static getPermission(account: AccountInfo) {
    return (
      account.idTokenClaims as unknown as {
        roles: string[];
      }
    ).roles;
  }

  private setUser(): Observable<LoginUser> {
    const account = this.getAccount();
    const permission = AuthService.getPermission(account);

    return from(
      this.getToken().then((token) => {
        return this.userService
          .getUser(account.localAccountId)
          .toPromise()
          .then((user) => {
            if (user) {
              let firstName = user.givenName;
              let lastName = user.surname;

              if (user.surname === null || user.givenName == null) {
                if (user.displayName.includes(",")) {
                  const fullName = user.displayName.split(", ");

                  [lastName, firstName] = fullName;
                } else {
                  const email = user.mail.split("@")[0];
                  const fullName = email.split(".");

                  if (email.includes(".")) {
                    [firstName, lastName] = fullName;
                  } else {
                    firstName = "??";
                    lastName = "??";
                  }
                }
              }

              this.user = new LoginUser(
                firstName,
                lastName,
                user.id,
                user.mail,
                user.companyName,
                (permission as string[]).join(),
              );
            } else {
              this.user = null;
            }

            return this.user;
          });
      }),
    );
  }

  logout(): void {
    this.msalService.logoutPopup({
      mainWindowRedirectUri: "/sign-out",
    });
    this.clearUser();
  }

  private clearUser(): void {
    this.user = null;
    this.loggedInSubject.next(false);
    this.localStorageService.clearLocalStorage();
  }

  getToken(redirect?: boolean): Promise<string> {
    if (redirect && !this.reloading) {
      this.reloading = true;
    }

    const account = this.getAccount();

    const mapToken = (authResult: AuthenticationResult) => {
      this.token = authResult ? authResult.accessToken : null;

      if (environment.url.submittal.includes("aecominnovationstudio")) {
        console.log(this.token);
      }

      return this.token as unknown as string;
    };

    const loginRedirect = () => {
      return this.msalService
        .loginRedirect({
          scopes: [environment.azureNew.scope],
        })
        .pipe(
          mergeMap(() => {
            this.loginInProcess = false;
            return this.msalService
              .acquireTokenSilent({
                scopes: [environment.azureNew.scope],
                account: this.getAccount(),
              })
              .pipe(map(mapToken));
          }),
        );
    };

    const cleanLogin = (error: Error | null) => {
      while (
        this.loginInProcess &&
        (
          this.msalBroadcastService.inProgress$
            .source as BehaviorSubject<InteractionStatus>
        ).value !== InteractionStatus.HandleRedirect
      ) {
        this.sleep(500);
      }
      if (error instanceof InteractionRequiredAuthError || error === null) {
        this.clearUser();
        this.loginInProcess = true;
        return loginRedirect();
      }
      if (error instanceof InteractionRequiredAuthError) {
        console.error("An error occurred. Please try again.", error.message);
      }
      return null;
    };

    if (account && !AuthService.isExpired(account)) {
      return this.waitForRedirectComplete().then(() => {
        return this.msalService
          .acquireTokenSilent({
            scopes: [environment.azureNew.scope],
            account,
          })
          .pipe(map(mapToken), catchError(cleanLogin))
          .toPromise();
      });
    }
    if (account === undefined) {
      return loginRedirect().toPromise();
    }
    return cleanLogin(null).toPromise();
  }

  private sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public async waitForLogin(): Promise<void> {
    if (typeof this.user !== "undefined") {
      // console.log('User Found');
      return Promise.resolve();
    }
    // const keys = Object.keys(localStorage);
    // const severErrorKey = keys.find((key) => {
    //   return key.includes("server-telemetry");
    // });
    // if (severErrorKey) {
    //   const value = JSON.parse(localStorage[severErrorKey]);
    //   if (value.cacheHits && parseInt(value.cacheHits, 10) > 1) {
    //     localStorage.clear();
    //   }
    // }

    await this.sleep(100);
    await this.waitForLogin();
  }

  public isTokenExpired(): boolean {
    if (!this.token || typeof this.token !== "string") return true;

    let isJwtExpired = false;
    const decoded = jwtDecode<JwtPayload>(this.token);
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > decoded.exp) isJwtExpired = true;

    return isJwtExpired;
  }

  public async waitForToken(): Promise<void> {
    if (!this.isTokenExpired()) {
      // console.log('User Found');
      return Promise.resolve();
    }

    if (!this.loginInProcess) {
      await this.getToken();
    } else {
      await this.sleep(500);
      await this.waitForLogin();
    }
  }
  // public async waitForToken(): Promise<void> {
  //   if (this.token !== undefined) {
  //       // console.log('User Found');
  //       return Promise.resolve();
  //   }
  //   else{
  //       // console.log('Waiting for User...');
  //       await this.sleep(100);
  //       await this.waitForLogin();
  //   }
  // }

  public async waitForRedirectComplete(): Promise<void> {
    if (
      !this.loginInProcess &&
      (
        this.msalBroadcastService.inProgress$
          .source as BehaviorSubject<InteractionStatus>
      ).value !== InteractionStatus.HandleRedirect
    ) {
      // console.log('Done Redirecting');
      return Promise.resolve();
    }
    // console.log('Waiting for Redirect completion...');
    await this.sleep(100);
    await this.waitForRedirectComplete();
  }
}
