/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable prettier/prettier */
import { FileModel, ModalContainerService } from "@aecom/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { IPRFIDocumentDownload, IPRFIItem, IUser, RFIItemService } from "src/app/api-generated";
import FileListType from "src/app/models/fileListType";
import IRFIViewPrepare from "src/app/models/IRFIViewPrepaer";
import RFIFile from "src/app/models/rfiFile";
import RFIRole from "src/app/models/rfiRoles";
import RFIStatus from "src/app/models/rfiStatus";
import RFIUpdateBase from "src/app/models/rfiUpdateBase";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import LocalUserService from "src/app/services/local-user.service";
import RouteParams from "src/app/shared/route-params";
import { rfiAppId } from "src/app/shared/staticValue";
import { getDocumentsforFinalResponse, getStatusBeforeReopen, isDB, prepareMutiLineText } from "src/app/shared/utils";
import LoadingService from "../../../services/loading.service";
import AuthService from "../../auth/auth.service";
import BaseFunctionService from "@services/baseFunction.service";
import RFIRecallApprovalStep from "@models/rfiRecallApprovalStep";
import RFIReopenDetail from "@models/rfiReopenDetail";

@Component({
  selector: "app-review",
  templateUrl: "./view-rfi.component.html",
  styleUrls: ["./view-rfi.component.scss"]
})
export default class ViewRFIComponent implements OnInit {

  rfi: IPRFIItem;

  rfiDocuments: IPRFIDocumentDownload[];

  users: IUser[];

  contractId: string;

  currentUser: string;

  rfiFiles: RFIFile[] = [];

  fileListType: FileListType = FileListType.View;

  IsDbAdmin = true;

  IsDocControl = false;

  showPrint = false;

  IsReject = false;

  IsCancel = false;

  isRecalled = false;

  IsResponded = false;

  rejectedBy = "";

  rejectedNotes = "";

  docFiles: FileModel[] = [];

  showRecallButton = false;

  note = "";

  response: RFIRecallApprovalStep;

  getFileIcon = ViewRFIComponent.getFileIcon;

  showReport = false;

  showReassignBtn = false;

  defaultTab = "details";

  showReopenBtn = false;

  reopenItem: RFIReopenDetail;

  showReopenItem = false;

  showReopenAcknowledge = false;

  acknowledge = false;

  reason: string | null = null;

  reasonMaxLength = 2000;

  attemptToIssue = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public loadingService: LoadingService,
    public activemodal: ModalContainerService,
    public rfiItemService: RFIItemService,
    private localContractUserService: LocalContractUserService,
    private localUserService: LocalUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.users = this.localUserService.getItems();
    const data: IRFIViewPrepare = this.activatedRoute.snapshot.data.RFIPrepare;
    this.rfi = data.rfi;
    this.contractId = this.activatedRoute.snapshot.parent.params[RouteParams.ContractId];
    this.rfiDocuments = data.rfiDocuments;
    this.currentUser = this.authService.user.Id;
    this.docFiles = getDocumentsforFinalResponse(this.rfiDocuments)
      .map((item) => {
        console.log("item", item.DownloadURL);

        return new FileModel(
          item.Guid,
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL
        );
      });
    const role = this.localContractUserService.currentUserContractRole;
    this.IsDbAdmin = isDB(role);
    this.IsDocControl = role === RFIRole.Doc_Control;
    this.showPrint = data.rfi.Status === RFIStatus.Responded;
    this.IsResponded = getStatusBeforeReopen(this.rfi) === RFIStatus.Responded;
    if(this.IsResponded)
    {
      this.rfi.OfficialResponse = prepareMutiLineText(this.rfi.OfficialResponse);
    }
    this.showRecallButton =
      (this.IsDbAdmin && this.rfi.IssueBy === this.authService.user.Id) &&
      !this.rfi.IsRecalled &&
      !(
        this.rfi.Status === RFIStatus.Responded ||
        this.rfi.Status === RFIStatus.Request_to_Reopen ||
        this.rfi.Status === RFIStatus.Canceled ||
        this.rfi.Status === RFIStatus.Draft || 
        this.rfi.Status === RFIStatus.QAQC_Fail ||
        this.rfi.Status === RFIStatus.PM_Review ||
        this.rfi.Status === RFIStatus.SPM_Review || 
        this.rfi.Status === RFIStatus.DDC_Review 
      );

      const reassignStatus: string[] = [
        RFIStatus.Distribution, 
        RFIStatus.Review_Period, 
        RFIStatus.PM_Review, 
        RFIStatus.SPM_Review,
        RFIStatus.DDC_Review
      ]

      this.showReassignBtn = 
        (role === RFIRole.Doc_Control || role === RFIRole.Coordinator) && 
        reassignStatus.findIndex((s)=>{return s===this.rfi.Status})>=0 && this.rfi.IsRecalled !== true
      console.log("role", role);

      this.showReopenBtn = this.isEndStep(this.rfi.Status) && this.hasPermissionToReopen();

    const showRejectStatusArr: string[] = [RFIStatus.QAQC_Fail, RFIStatus.Canceled, RFIStatus.Superseded]
    if (
      showRejectStatusArr.includes(this.rfi.Status) &&
      this.rfi.rfi_reject_detail &&
      this.rfi.rfi_reject_detail.length > 0
    ) {
      this.IsReject = this.rfi.Status === RFIStatus.QAQC_Fail;
      this.IsCancel = !this.IsReject;
      const lastReject = this.rfi.rfi_reject_detail.sort(
        (a, b) =>
          new Date(b.DateUpdated).getTime() -
          new Date(a.DateUpdated).getTime(),
      )[0];
      const rejectBy = this.localContractUserService.getItems().find((cu)=>{return cu.UserId === lastReject.UpdatedBy});
      this.rejectedBy = rejectBy?.contract_user_application_role.find((a)=>{return a.ApplicationId === rfiAppId})?.application_role?.Role ?? "";
      this.rejectedNotes = prepareMutiLineText(lastReject.Note);
    }

    if(this.IsDbAdmin && 
      this.rfi.rfi_reopen && 
      this.rfi.rfi_reopen.length>0)
    {
      const latestReopen = this.rfi.rfi_reopen.sort((a,b)=>(b.DateRequest?new Date(b.DateRequest).getTime():0)-(a.DateRequest?new Date(a.DateRequest).getTime():0))[0];
      if(!this.isEndStep(this.rfi.Status))
      {
        this.showReopenItem = true;
        this.reopenItem = new RFIReopenDetail(latestReopen);
      } else {
        this.showReopenAcknowledge = latestReopen.ResponseBy === null;
      }
      
    }

    if (this.IsDbAdmin && this.rfi.Status === RFIStatus.Responded && this.rfi.Viewed !== true) {
      const item = new RFIUpdateBase(this.contractId, this.rfi);
      this.baseFunctionService.dbViewRFI();
    }

    if (
      (this.rfi.IsRecalled || this.rfi.Status === RFIStatus.Canceled) &&
      this.rfi.rfi_recall &&
      this.rfi.rfi_recall.length > 0
    ) {
      this.isRecalled = true;
      this.rfi.ShowRecallRejected = false;

      const lastRecall = this.rfi.rfi_recall.sort(
        (a, b) => new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime()
      )[0];
      this.response = new RFIRecallApprovalStep(this.contractId, this.rfi, lastRecall);
      this.note = prepareMutiLineText(lastRecall.Notes);
    }

    if (this.IsDbAdmin && this.rfi.IssueBy === this.authService.user.Id && this.rfi.ShowRecallRejected && !this.rfi.IsRecalled) {
      this.baseFunctionService.dbReadReject();
    }
    this.loadingService.stop();
  }

  back(): void {
    this.baseFunctionService.back();
  }

  voidRFI(): void {
    this.baseFunctionService.dbVoidRFI();
  }

  reviseRFI(): void {
    this.baseFunctionService.reviseRFI();
  }

  static getFileIcon(type: string): string {
    return type.includes("image") ? " icon icon-image_doc" : " icon icon-blank_doc";
  }

  recallRFI(): void {
    this.baseFunctionService.recallRFI();
  }

  submitRFI(): void {
    this.loadingService.start();
    this.baseFunctionService.update(this.response);
  }

  setAcceptRecall(e: boolean): void {
    this.response.ShowRecallRejected = e;
  }

  generateReport(): void {
    this.showReport = true;
  }

  closeReport(): void {
    this.showReport = false;
  }

  reassign(): void {
    this.baseFunctionService.reassign();
  }

  reopen(): void {
    //this.baseFunctionService.rfiReopen({ChangeType:"1,2,3",Reason:"33",Significance:1});
    //this.baseFunctionService.rfiReopen({ChangeType:"1,2,3,6",OtherChangeType:"588",Reason:"33",Significance:2});
    this.baseFunctionService.rfiReopen();
  }

  reopenApproval(): void {
    this.baseFunctionService.rfiReopenAcknowledge();
  }

  isEndStep(status: string): boolean {
    const finalArr: string[] = [
      RFIStatus.Canceled,
      RFIStatus.Superseded,
      RFIStatus.Responded,
    ];
    return finalArr.includes(status);
  }

  hasPermissionToReopen(): boolean {
    if(this.IsDocControl)
    {
      return true;
    }
    return this.currentUser === this.rfi.UpdatedBy;
  }

  setAcknowledge(e: boolean): void {
    this.acknowledge = e;
  }

  getReasonLength(): number {
    return this.reason
      ? this.reason.length
      : 0;
  }
}
