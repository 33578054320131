import { IPRFIItem, IRFIDelegateDetail } from "../api-generated";
import RFIRole from "./rfiRoles";
import RFIStatus from "./rfiStatus";
import TimeStamp from "./timeStamp";


export default class RFIDelegateDetail
  extends TimeStamp
  implements IRFIDelegateDetail
{
  public UserId: string;
  public RoleId: string;

  constructor(item: IPRFIItem) {
    super();
    this.RoleId = this.getRoleIdByStatus(item);
  }

  private getRoleIdByStatus(item: IPRFIItem): string {
    let result = "";
    switch(item.Status)
    {
      case RFIStatus.Distribution:
      case RFIStatus.Review_Period:
        result = RFIRole.Coordinator;
        break;
      case RFIStatus.PM_Review:

        result = RFIRole.Manager;
        break;
      case RFIStatus.DDC_Review:
        result = RFIRole.DDC_Manager;
        break;
      case RFIStatus.SPM_Review:
        result = RFIRole.SR_Manager;
        break;
      default:
        break;
    }
    return result
  }
}
