import { Component, Input, OnInit } from "@angular/core";
import { getIcon, getStatusText } from "@shared/utils";
import { IPRFIItem } from "src/app/api-generated";
@Component({
  selector: "rfi-header",
  templateUrl: "./rfi-header.component.html",
  styleUrls: ["./rfi-header.component.scss"],
})
export default class RFIHeaderComponent implements OnInit {
  @Input() entity: IPRFIItem;

  @Input() IsDbAdmin = false;

  rfiNumber: string;

  rfiIcon: string;

  status: string;

  priority: string;

  priorityBackground: string;

  ngOnInit(): void {
    this.rfiNumber = this.entity.RFINumber;
    this.status = getStatusText(this.entity, this.IsDbAdmin);
    this.rfiIcon = getIcon(this.entity, this.IsDbAdmin);

    switch (this.entity.Priority) {
      case 2:
        this.priority = "Medium";
        this.priorityBackground = "btn sm solid orange unchange";
        break;
      case 3:
        this.priority = "High";
        this.priorityBackground = "btn sm solid red unchange";
        break;
      default:
        this.priority = "Low";
        this.priorityBackground = "btn sm solid grey unchange";
    }
  }

}
