/* eslint-disable import/no-extraneous-dependencies */
import { AecomCoreModule, LayoutService } from "@aecom/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import {
  Configuration,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { BBJCoreModule } from "@bbj/components";
import { NgxSpinnerModule } from "ngx-spinner";
import { ApiModule } from "src/app/api-generated/api.module";
import environment from "src/environments/environment";
import { OAuthSettings } from "../oauth";
import { BASE_PATH } from "./api-generated";
import AppRoutingModule from "./app-routing.module";
import AppComponent from "./app.component";
import AccessDeniedComponent from "./content/auth/access-denied/access-denied.component";
import AuthInterceptor from "./content/auth/auth.interceptor";
import AuthService from "./content/auth/auth.service";
import BaseComponent from "./content/base/base.component";
import ErrorComponent from "./content/error/error.component";
import FileListComponent from "./content/fileList/fileList.component";
import CompilingRfiComponent from "./content/rfi/compiling-rfi/compiling-rfi.component";
import CreateRfiComponent from "./content/rfi/create-rfi/create-rfi.component";
import InreviewQaqcRfiComponent from "./content/rfi/inreview-qaqc-rfi/inreview-qaqc-rfi.component";
import ReportComponent from "./content/rfi/report/report.component";
import ReviewRfiComponent from "./content/rfi/review-rfi/review-rfi.component";
import RFIListComponent from "./content/rfi/rfi-list/rfi-list.component";
import ViewRFIComponent from "./content/rfi/view-rfi/view-rfi.component";
import SelectUserComponent from "./content/selectUser/selectUser.component";
import { SignoutComponent } from "./content/signout/signout.component";
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import WaitForUserGuard from "./guards/wait-for-user-guard";
import ErrorResolve from "./resolvers/error-resolver";
import LoginUserResolve from "./resolvers/login-user-resolver";
import RFIViewResolver from "./resolvers/rfi-view.resolver";
import AzureBlobService from "./services/azureBlob.service";
import LocalClassificationService from "./services/local-classification.service";
import LocalContractRequirementService from "./services/local-contractRequirement.service";
import LocalContractService from "./services/local-contract.service";
import LocalContractUserService from "./services/local-contractUser.service";
import LocalSpecificationService from "./services/local-specification.service";
import LocalStorageService from "./services/local-storage.service";
import LocalUserService from "./services/local-user.service";
import NavService from "./services/nav.service";
import { DelegateModalComponent } from "./shared/delegate-modal/delegate-modal.component";
import { StorageKey } from "./shared/localstorage";
import NotifyListComponent from "./shared/notify-list/notify-list.component";
import { RecallModalComponent } from "./shared/recall-modal/recall-modal.component";
import { PreviousRouteService } from "./services/previousRoute.service";
import { UserNameCompanyDepartmentPipe } from "./shared/pipes/user-name-company-department";
import { ReadRecallModalComponent } from "@shared/readRecall-modal/readRecall-modal.component";
import { SendBackModalComponent } from "@shared/sendBack-modal/sendBack-modal.component";
import LeftPanelComponent from "@shared/leftPanel/leftPanel.component";
import RFIProcessingTabComponent from "@shared/rfiProcessingTab/rfiProcessingTab.component";
import RFILogTabComponent from "@shared/rfiLogTab/rfiLogTab.component";
import { RejectHistoryTableComponent } from "@shared/rejectHistoryTable/rejectHistoryTable.component";
import { RecallHistoryTableComponent } from "@shared/recallHistoryTable/recallHistoryTable.component";
import { WorkflowHistoryComponent } from "@shared/workflowHistory/workflowHistory.component";
import RFIResponseDetailComponent from "@shared/rfiResponse-detail/rfiResponse-detail.component";
import RFIHeaderComponent from "@shared/rfi-header/rfi-header.component";
import RFIDetailComponent from "@shared/rfi-detail/rfi-detail.component";
import ReopenDetailComponent from "@shared/reopen-detail/reopen-detail.component";
import { ReopenModalComponent } from "@shared/reopen-modal/reopen-modal.component";
import { ReopenHistoryTableComponent } from "@shared/reopenHistoryTable/reopenHistoryTable.component";
import SelectedUsersTableComponent from "@shared/selectedUsersTable/selectedUsersTable.component";
import {MatProgressBarModule} from '@angular/material/progress-bar';

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: "localStorage",
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  });
}

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      // set to true for IE 11
      storeAuthStateInCookie: false,
    },
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
    // unprotectedResources: OAuthSettings.unprotectedResources,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    BaseComponent,
    ErrorComponent,
    CreateRfiComponent,
    SelectUserComponent,
    ReviewRfiComponent,
    ViewRFIComponent,
    ReportComponent,
    RFIDetailComponent,
    RFIListComponent,
    CompilingRfiComponent,
    FileListComponent,
    InreviewQaqcRfiComponent,
    RFIHeaderComponent,
    CompilingRfiComponent,
    FileListComponent,
    DelegateModalComponent,
    RFIListComponent,
    RecallModalComponent,
    ReadRecallModalComponent,
    SendBackModalComponent,
    NotifyListComponent,
    SignoutComponent,
    UserNameCompanyDepartmentPipe,
    RFIResponseDetailComponent,
    LeftPanelComponent,
    RFIProcessingTabComponent,
    RFILogTabComponent,
    RejectHistoryTableComponent,
    RecallHistoryTableComponent,
    WorkflowHistoryComponent,
    ReopenDetailComponent,
    ReopenModalComponent,
    ReopenHistoryTableComponent,
    SelectedUsersTableComponent,
  ],
  imports: [
    AecomCoreModule,
    BBJCoreModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory(),
    ),
    BrowserAnimationsModule,
    ApiModule,
    MatProgressBarModule,
  ],
  providers: [
    PendingChangesGuard,
    WaitForUserGuard,
    LocalStorageService,
    LocalContractService,
    LocalClassificationService,
    LocalContractRequirementService,
    LocalSpecificationService,
    LocalContractUserService,
    LocalUserService,
    PreviousRouteService,
    AuthService,
    LayoutService,
    AzureBlobService,
    NavService,
    LoginUserResolve,
    ErrorResolve,
    RFIViewResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
