import environment from "./environments/environment";

export const BluebeamSettings = {
  CLIENT_ID: "f9443344-719c-4ddc-a07f-6651013cae6c",
  CLIENT_SECRET: "6314201e-1571-478c-925b-d491ad168e28",
  REDIRECT_URI: `${window.location.protocol}//${window.location.host}/bluebeam`,
  RESPONSE_TYPE: "code",
  SCOPE: "full_prime full_user jobs read_prime",
  STATE: "bbjnyc.bluebeam.state",
  AUTH_ENDPOINT: "https://authserver.bluebeam.com/auth/oauth/authorize",
  TOKEN_ENDPOINT: "https://authserver.bluebeam.com/auth/token",
};
export const OAuthSettings = {
  appId: environment.azureNew.clientId,
  secret: "",
  scopes: [environment.azureNew.scope],
  authority: `https://login.microsoftonline.com/${environment.azureNew.tenantId}/`,
  protectedResourceMap: new Map([
    // [
    //   `${environment.baseWebAPI}`,
    //   ['api://9515566f-186b-4a25-80cb-086de686fe3d/ec2-scope'],
    // ],
    [`${environment.api.listUsers}`, [environment.azureNew.scope]],
    [`${environment.api.getUser}`, [environment.azureNew.scope]],
  ]),
  unprotectedResources: ["authserver.bluebeam.com"],
  postLogoutRedirectUri: environment.url.hostURL,
};
