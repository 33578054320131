import { Component, Input } from "@angular/core";
import FileListType from "@models/fileListType";
import ReopenHistoryTableItem from "@models/reopenHistoryTableItem";

@Component({
  selector: "reopenHistoryTable",
  templateUrl: "./reopenHistoryTable.component.html",
  styleUrls: ["./reopenHistoryTable.component.scss"],
})
export class ReopenHistoryTableComponent {
  @Input() data: ReopenHistoryTableItem[] = [];

  fileListType: FileListType = FileListType.View;
}
