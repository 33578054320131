enum RouteParams {
  Project = "project",
  GuidelineId = "guidelineId",
  ItemId = "itemId",
  CommentId = "commentId",
  ContractId = "contractId",
  ErrorCode = "errorCode",
  Status = "status",
}
export default RouteParams;
