<div class="page-header">
  <div class="header-title">
    <p>
      <a [routerLink]="['/' + entity.ContractId + '/list']">RFI list &gt;</a>
      <span> {{ rfiNumber }}</span>
    </p>

    <h1>
      <i class="icon icon-lg title-icon-purple" [ngClass]="rfiIcon"></i>
      <span style="text-transform: uppercase">{{ status }}</span>
    </h1>
  </div>

  <div style="flex: 1">
    <div class="header-actions">
      <aecom-button [innerClass]="priorityBackground">
        {{ priority }}
      </aecom-button>
    </div>
    <div class="header-subTitle">RFI #{{ rfiNumber }}</div>
  </div>
</div>
