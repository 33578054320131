<div class="maincontainer" *ngIf="rfi && rfiDocuments">
  <div class="contentcontainer">
    <div class="col-6 leftHalf">
      <leftPanel [entity]="entity" [docs]="rfiDocuments" [defaultTab]="defaultTab"></leftPanel>
    </div>

    <div class="col-6 rightHalf">
      <div class="row">
        <div class="col-12">
          <div class="page-header" style="align-items: baseline">
            <div class="header-actions">
              <aecom-button innerClass="btn lg outline orange" (click)="back()">
                back
              </aecom-button>

              <aecom-button
                *ngIf="!isQaQc"
                innerClass="btn lg outline blue"
                (click)="reassign()"
                data-cy="reassign"
              >
                Reassign
              </aecom-button>

              <aecom-button
                [theme]="'light'"
                [disabled]="!hasChanges() || !rfi.QAQCCompliance ? true : null"
                innerClass="btn lg solid green"
                (click)="rfi.QAQCCompliance && saveDraft()"
              >
                save draft
              </aecom-button>

              <aecom-button
                innerClass="btn lg solid blue"
                (click)="submitRFI()"
              >
                Submit
              </aecom-button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="rfi" class="row">
        <div class="col-12">
          <aecom-form columns="6">
            <aecom-form-group
              columnSpan="6"
              columns="2"
              title="QA/QC Review"
              style="margin-bottom: 40px"
              class="matchToTab"
            >
              <aecom-form-item [label]="'QA/QC Compliant?'" columnSpan="1">
                <div class="button-wrapper d-flex">
                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      rfi.QAQCCompliance === true ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQaqcCompliance(true)"
                  >
                    Yes
                  </aecom-button>

                  <aecom-button
                    innerClass="btn solid btn-tab {{
                      rfi.QAQCCompliance === false ? 'blue' : 'bg-grey-e'
                    }}"
                    (click)="setQaqcCompliance(false)"
                  >
                    No
                  </aecom-button>
                </div>
              </aecom-form-item>

              <aecom-form-item
                *ngIf="rfi.QAQCCompliance === false"
                [label]="'Reason'"
                columnSpan="2"
              >
                <aecom-textbox
                  rows="6"
                  placeholder="Reason"
                  [theme]="'light'"
                  [maxlength]="1500"
                  [isMultiLine]="true"
                  [(ngValue)]="rfi.rfi_reject_detail.Note"
                  [validate]="
                    (rfi.rfi_reject_detail.Note.length === 0 || baseFunctionService.isInputInvalid(rfi.rfi_reject_detail.Note)) &&
                    rfi.QAQCCompliance === false &&
                    canIssueRFI
                  "
                ></aecom-textbox>
              </aecom-form-item>
            </aecom-form-group>

            <ng-container *ngIf="rfi.QAQCCompliance">
              <aecom-form-group
                *ngIf="isQaQc"
                columnSpan="6"
                columns="2"
                title="Assign"
                style="margin-bottom: 70px"
              >
                <aecom-form-item [label]="'Manager'" columnSpan="1" [required]="true">
                  <aecom-dropdown
                    [theme]="'light'"
                    placeholder="Select"
                    [data]="managerList"
                    (ngValueChange)="assignManger($event)"
                    [selectedId]="rfi.ManagerId"
                    [validate]="
                      (!rfi.ManagerId || rfi.ManagerId.trim() === '') &&
                      canIssueRFI
                    "
                  ></aecom-dropdown>
                </aecom-form-item>

                <aecom-form-item [label]="'Coordinator'" columnSpan="1" [required]="true">
                  <aecom-dropdown
                    [theme]="'light'"
                    placeholder="Select"
                    [data]="coordinatorList"
                    (ngValueChange)="assignCoordinator($event)"
                    [selectedId]="rfi.CoordinatorId"
                    [validate]="
                      (!rfi.CoordinatorId ||
                        rfi.CoordinatorId.trim() === '') &&
                      canIssueRFI
                    "
                  ></aecom-dropdown>
                </aecom-form-item>
              </aecom-form-group>

              <aecom-form-group
                *ngIf="!isQaQc"
                columnSpan="6"
                columns="2"
                title="Assign"
                style="margin-bottom: 70px"
              >
                <aecom-form-item [label]="'Internal Due Date'" columnSpan="1" [required]="true">
                  <aecom-date
                    [weekendsOff]="true"
                    [theme]="'light'"
                    [ngValue]="internalDueDate"
                    (change)="setDate($event)"
                    [notValidAfter]="maxInternalDueDate"
                    [validate]="
                      !internalDueDate &&
                      canIssueRFI
                    "
                  ></aecom-date>
                </aecom-form-item>
                
                <aecom-form-item [label]="'Response Due Date'" columnSpan="1" [required]="true">
                  <aecom-date
                    [weekendsOff]="true"
                    [theme]="'light'"
                    [ngValue]="dueDate"
                    [notValidBefore]="minDueDate"
                    (change)="setDueDate($event)"
                    [validate]="
                      !dueDate && canIssueRFI
                    "
                  ></aecom-date>
                </aecom-form-item>
              </aecom-form-group>
              <selectedUsersTable [isReviewer]="true" [tableData]="baseFunctionService.reviewerTableData" (removeUserClick)="removeUserClick($event)" (openWindowClick)="openWindowClick($event)" style="grid-column: span 6 / auto; position: relative"></selectedUsersTable>
              <selectedUsersTable [isReviewer]="false" [tableData]="baseFunctionService.watcherTableData" (removeUserClick)="removeUserClick($event)" (openWindowClick)="openWindowClick($event)" style="grid-column: span 6 / auto; position: relative"></selectedUsersTable>
              
            </ng-container>
          </aecom-form>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="baseFunctionService.showSelectUser" class="up-contentcontainer">
  <div class="selectUserWindow">
    <select-user
      (close)="closeWindow($event)"
    ></select-user>
  </div>
</div>
