import TableHeaderCol from "./table-header-col";

const tableHeader: TableHeaderCol[] = [
  {
    Name: "id",
    Type: "hide",
    Header: "User #",
    RelativeWidth: 0,
  },
  {
    Name: "name",
    Type: "text",
    Header: "Name",
    RelativeWidth: 150,
  },
  {
    Name: "email",
    Type: "text",
    Header: "Email",
    RelativeWidth: 150,
  },
  {
    Name: "organization",
    Type: "text",
    Header: "Organization",
    RelativeWidth: 100,
  },
  {
    Name: "track",
    Type: "text",
    Header: "Track",
    RelativeWidth: 50,
  },
];

export default tableHeader;
