<div class="contentcontainer" *ngIf="fileList.length > 0">
  <div class="header" *ngIf="title">
    {{ title }}
  </div>

  <div class="file-list">
    <ng-container *ngFor="let doc of fileList; let i = index">
      <div style="position: relative">
        <li>
          <!-- <div style="display: flex; padding: 0;"> -->
          <i
            [class]="getFileIcon(doc.Name)"
            style="margin-left: 15px; margin-right: 10px"
          ></i>

          <a
            [ngClass]="{ linkItemText: listType !== fileListType.Upload }"
            style="
              max-width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
            [href]="doc.URL"
            target="_blank"
            [title]="doc.Name"
          >
            {{ doc.Name }}
          </a>

          <div class="actionsBlock" *ngIf="listType === fileListType.Upload">
            <i
              class="
                icon icon-lg
                icon-action_check_circle
                icon-green
                nonHoverIcon
              "
            ></i>

            <i
              class="icon icon-lg icon-basic_trashcan icon-orange hoverIcon"
              (click)="removeFile(i)"
            ></i>
          </div>

          <div class="actionsBlock" *ngIf="listType === fileListType.View">
            <i
              class="icon icon-action_download icon-blue icon-lg hoverIcon"
              (click)="download(doc)"
            ></i>
          </div>

          <div class="actionsBlock" *ngIf="listType === fileListType.Movable">
            <i
              class="icon icon-action_download icon-blue icon-lg hoverIcon"
              (click)="download(doc)"
              >third</i
            >

            <i
              class="
                icon icon-moveFile icon-blue
                icon-cus_green
                icon-lg
                hoverIcon
              "
              (click)="moveFile(doc)"
            ></i>
          </div>
          <!-- </div> -->
        </li>
      </div>
    </ng-container>
  </div>
</div>
