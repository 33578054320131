import { IRFIRejectDetail } from "../api-generated";
import TimeStamp from "./timeStamp";

export default class RFIRejectDetails
  extends TimeStamp
  implements IRFIRejectDetail
{
  public Guid?: string;

  public Note?: string;

  constructor(item?: IRFIRejectDetail) {
    super(item);

    this.Guid = item?.Guid;

    this.Note = item?.Note ?? "The rfi did not pass the program's QA/QC checks.";
  }
}
