import { IRFIReopenApproval, RfiReopen } from "../api-generated";

export default class RFIReopenApprovalDetail
  implements IRFIReopenApproval
{
  public Guid: string;

  constructor(item: RfiReopen) {
    this.Guid = item.Guid;
  }
}
