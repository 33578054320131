import { ModalContainerService, NotificationComponent, NotificationType } from "@aecom/core";
import { Injectable } from "@angular/core";
import { ContractUserApplicationService, RFIReviewerService, RFIItemService, IPRFIItem, IPRFIDocumentDownload, IRFIReopen, RFIDocumentsService, IPRFIDocumentUploadReturn, IPRFIReviewSubmitItem, IPRFIReviewerInit, RfiReviewer, EmailService, IRFINotificationReviewer, RfiWatcher, IPRFIWatcherInit, RFIWatcherService, IUser } from "../api-generated";
import { ActivatedRoute, Router } from "@angular/router";
import AuthService from "@auth/auth.service";
import LoadingService from "./loading.service";
import LocalContractUserService from "./local-contractUser.service";
import { RecallModalComponent } from "@shared/recall-modal/recall-modal.component";
import _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { DelegateModalComponent } from "@shared/delegate-modal/delegate-modal.component";
import LocalApplicationRoleService from "./local-application_role.service";
import LocalRFIItemService from "./local-rfiItem.service";
import RFIRecallRequest from "@models/rfiRecallRequest";
import RFIRecallStep from "@models/rfiRecallStep";
import RFIDelegate from "@models/rfiDelegate";
import { ReadRecallModalComponent } from "@shared/readRecall-modal/readRecall-modal.component";
import RFIUpdateBase from "@models/rfiUpdateBase";
import { SendBackModalComponent } from "@shared/sendBack-modal/sendBack-modal.component";
import RFICompilingStep from "@models/rfiCompilingStep";
import RFIRole from "@models/rfiRoles";
import RFICreateStep from "@models/rfiCreateStep";
import RFIInReviewStep from "@models/rfiInReviewStep";
import RFIRecallApprovalStep from "@models/rfiRecallApprovalStep";
import RFIReview from "@models/rfiReview";
import ContractNestedViewItem from "@models/contractNestedViewItem";
import RFIReopenApproval from "@models/rfiReopenApproval";
import { ReopenModalComponent } from "@shared/reopen-modal/reopen-modal.component";
import RFIReopenStep from "@models/rfiReopenStep";
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { FileModel } from "@aecom/core";
import { UploadStatus } from "@aecom/core";
import RFIDocumentUpload from "@models/rfiDocumentUpload";
import RFIStatus from "@models/rfiStatus";
import AzureBlobService from "./azureBlob.service";
import { combineUserInfo, generateUserRowItem, getAllUsersExceptDB, isFileNameInvalid } from "@shared/utils";
import RFIDocumentCopy from "@models/rfiDocumentCopy";
import RowItem from "@content/selectUser/table-row";
import LocalUserService from "./local-user.service";
import { RemoveUserArg } from "@shared/selectedUsersTable/selectedUsersTable.component";
import IRemoveUserClick from "@models/IRemoveUserClick";


@Injectable({
  providedIn: "root",
})
export default class BaseFunctionService {
  protected entity: IPRFIItem;
  protected contractId: string;
  protected docs: IPRFIDocumentDownload[];
  protected refId: string;
  protected tempGuid: string;

  protected fileListSubject = new BehaviorSubject<FileModel[]>([]);

  fileList$: Observable<FileModel[]> = this.fileListSubject.asObservable();

  protected oldFileList: FileModel[] = [];

  protected fileListNameValidSubject = new BehaviorSubject<boolean>(true);

  protected removedFileIds: string[] = [];

  protected reviewerTableData: RowItem[] = [];

  protected watcherTableData: RowItem[] = [];

  protected allUserTableRows: RowItem[] = [];

  reviewersResponse: (RfiReviewer & {UserInfo?: IUser})[];

  watchersResponse: (RfiWatcher & {UserInfo?: IUser})[];

  tableRows: RowItem[] = [];

  showSelectUser = false;

  activeSave = false;

  openReviewerWindow: boolean | undefined = undefined;

  protected responseView: boolean | undefined = undefined; 

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public rfiItemService: RFIItemService,
    public rfiDocumentsService: RFIDocumentsService,
    public localContractUserService: LocalContractUserService,
    public rfiReviewerService: RFIReviewerService,
    public localApplicationRoleServices: LocalApplicationRoleService,
    public contractUserApplicationService: ContractUserApplicationService,
    public localRFIItemService: LocalRFIItemService,
    public localUserService: LocalUserService,
    public emailService: EmailService,
    private rfiWatcherService: RFIWatcherService,
  ) {}

  setEntity(entity: IPRFIItem, docs: IPRFIDocumentDownload[]): void {
    this.entity = entity;
    this.contractId = this.entity.ContractId;
    this.docs = docs;
    this.fileListSubject.next([]);
    this.oldFileList = [];
    this.fileListNameValidSubject.next(true);
    this.refId = undefined;
    this.tempGuid = undefined;
    this.showSelectUser = false;
  }

  setContractId(contractId: string): void {
    this.contractId = contractId;
    this.entity = undefined;
    this.docs = [];
    this.fileListSubject.next([]);
    this.oldFileList = [];
    this.fileListNameValidSubject.next(true);
    this.refId = undefined;
    this.tempGuid = undefined;
    this.allUserTableRows = [];
    this.reviewerTableData = [];
    this.watcherTableData = [];
    this.showSelectUser = false;
  }

  setRefId(id: string): void {
    this.refId = id;
  }

  setTempId(id: string): void {
    this.tempGuid = id;
  }

  setReviewerWatcherTableView(): void {
    const users = this.localUserService.getItems();
    const contractUsers = this.localContractUserService.getItems();
    this.allUserTableRows = getAllUsersExceptDB(contractUsers);

    this.reviewerTableData = this.entity.rfi_reviewer.map((item) => {
      const user = users.find((u) => {
        return u.id === item.ReviewerId;
      });
      return generateUserRowItem(combineUserInfo(item, user));
    });

    this.watcherTableData = this.entity.rfi_watcher.map((item) => {
      const user = users.find((u) => {
        return u.id === item.WatcherId;
      });
      return generateUserRowItem(combineUserInfo(item, user));
    });
    this.showSelectUser = false;
    this.responseView = false;
  }

  setReviewerWatcherResponseView(): void {
    const users = this.localUserService.getItems();
    const contractUsers = this.localContractUserService.getItems();
    this.allUserTableRows = getAllUsersExceptDB(contractUsers);
    this.reviewersResponse = this.entity.rfi_reviewer.map((item) => {
      const user = users.find((u)=>{return u.id === item.ReviewerId});
      return combineUserInfo<RfiReviewer>(item, user);
    });
    this.watchersResponse = this.entity.rfi_watcher.map((item) => {
      const user = users.find((u)=>{return u.id === item.WatcherId});
      return combineUserInfo<RfiWatcher>(item, user);
    });
    this.showSelectUser = false;
    this.responseView = true;
  }

  getFileListForCurrentStep(status: string, refId?: string): FileModel[] {
    return _.uniqBy(
      this.docs.filter((item) => {
        if(refId)
        {
          return item.ReferenceId === refId;
        }
        return item.Status === status;
      }).map((item)=>{
        return new FileModel(
          item.Guid, // use document Guid instead of fileId
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
        )
      }).sort((a, b) => {
        return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
      }),
      "Name"
    );
  }

  setFileList(files: FileModel[]): void {
    this.fileListSubject.next(files);
    this.oldFileList = _.cloneDeep(files);
    this.fileListNameValidSubject.next(true);
  }

  errorHandle(error: HttpErrorResponse, activeModal: ModalContainerService, router: Router): void {
    if (error.status === 403 || error.status === 409) {
      const modalInstance1 = activeModal.open(NotificationComponent);
      modalInstance1.instance.type = NotificationType.Information;
      modalInstance1.instance.theme = "light";
      modalInstance1.instance.title = "Your Changes Can't be Saved";
      modalInstance1.instance.body = "Someone already saved changes. Please refresh your screen to see updates.";
      modalInstance1.result.then((result1) => {
        if (result1 === 0) {
          const currentUrl = router.url;
          router.navigateByUrl("blank").then(() => {
            router.navigateByUrl(currentUrl);
          });
        }
      });
    } else {
      console.log(error);
      const modalInstance = activeModal.open(NotificationComponent);
      modalInstance.instance.type = NotificationType.Information;
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Error";
      modalInstance.instance.body = "An unexpected error occurred";
    }
  }

  back(): void {
    this.router.navigateByUrl(`${this.contractId}/list`);
  }

  backAfterConfirm(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Cancel editing?";
    modalInstance.instance.body = "Your changes will not be saved.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      }
    });
  }

  recallRFI(): void {
    const modalInstance = this.activeModal.open(RecallModalComponent);

    modalInstance.result.then((result: RFIRecallRequest) => {
      console.log("result", result);

      if (
        (result && result.comments && !(_.isNumber(result.comments) && result.comments === 0)) ||
        _.isString(result.comments)
      ) {
        this.loadingService.start();
        const request = new RFIRecallStep(this.contractId, result, this.entity);

        this.rfiItemService.rfiUpdate(request).subscribe(
          () => {
            this.loadingService.stop();
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop();
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  reviseRFI(): void {
    this.router.navigateByUrl(`${this.contractId}/create/${this.entity.Guid}`);
  }

  reassign(): void {
    const modalInstance = this.activeModal.open(DelegateModalComponent);
    modalInstance.instance.rfiItem = this.entity;

    modalInstance.result.then((result: RFIDelegate | null) => {
      console.log("result", result);

      if (result) {
        this.loadingService.start();
        this.rfiItemService.rfiDelegate(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  dbReadReject(): void {
    const modalInstance = this.activeModal.open(ReadRecallModalComponent);

    modalInstance.instance.title = "Recalled RFI has been Rejected";
    modalInstance.instance.body =
      "Doc Control rejected your recall request. Please contact directly to the Doc Control for any question.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new RFIUpdateBase(
          this.contractId,
          this.entity,
        );

        this.rfiItemService.dbReadRejectedRecallRFI(item).subscribe();
      }
    });
  }

  dbViewRFI(): void {
    const item = new RFIUpdateBase(this.contractId, this.entity);

    this.rfiItemService.viewedRFI(item).subscribe(
      (r) => {
        console.log(r);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
      },
    );
  }

  dbVoidRFI(): void {
    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Void RFI?";
      modalInstance.instance.body = "Your RFI will be voided.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          const item = new RFIUpdateBase(
            this.contractId,
            this.entity,
          );
          item.IsDraft = false;
          this.rfiItemService.rfiUpdate(item).subscribe(
            () => {
              this.loadingService.stop(true);
              this.router.navigateByUrl(`${this.contractId}/list`);
            },
            (error: HttpErrorResponse) => {
              this.loadingService.stop(true);
              this.errorHandle(error, this.activeModal, this.router);
            },
          );
        }
      });
    }
  }

  showFileProgressNotification(): void {
    this.OpenAlert("Please wait until all your files have been uploaded", "File upload in progress");
  }

  OpenAlert(body: string, title = "Error") {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = title;
    modalInstance.instance.body = body;
    modalInstance.result;
  }

  sendBack(): void {
    const modalInstance = this.activeModal.open(SendBackModalComponent);
    modalInstance.instance.by = this.localApplicationRoleServices.getRoleById(this.entity.BallInCourt);
    modalInstance.result.then((result) => {
      if (result && result !== 0 && result.length > 0) {
        this.loadingService.start();
        const releaseDisposition = new RFICompilingStep(this.contractId, this.entity);
        releaseDisposition.IsDraft = false;
        releaseDisposition.ToDDC = false;
        releaseDisposition.ToSrManager = false;
        releaseDisposition.rfi_compiled_response.Response = result;
        releaseDisposition.rfi_compiled_response.IsDraft = false;
        releaseDisposition.rfi_compiled_response.ScheduleImpact = undefined;
        releaseDisposition.rfi_compiled_response.CostImpact = undefined;
        releaseDisposition.rfi_compiled_response.To = RFIRole.Coordinator;
        this.rfiItemService.rfiUpdate(releaseDisposition).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  update(rfi: RFICreateStep | RFIInReviewStep | RFICompilingStep | RFIRecallStep | RFIRecallApprovalStep | RFIUpdateBase) {
    this.updateFiles(rfi, rfi["DosePMCAccept"] === false);
    this.rfiItemService.rfiUpdate(rfi).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  reviewerSubmit(item: IPRFIReviewSubmitItem): void {
    this.loadingService.start();
    if(!item.hasComments)
    {
      item.Response = "";
    }
    this.updateFiles(item, !item.hasComments);
    this.rfiReviewerService.submitRFIReview(item).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  delegate(rfi: RFIDelegate) {
    this.rfiItemService.rfiDelegate(rfi).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  submitMyReviewResponse(review: RFIReview) {
    this.rfiReviewerService
      .submitRFIReview(review)
      .subscribe(
        () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          this.errorHandle(error, this.activeModal, this.router);
        },
      );
  }

  subDelegate(item: RFIDelegate): void {
    this.rfiItemService.rfiDelegate(item).subscribe(
      () => {
        this.loadingService.stop(true);
        this.router.navigateByUrl(`${this.contractId}/list`);
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop(true);
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  getNestedViewFlag(): boolean {
    return this.localRFIItemService.nestedView;
  }

  setNestedViewFlag(value: boolean): void {
    this.loadingService.start();
    const nestedViewItem = new ContractNestedViewItem(this.authService.user.Id, this.contractId, value);
    this.contractUserApplicationService.updateApplicationNestedVeiw(nestedViewItem).subscribe(
      () => {
        this.localRFIItemService.nestedView = value;
        this.loadingService.stop();
      },
      (error: HttpErrorResponse) => {
        this.loadingService.stop();
        this.errorHandle(error, this.activeModal, this.router);
      },
    );
  }

  rfiReopen(): void {
    const role = this.localContractUserService.currentUserContractRole;
    const isDoc = role === RFIRole.Doc_Control;
    const item = new RFIReopenStep(this.contractId, this.entity);
    if(isDoc)
    {
      item.tempId = uuidv4();
    }
    const modalInstance = this.activeModal.open(ReopenModalComponent);
    modalInstance.instance.item = item;
    modalInstance.result.then((result) => {
      if (result) {
        console.log(result);
        this.loadingService.start();
        this.rfiItemService.rfiReopen(result).subscribe(
          () => {
            this.loadingService.stop(true);
            this.router.navigateByUrl(`${this.contractId}/list`);
          },
          (error: HttpErrorResponse) => {
            this.loadingService.stop(true);
            this.errorHandle(error, this.activeModal, this.router);
          },
        );
      }
    });
  }

  rfiReopenAcknowledge(): void {
    if(this.entity.rfi_reopen?.length > 0)
    {
      const reopen = this.entity.rfi_reopen.sort((a, b) =>
        new Date(b.DateRequest).getTime() - new Date(a.DateRequest).getTime(),
      )[0];
      const rfi = new RFIReopenApproval(this.contractId, this.entity, reopen);
      this.rfiItemService.rfiReopenApproval(rfi).subscribe(
        () => {
          this.loadingService.stop(true);
          this.router.navigateByUrl(`${this.contractId}/list`);
        },
        (error: HttpErrorResponse) => {
          this.loadingService.stop(true);
          this.errorHandle(error, this.activeModal, this.router);
        },
      );
    }
  }

  // rfiReopenApproval(approval: boolean, reason?: string | null): void {
  //   if(!approval)
  //   {
  //     const modalInstance = this.activeModal.open(NotificationComponent);
  //     modalInstance.instance.theme = "light";
  //     modalInstance.instance.title = "Submit Response?";
  //     modalInstance.instance.body = "<span class='warningFont'>Upon submission, DDC/AHJV reserves the right to proceed with the appropriate measures to respond and resolve issues in accordance with the Agreement.</span>";

  //     modalInstance.result.then((result) => {
  //       if (result === 1) {
  //         this.rfiReopenApprovalAction(approval, reason);
  //       }
  //     });
  //   }
  //   else{
  //     this.rfiReopenApprovalAction(approval, reason);
  //   }
  // }

  readReopened(): void {
    const modalInstance = this.activeModal.open(NotificationComponent);
    modalInstance.instance.type = NotificationType.Information;
    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "RFI has been reopened";
    modalInstance.instance.showCancel = false;
    modalInstance.instance.body = "<span class='warningFont'>Please send back to Coordinator OR modify and reissue the response.</span>";

    modalInstance.result.then((result) => {
      if (result === 1) {
        const item = new RFIUpdateBase(
          this.contractId,
          this.entity,
        );

        this.rfiItemService.viewedRFI(item).subscribe();
      }
    });
  }

  isInputInvalid(str: string | null | undefined): boolean {
    if(str)
    {
      const invalidChars = /[^\x20-\x7E\n\r]+/;
      return invalidChars.test(str);
    }
    return false;
  }

  isFileNameValid(): boolean {
    return this.fileListNameValidSubject.getValue();
  }

  areFilesChanged(): boolean {
    const oldFiles = JSON.stringify(this.oldFileList);
    const currentFiles = JSON.stringify(this.fileListSubject.getValue());
    return oldFiles !== currentFiles;
  }

  protected updateFiles(rfi: {docRemove?:string[],docSave?:string[]}, remove: boolean = false): void {
    const allCurrentFiles = [...this.fileListSubject.getValue()];
    const allOldFiles = [
      ...this.oldFileList.map((f) => f.Guid)
    ];
    if(remove)
    {
      rfi.docRemove = [...new Set([...(rfi.docSave ?? []),...allCurrentFiles.map((f)=>{return f.Guid}),...this.removedFileIds])];
      rfi.docSave = [];
    } else {
      const filesToAdd = allCurrentFiles.filter((f) => {
        return !allOldFiles.includes(f.Guid) && f.Status === UploadStatus.LOADED;
      }).map((f) => {
        return f.Guid;
      });
  
      const fileIds = allCurrentFiles.map((f) => {
        return f.Guid;
      });
  
      const filesToRemove = allOldFiles.filter((f) => {
        return !fileIds.includes(f);
      });
  
      const removeIds = [...new Set([...filesToRemove, ...this.removedFileIds])];
      rfi.docSave = [...new Set([...((rfi.docSave ?? []).filter((f)=>{return !removeIds.includes(f)})), ...filesToAdd])];
      rfi.docRemove = removeIds;
    }
  }

  deleteFile(e: FileModel): void {
    this.updateFileList(e, true);
  }

  importFile(e: FileModel[]): void {
    let status = "";
    let rfiId = "";
    if(!this.entity)
    {
      status = RFIStatus.Draft;
      rfiId = this.tempGuid;
    } else {
      rfiId = this.entity.Guid;
      status = this.entity.Status === RFIStatus.QAQC_Fail ? RFIStatus.Draft : this.entity.Status;
    }
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new RFIDocumentUpload(
            item.Name,
            this.authService.user.Id,
            status,
            rfiId,
            this.refId
          );

          await this.rfiDocumentsService
            .createRFIDocument(fileUploaded)
            .toPromise()
            .then((r: IPRFIDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item);
              AzureBlobService.uploadFile(r.URL, item);
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item);
        }
      });
    }
  }

  protected updateFileList(item: FileModel, isRemove = false): void {
    let fileList = this.fileListSubject.getValue();
    let fileListNameValid = true;
    const index = fileList.findIndex((file) => {
      return file.Name === item.Name;
    });

    if (isRemove) {
      if (index !== -1) {
        fileList.splice(index, 1);
      }
    } else if (index === -1) {
      fileList.push(item);
      fileList = fileList.slice();
    }

    fileList.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        fileListNameValid = false;
      }
    });
    this.fileListSubject.next(fileList);
    this.fileListNameValidSubject.next(fileListNameValid);
    
    if(isRemove)
    {
      this.removedFileIds.push(item.Guid);
    }
  }

  hasFilesUploading(): boolean {
    return this.fileListSubject.getValue().some((item) => item.Status === UploadStatus.UPLOADING);
  }

  
  addFileToAttachment(prevItem: FileModel): void {
    const item = JSON.parse(JSON.stringify(prevItem));
    item.Percentage = 100;
    item.Status = "preloaded";

    if (!this.isFileExist(prevItem)) {
      const fileUploaded = new RFIDocumentCopy(
        this.entity.Status,
        this.entity.Guid,
        item.Guid,
        this.refId
      );

      this.rfiDocumentsService
        .copyRFIDocument(fileUploaded)
        .subscribe((res) => {
          item.Guid = res.Guid;
          this.updateFileList(item);
        });
    }
  }

  protected isFileExist(item: FileModel): boolean {
    const index = this.fileListSubject.getValue().findIndex((file) => {
      return file.Name === item.Name;
    });
    return index > -1;
  }

  openWindowClick(role: boolean): void {
    this.activeSave = false;
    this.openReviewerWindow = role;
    this.tableRows = this.allUserTableRows
      .filter((user) => {
        if (role) {
          return this.responseView ? (
            this.reviewersResponse.findIndex((u) => {
              return u.UserInfo?.id == user.id;
            }) == -1
          ) : (
            this.reviewerTableData.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        } else {
          return this.responseView ? (
            this.watchersResponse.findIndex((u) => {
              return u.UserInfo?.id == user.id;
            }) == -1
          ) : (
            this.watcherTableData.findIndex((u) => {
              return u.id == user.id;
            }) == -1
          );
        }
      })
      .map((user) => {
        user.checked = false;
        return user;
      });

    this.showSelectUser = true;
  }

  closeWindow(rfi: {rfi_reviewer?:string[],rfi_watcher?:string[]}, e?: RowItem[]): void {
    if (this.activeSave) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      if (e && Array.isArray(e) && e.length > 0) {
        if (this.openReviewerWindow) {
          this.reviewerTableData = this.reviewerTableData.concat(e);

          rfi.rfi_reviewer = this.reviewerTableData.map((user) => {
            return user.id;
          });
        } else {
          this.watcherTableData = this.watcherTableData.concat(e);

          rfi.rfi_watcher = this.watcherTableData.map((user) => {
            return user.id;
          });
        }
        this.showSelectUser = false;
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  removeUserClick(rfi: {rfi_reviewer?:string[],rfi_watcher?:string[]}, remove: RemoveUserArg): void {
    const {row, isReviewer} = remove;
    if (isReviewer) {
      const index = this.reviewerTableData.findIndex((item) => {
        return item.id === row.id;
      });

      if (index > -1) {
        this.reviewerTableData = this.reviewerTableData.filter((item) => {
          return item.id !== row.id;
        });

        rfi.rfi_reviewer = this.reviewerTableData.map((user) => {
          return user.id;
        });
      }
    } else {
      const index = this.watcherTableData.findIndex((item) => {
        return item.id === row.id;
      });

      if (index > -1) {
        this.watcherTableData = this.watcherTableData.filter((item) => {
          return item.id !== row.id;
        });
        rfi.rfi_watcher = this.watcherTableData.map((user) => {
          return user.id;
        });
      }
    }
  }

  closeWindowDirectSaveToDB(e?: RowItem[]): void {
    if (this.activeSave) {
      if (e && Array.isArray(e) && e.length > 0) {
        if (e.length > 0) {
          this.loadingService.start();
          const users = this.localUserService.getItems();
          if (this.openReviewerWindow) {
            forkJoin(
              e.map((user) => {
                const temp = {} as IPRFIReviewerInit;
                temp.RFIGuid = this.entity.Guid;
                temp.ReviewerId = user.id;
                return this.rfiReviewerService.addRFIReviewer(temp);
              })
            ).subscribe(async (res: RfiReviewer[]) => {

              this.reviewersResponse = this.reviewersResponse.concat(
                res.map((item) => {
                  const user = users.find((u)=>{return u.id === item.ReviewerId});
                  return combineUserInfo<RfiReviewer>(item, user);
                })
              );
              const data: IRFINotificationReviewer = {
                rfiGuid: this.entity.Guid,
                reviewerIds: res.map((reviewer) => reviewer.ReviewerId),
                role: RFIRole.Reviewer
              };
              await this.emailService.addRFIReviewerAndWatcher(data).subscribe((r) => {
                this.loadingService.stop();
                // console.log(r);
              });
            });
          } else {
            forkJoin(
              e.map((user) => {
                const temp = {} as IPRFIWatcherInit;
                temp.RFIGuid = this.entity.Guid;
                temp.WatcherId = user.id;

                return this.rfiWatcherService.addRFIWatcher(temp);
              })
            ).subscribe(async (res: RfiWatcher[]) => {
              this.watchersResponse = this.watchersResponse.concat(
                res.map((item) => {
                  const user = users.find((u)=>{return u.id === item.WatcherId});
                  return combineUserInfo<RfiWatcher>(item, user);
                })
              );
              const data: IRFINotificationReviewer = {
                rfiGuid: this.entity.Guid,
                reviewerIds: res.map((reviewer) => reviewer.WatcherId),
                role: RFIRole.Watcher
              };
              await this.emailService.addRFIReviewerAndWatcher(data).subscribe((r) => {
                this.loadingService.stop();
                // console.log(r);
              });
            });
          }
          this.showSelectUser = false;
        }
      } else {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Notification";
        modalInstance.instance.body = "Are you sure you would like to cancel?";

        modalInstance.result.then(async (result) => {
          if (result === 1) {
            this.showSelectUser = false;
          }
        });
      }
    } else {
      this.showSelectUser = false;
    }
  }

  removeUserClickDirectSaveToDB(event: IRemoveUserClick): void {
    const {userId, isReviewer} = event;
    if (isReviewer) {
      const reviewerIndex: number = this.reviewersResponse.findIndex(
        (user) => user.UserInfo?.id === userId
      );

      if (reviewerIndex >= 0) {
        this.reviewersResponse.splice(reviewerIndex, 1);
        this.reviewersResponse = this.reviewersResponse.slice();

        const temp = {} as IPRFIReviewerInit;
        temp.RFIGuid = this.entity.Guid;
        temp.ReviewerId = userId;

        this.rfiReviewerService.removeRFIReviewer(temp).subscribe((res) => {
          console.log("res", res);
        });
      }
    } else {
      const watcherIndex = this.watchersResponse.findIndex(
        (user) => user.UserInfo?.id === userId
      );

      if (watcherIndex >= 0) {
        this.watchersResponse.splice(watcherIndex, 1);
        this.watchersResponse = this.watchersResponse.slice();

        const temp = {} as IPRFIWatcherInit;
        temp.RFIGuid = this.entity.Guid;
        temp.WatcherId = userId;

        this.rfiWatcherService.removeRFIWatcher(temp).subscribe((res) => {
          // console.log("res", res);
        });
      }
    }
  }
}

