import { IPRFIItem, IRFIRecallApproveStep, RfiRecall } from "../api-generated";
import RFIRecallItem from "./rfiRecallItem";
import RFIUpdateBase from "./rfiUpdateBase";


export default class RFIRecallApprovalStep
  extends RFIUpdateBase
  implements IRFIRecallApproveStep
{
  public IsRecalled: boolean;
  public ShowRecallRejected: boolean;
  public rfi_recall: RFIRecallItem;

  constructor(contractId: string, item: IPRFIItem, recall: RfiRecall) {
    super(contractId, item);
    this.IsRecalled = false;
    this.ShowRecallRejected = false;
    this.rfi_recall = new RFIRecallItem(recall);
    
  }
}
