/* eslint-disable prettier/prettier */
import { FileModel } from "@aecom/core";
import _ from "lodash";
import { IUser, RfiWatcher, RfiReviewer, IPRFIDocumentDownload} from "../api-generated";
import RFIStatus from "./rfiStatus";
import { prepareMutiLineText } from "../shared/utils";

export default class RFIResponseDetail {
  public Guid: string;

  public RFIGuid: string;

  public UserId: string;

  public Response: string;

  public DateUpdated: string;

  public IsDraft: boolean;

  public ReviewerName: string;

  public ReviewerOrganization: string;

  public ReviewerTrackName: string;

  public Attachments: FileModel[];

  public Collapse: boolean;

  public HasComments: boolean;

  constructor(rfi: ((RfiReviewer | RfiWatcher) & {UserInfo?: IUser}), docs: IPRFIDocumentDownload[]) {
    this.Guid = rfi.Guid ?? "";

    this.RFIGuid = rfi.RFIGuid ?? "";

    if(RFIResponseDetail.isReviewer(rfi))
    {
      this.UserId = rfi.ReviewerId ?? "";
      this.Response = prepareMutiLineText(rfi.Response);
      this.HasComments = rfi.hasComments;
    }
    else{
      this.UserId = rfi.WatcherId ?? "";
    }

    this.DateUpdated = rfi.DateUpdated
      ? `${new Date(rfi.DateUpdated).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric"
        })} ${new Date(rfi.DateUpdated).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit"
        })}`
      : "-";

    this.IsDraft = rfi.IsDraft !== false;

    this.ReviewerName = rfi.UserInfo?.displayName ?? "";

    this.ReviewerOrganization = rfi.UserInfo?.companyName ?? "[Organization]";

    this.ReviewerTrackName = rfi.UserInfo?.department ?? "[Track Name]";

    this.Collapse = false;

    const documnetsForThis = docs
      .filter((item) => {
        return item.Status === RFIStatus.Review_Period && item.ReferenceId === this.Guid;
      })
      .map((item) => {
        return new FileModel(
          item.Guid,
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL
        );
      });

    this.Attachments = _.uniqBy(
      documnetsForThis.sort((a, b) => {
        return new Date(b.Base64).getTime() - new Date(a.Base64).getTime();
      }),
      "Name"
    );
  }

  private static isReviewer(rfi: ((RfiReviewer | RfiWatcher) & {UserInfo?: IUser})): rfi is RfiReviewer {
    return "ReviewerId" in rfi;
  }

}
