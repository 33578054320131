/* eslint-disable no-case-declarations */
import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPRFIDocumentDownload,
  RfiCompiledResponse,
} from "../api-generated";
import { getCostImpact, getScheduleImpace, mapDocumentsToFile, prepareMutiLineText } from "@shared/utils";
import LocalApplicationRoleService from "@services/local-application_role.service";
import { FileModel } from "@aecom/core";
import RFIStatus from "./rfiStatus";
import RFIRole from "./rfiRoles";

export default class NotesListItem {
  public Name: string;

  public Role: string;

  public Title: string;

  public FormatedDateUpdated: string;

  public CostImpact: string | undefined;

  public ScheduleImpact: string | undefined;

  public Notes: string;

  public Collapse: boolean;

  public Sort: number;

  public IsBack: boolean;

  public Attachments?: FileModel[];

  constructor(item: RfiCompiledResponse, localContractUserService: LocalContractUserService, localApplicationRoleServices: LocalApplicationRoleService, documents: IPRFIDocumentDownload[]) {
    this.IsBack = item.To === RFIStatus.Review_Period;
    if (item.UpdatedBy) {
      const manager = localContractUserService.getUserById(
        item.UpdatedBy,
      )?.UserInfo;

      this.Name = manager?.displayName ?? "";
      let roleId = "";
      switch(item.Status)
      {
        case RFIStatus.PM_Review:
          roleId = RFIRole.Manager;
          break;
        case RFIStatus.SPM_Review:
          roleId = RFIRole.SR_Manager;
          break;
        case RFIStatus.DDC_Review:
          roleId = RFIRole.DDC_Manager;
          break;
        default:
          roleId = RFIRole.Coordinator;
          break;
      }
      this.Role = `${localApplicationRoleServices.getRoleById(roleId)}`;
    }
    // this.Title = this.instanceOf_SubmittalDisposition(item) ? "Note for DB" : "Note to Manager";
    this.Title = "Note for DB";
    this.FormatedDateUpdated = item.DateUpdated
      ? `${new Date(item.DateUpdated).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })} ${new Date(item.DateUpdated).toLocaleTimeString([], {
          hour: "numeric",
          minute: "2-digit",
        })}`
      : "-";
    this.Notes = prepareMutiLineText(item.Response);
    this.CostImpact = getCostImpact(item);
    this.ScheduleImpact = getScheduleImpace(item);
    this.Sort = item.DateUpdated ? new Date(item.DateUpdated).getTime() : -1;

    const documnetsForThis = documents.filter((doc) => {
      return doc.ReferenceId === item.Guid;
    });

    this.Attachments = mapDocumentsToFile(documnetsForThis);
    this.Collapse = true;
  }
}
