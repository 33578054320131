<aecom-modal
  [title]="title"
  class="modal-md"
  [canClose]="false"
  theme="light"
>
  <div class="modal-body">
    <h4>{{body}}</h4>
    <aecom-form-item
      id="reassignTo"
      [label]="'Reassign to'"
      [required]="true"
    >
      <aecom-dropdown
        data-cy="reassignTo"
        [theme]="'light'"
        placeholder="Select"
        [data]="userList"
        (ngValueChange)="setUserSelect($event)"
        [validate]="attemptToIssue && (!rfi.rfi_delegate_detail.UserId || rfi.rfi_delegate_detail.UserId==='')"
      ></aecom-dropdown>
    </aecom-form-item>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">Cancel</aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">Proceed</aecom-button>
    </div>
  </div>
</aecom-modal>
