import { Component, Input } from "@angular/core";
import RecallHistoryTableItem from "@models/recallHistoryTableItem";

@Component({
  selector: "recallHistoryTable",
  templateUrl: "./recallHistoryTable.component.html",
  styleUrls: ["./recallHistoryTable.component.scss"],
})
export class RecallHistoryTableComponent {
  @Input() data: RecallHistoryTableItem[] = [];
}
