import { IPRFIItem, IRFIItemBase } from "../api-generated";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class RFIUpdateBase
  extends TimeStampWithDraft
  implements IRFIItemBase
{
  public Guid?: string;

  public ContractId: string;

  public Status?: string;

  public BallInCourt?: string;

  public RFINumber?: string;

  public docSave?: string[];
  
  public docRemove?: string[];

  constructor(contractId: string, item?: IPRFIItem) {
    super(item);
    this.Guid = item?.Guid;
    this.ContractId = item?.ContractId ?? contractId;
    this.Status = item?.Status;
    this.BallInCourt = item?.BallInCourt;
    this.RFINumber = item?.RFINumber;
  }
}
