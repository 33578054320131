import { IPRFIReviewSubmitItem, RfiReviewer } from "../api-generated";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class RFIReview
  extends TimeStampWithDraft
  implements IPRFIReviewSubmitItem
{
  public Guid: string;

  public Response: string;

  public hasComments: boolean;

  constructor(rfiReview: RfiReviewer) {
    super(rfiReview);

    this.Guid = rfiReview.Guid;

    this.Response = rfiReview.Response ?? "";

    this.hasComments = true;
  }
}
