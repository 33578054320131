/* eslint-disable import/no-extraneous-dependencies */
import { ModalContainerService } from "@aecom/core";
import { Component } from "@angular/core";
import RFIRecallRequest from "@models/rfiRecallRequest";

@Component({
  selector: "app-recall-modal",
  templateUrl: "./recall-modal.component.html",
  styleUrls: ["./recall-modal.component.scss"]
})
export class RecallModalComponent {
  result = new RFIRecallRequest();

  isValid = false;

  validate = false;

  constructor(private activeModal: ModalContainerService) {}

  save() {
    this.validate = true;

    if (this.isValid) {
      this.activeModal.close(this.result);
    }
  }

  checkInput(e): void {
    this.result.comments = e;

    if (this.result.comments.length == 0) {
      this.isValid = false;
    } else {
      this.isValid = this.result.comments.toLowerCase().trim() !== "enter note";
    }
  }

  cancel() {
    this.activeModal.close(0);
  }
}
