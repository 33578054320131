<aecom-form columns="12" class="contentcontainer">
  <aecom-form-item class="itemBlock" [label]="'Issued By'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.originator }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Issue Date'" columnSpan="6">
    <div class="itemText">{{ rfiDetail.issuedDate }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Responded By'" columnSpan="6">
    <div class="itemText">{{ rfiDetail?.responder?.displayName || 'N/A'}}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Response Date'" columnSpan="6">
    <div class="itemText">{{ rfiDetail?.respondedDate || 'N/A'}}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Title'" columnSpan="12">
    <div class="itemText">{{ rfiDetail.title }}</div>
  </aecom-form-item>

  <aecom-form-item class="itemBlock" [label]="'Question'" columnSpan="12">
    <div class="itemText" [innerHtml]="rfiDetail.question">
    </div>
  </aecom-form-item>

  <aecom-form-group columnSpan="6" columns="1" title="Attachments">
    <aecom-form-item
      class="itemBlock"
      [label]="''"
      columnSpan="1"
    >
      <file-list [(fileList)]="docFiles" [listType]="fileListType" *ngIf="docFiles && docFiles.length > 0; else notAvailable"></file-list>
    </aecom-form-item>
  </aecom-form-group>

  <aecom-form-item class="itemBlock" columnSpan="6" [label]="''"></aecom-form-item>

  <aecom-form-group columnSpan="12" columns="2" title="Metadata">
    <aecom-form-item class="itemBlock" [label]="'Phase'" columnSpan="1">
      <div class="itemText">{{ rfiDetail?.phase || 'N/A' }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Classification'" columnSpan="1">
      <div class="itemText">{{ rfiDetail?.classification || 'N/A'}}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Contract Requirements'" columnSpan="2">
      <div class="itemText">{{ rfiDetail?.contractRequirement || 'N/A' }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'UniFormat Level 1 Category'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.division }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'UniFormat Level 2 Category'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.specification }}</div>
    </aecom-form-item>

    <!-- <aecom-form-item class="itemBlock" [label]="'MasterFormat Specification Section'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.masterSpec }}</div>
    </aecom-form-item> -->

    <aecom-form-item class="itemBlock" [label]="'Anticipated Schedule Impact'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.anticipatedScheduleImpact }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Anticipated Cost Impact'" columnSpan="1">
      <div class="itemText">{{ rfiDetail.anticipatedCostImpact  }}</div>
    </aecom-form-item>
  </aecom-form-group>

  <aecom-form-group columnSpan="12" columns="1" title="Additional Details">
    <aecom-form-item class="itemBlock" [label]="'Internal Reference Number'" columnSpan="1">
      <div class="itemText" *ngIf="rfiDetail.contractorReference; else notAvailable">{{ rfiDetail.contractorReference }}</div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Priority Note'" columnSpan="1">
      <div class="itemText" [innerHtml]="rfiDetail.note"></div>
    </aecom-form-item>

    <aecom-form-item class="itemBlock" [label]="'Processing Note'" columnSpan="1">
      <div class="itemText" [innerHtml]="rfiDetail.processingNotes"></div>
    </aecom-form-item>
  </aecom-form-group>
</aecom-form>

<ng-template #notAvailable>
  <div class="itemText">N/A</div>
</ng-template>
