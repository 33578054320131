import { v4 as uuidv4 } from 'uuid';
import { IPRFIItem, IRFICompileStep } from "../api-generated";
import RFICompilingBase from "./rfiCompilingBase";
import RFIUpdateBase from "./rfiUpdateBase";
import RFIStatus from "./rfiStatus";

export default class RFICompilingStep
  extends RFIUpdateBase
  implements IRFICompileStep
{
  public rfi_compiled_response: RFICompilingBase;
  public DosePMCAccept?: boolean;
  public ToDDC?: boolean | null;
  public ToSrManager?: boolean | null;
  public DDCManagerId?: string | null;
  public SrManagerId?: string | null;
  public tempId?: string;

  constructor(contractId: string, item: IPRFIItem) {
    super(contractId, item);

    this.DosePMCAccept = item.IsDraft ? item.DosePMCAccept : true;
    this.ToDDC = item.IsDraft ? item.ToDDC : false;
    this.ToSrManager = item.IsDraft ? item.ToSrManager : false;
    this.DDCManagerId = item.IsDraft ? item.DDCManagerId : undefined;
    this.SrManagerId = item.IsDraft ? item.SrManagerId : undefined;

    const compiles = item.rfi_compiled_response?.sort(
      (a, b) =>
        new Date(b.DateUpdated).getTime() - new Date(a.DateUpdated).getTime(),
    );
    if(compiles && compiles.length>0)
    {
      const lastCompile = compiles[0];
      if(lastCompile.IsDraft!==true)
      {
        this.rfi_compiled_response = new RFICompilingBase(item);
        this.tempId = uuidv4();
        if(item.Status !== RFIStatus.Review_Period)
        {
          if(lastCompile.ScheduleImpact!==null)
          {
            this.rfi_compiled_response.ScheduleImpact = lastCompile.ScheduleImpact;
          }
          if(lastCompile.CostImpact!==null)
          {
            this.rfi_compiled_response.CostImpact = lastCompile.CostImpact;
          }
          
          this.rfi_compiled_response.Response = lastCompile.Response;
        }
      }
      else {
        this.rfi_compiled_response = new RFICompilingBase(item, lastCompile);
      }
      
    } else {
      this.rfi_compiled_response = new RFICompilingBase(item);

      this.tempId = uuidv4();
    }
  }

  getReferenceId(): string {
    return this.rfi_compiled_response.Guid ?? this.tempId;
  }
}
