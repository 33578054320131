import { IRFIReopen, RfiReopen } from "../api-generated";

export default class RFIReopenDetail
  implements IRFIReopen
{
  public ChangeType?: string | null;
  public OtherChangeType?: string | null;
  public Reason?: string | null;
  public Significance?: number | null;
  public DirectedBy?: string | null;

  constructor(item?: RfiReopen) {
    this.ChangeType = item?.ChangeType;
    this.OtherChangeType = item?.OtherChangeType;
    this.Reason = item?.Reason;
    this.Significance = item?.Significance;
    this.DirectedBy = item?.DirectedBy;
  }
}
