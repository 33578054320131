/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable security/detect-non-literal-fs-filename */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
import {
  FileModel,
  groupData,
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPRFIItem,
  IPRFISpecification,
  RFIDocumentsService,
  RFIItemService,
} from "src/app/api-generated";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import IRFIViewPrepare from "src/app/models/IRFIViewPrepaer";
import RFIStatus from "src/app/models/rfiStatus";
import FileManagementService from "src/app/services/file-management.service";
import LoadingService from "src/app/services/loading.service";
import LocalClassificationService from "src/app/services/local-classification.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import LocalSpecificationService from "src/app/services/local-specification.service";
import LocalContractRequirementService from "src/app/services/local-contractRequirement.service";
import LocalPhaseService from "src/app/services/local-phase.service";
import { DesignClassificationId, MasterSpecType, PerformanceContractRequirementId, UniformatSpecType } from "src/app/shared/staticValue";
import { isFacOrTsbContract, isGARContract, isOldContract, isReviseDraft, prepareMutiLineText } from "src/app/shared/utils";
import AuthService from "../../auth/auth.service";
import RFICreateStep from "@models/rfiCreateStep";
import BaseFunctionService from "@services/baseFunction.service";

@Component({
  selector: "app-create-rfi",
  templateUrl: "./create-rfi.component.html",
  styleUrls: ["./create-rfi.component.scss"],
})
export default class CreateRfiComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  rfi: RFICreateStep;

  oldRFI: RFICreateStep;

  entity: IPRFIItem;

  isSaved = false;

  attemptToIssue = false;

  divisionList: ISelectItem[];

  specificationList: IPRFISpecification[] = [];

  specificationCleanList: ISelectItem[];

  classificationList: ISelectItem[];
  
  contractRequirementList: ISelectItem[];
  
  phaseList: ISelectItem[];

  anticipatedScheduleImpactList: ISelectItem[];

  anticipatedCostImpactList: ISelectItem[];

  masterSpecList: IPRFISpecification[] = [];

  masterSpecCleanList: ISelectItem[];

  contractId: string;

  division: string;

  titleMaxLength = 100;

  IsRevise = false;

  note = "";

  noteBy: string;

  questionsMaxLength = 1500;

  priorityNoteMaxLength = 150;

  processingNotesMaxLength = 150;

  IsContractRequirementRequied = false;

  IsUniformatRequired = false;

  IsOldContract = false;

  IsFacOrTsbContract = false;

  IsGARContract = false;

  currentUser: string;

  today = new Date().toISOString();

  constructor(
    public router: Router,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public rfiItemService: RFIItemService,
    public rfiDocuments: RFIDocumentsService,
    public activeModal: ModalContainerService,
    public localContractService: LocalContractService,
    public localContractUserService: LocalContractUserService,
    public fileManagementService: FileManagementService,
    public localSpecificationService: LocalSpecificationService,
    public localClassificationService: LocalClassificationService,
    public localContractRequirementService: LocalContractRequirementService,
    public localPhaseService: LocalPhaseService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    const data: IRFIViewPrepare | undefined = this.activatedRoute.snapshot.data.RFIPrepare;

    // determine if it's a old/new contract
    this.IsOldContract = isOldContract(this.localContractService.getItem().Code);
    
    this.IsFacOrTsbContract = isFacOrTsbContract(this.localContractService.getItem().Code);

    // determine if it's a GAR contract (a special old contract)
    this.IsGARContract = isGARContract(this.localContractService.getItem().Code);

    this.currentUser = this.authService.user.Id;

    // determine specification list
    if (this.IsGARContract) {
      // for QN-GAR contract only 
      this.specificationList = this.localSpecificationService
        .getItems()
        .filter((item) => {
            return item.SpecType === MasterSpecType
        });
    } else {
      // for all other other contracts
      this.specificationList = this.localSpecificationService
        .getItems()
        .filter((item) => {
            return item.SpecType === UniformatSpecType
        });
    }
    this.specificationCleanList = this.specificationList.map((item) => {
      return {
        name: `${item.SpecId} - ${item.Title}`,
        id: item.Guid,
        checked: false
      };
    });
    this.classificationList = this.localClassificationService.getItems()
    .filter((item) => {
      return item.InActive !== true;
    }).map((item)=>{
      return {
        name: item.Name,
        id: item.Guid,
        checked: false
      };
    });
    this.contractRequirementList = this.localContractRequirementService.getItems().map((item)=>{
      return {
        name: item.Name,
        id: item.Guid,
        checked: false
      };
    });
    this.phaseList = this.localPhaseService.getItems().map((item)=>{
      return {
        name: item.Title,
        id: item.Guid,
        checked: false
      };
    });
    
    this.anticipatedScheduleImpactList = [
      {name: 'No', id: '0', checked: true},
      {name: 'Yes', id: '1', checked: false}
    ];
    this.anticipatedCostImpactList = [
      {name: 'No', id: '0', checked: true},
      {name: 'Yes', id: '1', checked: false}
    ];
    
    this.masterSpecList = this.localSpecificationService
      .getItems()
      .filter((item) => {
          return item.SpecType === MasterSpecType
      });
    this.masterSpecCleanList = this.masterSpecList.map((item) => {
      return {
        name: `${item.SpecId} - ${item.Title}`,
        id: item.Guid,
        checked: false
      };
    });

    this.divisionList = [];
    const groups = groupData(_.sortBy(this.specificationList, "SpecId"), "Division");
    groups.forEach((v, k) => {
      if (k.toLowerCase().includes("division")) {
        this.divisionList.push({
          name: k,
          id: k.match(/division \d+/i)[0].replace(/division /i, ""),
          checked: false
        });
      } else if (k.toLowerCase().includes("agreement db")) {
        this.divisionList.push({
          name: k,
          id: k.match(/agreement db/i)[0].replace(/agreement db /i, ""),
          checked: false
        });
      } else if (k.toLowerCase().includes("exhibit")) {
        this.divisionList.push({
          name: k,
          id: k.match(/exhibit \w+/i)[0].replace(/exhibit /i, ""),
          checked: false
        });
      } else {
        this.divisionList.push({
          name: k,
          id: k,
          checked: false,
        });
      }
    });

    this.contractId = this.localContractService.getItem().Guid;

    this.rfi = new RFICreateStep(this.contractId, data?.rfi);
    if(data)
    {
      this.entity = data.rfi;
      this.division = this.divisionList.find((i) => i.name === data.rfi.rfi_specification?.Division)?.id || "";
      this.IsRevise = (this.entity.Status === RFIStatus.QAQC_Fail || isReviseDraft(this.entity)) && this.entity.rfi_reject_detail?.length > 0;

      if (this.IsRevise) {
        const lastReject = this.entity.rfi_reject_detail.sort(
          (a, b) =>
            new Date(b.DateUpdated).getTime() -
            new Date(a.DateUpdated).getTime(),
        )[0];
        this.note = prepareMutiLineText(lastReject.Note);
        this.noteBy = this.localContractUserService.getItems()
          .find((u) => {
            return u.UserId === lastReject.UpdatedBy
          })?.UserInfo?.displayName ?? '';
      }
    } else {
      this.baseFunctionService.setTempId(this.rfi.getGuid());
    }

    this.IsContractRequirementRequied = this.getIsContractRequirementRequied();
    this.IsUniformatRequired = this.getIsUniformatRequired();

    this.oldRFI = JSON.parse(JSON.stringify(this.rfi));
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldRFI);
    const currentRFI = JSON.stringify(this.rfi);

    // true means no changes
    return old !== currentRFI || this.baseFunctionService.areFilesChanged();
  }

  canIssue(): boolean {
    return this.IsOldContract === true 
      ? !(((this.IsGARContract || (!this.IsGARContract && this.IsUniformatRequired)) && (!this.division || !this.rfi.SpecificationId)) ||
          !this.rfi.ClassificationId ||
          !this.rfi.Title ||
          !this.rfi.Question ||
          (this.rfi.Priority === 3 && !this.rfi.PriorityReason) ||
          !this.baseFunctionService.isFileNameValid()
        )
      : !(
          (this.IsUniformatRequired ? !this.division : false) || // division is required only if IsDesignClassification = false
          (this.IsUniformatRequired ? !this.rfi.SpecificationId: false ) || // secification is required only if IsDesignClassification = false
          !this.rfi.ClassificationId ||
          !this.rfi.Title ||
          !this.rfi.Question ||
          (this.rfi.Priority === 3 && !this.rfi.PriorityReason) ||
          !this.baseFunctionService.isFileNameValid() ||
          !this.rfi.PhaseId ||
          (this.IsContractRequirementRequied ? !this.rfi.ContractRequirementId : false) || // division is required only if IsDesignClassification = true
          this.rfi.AnticipatedScheduleImpact === undefined ||
          this.rfi.AnticipatedCostImpact === undefined
        );
  }

  isValid(): boolean {
    return this.IsOldContract === true 
      ? !(((this.IsGARContract || (!this.IsGARContract && this.IsUniformatRequired)) && (!this.division || !this.rfi.SpecificationId)) ||
          !this.rfi.ClassificationId ||
          !this.rfi.Title || this.baseFunctionService.isInputInvalid(this.rfi.Title) ||
          !this.rfi.Question || this.baseFunctionService.isInputInvalid(this.rfi.Question) ||
          (this.rfi.Priority === 3 && (!this.rfi.PriorityReason || this.baseFunctionService.isInputInvalid(this.rfi.PriorityReason))) ||
          !this.baseFunctionService.isFileNameValid()
        )
      : !(
          (this.IsUniformatRequired ? !this.division : false) || // division is required only if IsDesignClassification = false
          (this.IsUniformatRequired ? !this.rfi.SpecificationId: false ) || // secification is required only if IsDesignClassification = false
          !this.rfi.ClassificationId ||
          !this.rfi.Title || this.baseFunctionService.isInputInvalid(this.rfi.Title) ||
          !this.rfi.Question || this.baseFunctionService.isInputInvalid(this.rfi.Question) ||
          (this.rfi.Priority === 3 && (!this.rfi.PriorityReason || this.baseFunctionService.isInputInvalid(this.rfi.PriorityReason))) ||
          !this.baseFunctionService.isFileNameValid() ||
          !this.rfi.PhaseId ||
          (this.IsContractRequirementRequied ? !this.rfi.ContractRequirementId : false) || // division is required only if IsDesignClassification = true
          this.rfi.AnticipatedScheduleImpact === undefined ||
          this.rfi.AnticipatedCostImpact === undefined
        );
  }

  IsTextLengthValid(): boolean {
    return this.getTitleLength() <= this.titleMaxLength && !this.baseFunctionService.isInputInvalid(this.rfi.Title) &&
    this.getQuestionsLength() <= this.questionsMaxLength && !this.baseFunctionService.isInputInvalid(this.rfi.Question) &&
    this.getPriorityNoteLength() <= this.priorityNoteMaxLength && !this.baseFunctionService.isInputInvalid(this.rfi.PriorityReason) &&
    this.getProcessingNotesLength() <= this.processingNotesMaxLength && !this.baseFunctionService.isInputInvalid(this.rfi.ProcessingNotes);
  }

  issueRFI(): void {
    this.attemptToIssue = true;

    if (!this.canIssue() || !this.IsTextLengthValid()) {
      return;
    }

    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else {
      const modalInstance = this.activeModal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Issue RFI?";
      modalInstance.instance.body =
        "Your RFI will be sent to the Doc Control. Once it's submitted, you won't be able to edit it.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.rfi.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveRFIDraft(): void {
    if (!this.hasChanges()||!this.baseFunctionService.isFileNameValid()||!this.IsTextLengthValid()) return;
    this.attemptToIssue = true;
    
    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else {
      try {
        const modalInstance = this.activeModal.open(NotificationComponent);
        modalInstance.instance.theme = "light";
        modalInstance.instance.title = "Save as Draft?";
        modalInstance.instance.body = "Your RFI will save as draft.";

        modalInstance.result.then((result) => {
          if (result === 1) {
            this.rfi.IsDraft = true;
            console.log(this.rfi);
            this.update();
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  update(): void {
    this.loadingService.start();
    this.baseFunctionService.update(this.rfi);
  }

  setPriority(e: number): void {
    if (_.isNumber(e)) {
      this.rfi.Priority = e;
    }
  }

  setDivisionList(e: ISelectItem): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.division = e.id;
      this.rfi.SpecificationId = null;
      this.specificationCleanList = [];

      this.specificationList
        .filter((i) => {
          return (
            (i.Division.toLowerCase().includes("division") &&
              i.Division?.match(/division \d+/i)[0].replace(/division /i, "") ===
                e?.id) ||
            (i.Division.toLowerCase().includes("agreement db") &&
              i.Division?.match(/agreement db/i)[0].replace(
                /agreement db /i,
                ""
              ) === e?.id) ||
            (i.Division.toLowerCase().includes("exhibit") &&
              i.Division?.match(/exhibit \w+/i)[0].replace(/exhibit /i, "") ===
                e?.id) ||
            (i.Division &&
              i.Division === e?.id)
          );
        })
        .forEach((item) => {
          this.specificationCleanList.push({
            name: `${item.SpecId} - ${item.Title}`,
            id: item.Guid.toString(),
            checked: false,
          });
        });
    }
  }

  setSpecificationList(e: ISelectItem): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.rfi.SpecificationId = e?.id ?? null;
    }
  }

  setClassificationList(e: ISelectItem): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.rfi.ClassificationId = e?.id ?? null;

      this.IsContractRequirementRequied = this.getIsContractRequirementRequied();
      this.IsUniformatRequired = this.getIsUniformatRequired();
    }
  }

  setContractRequirementList(e: ISelectItem): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.rfi.ContractRequirementId = e?.id ?? null;
      this.IsUniformatRequired = this.getIsUniformatRequired();
    }
  }

  setPhaseList(e: ISelectItem): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.rfi.PhaseId = e?.id ?? null;
    }
  }

  setAnticipatedScheduleImpact(e: boolean): void {
    if (_.isBoolean(e)) {
      this.rfi.AnticipatedScheduleImpact = e;
    }
  }

  setAnticipatedCostImpact(e: boolean): void {
    if (_.isBoolean(e)) {
      this.rfi.AnticipatedCostImpact = e;
    }
  }

  setMasterSpecList(e: ISelectItem | any): void {
    if (!_.isArray(e) && !_.isEmpty(e)) {
      this.rfi.MasterSpecId = e?.id ?? null;
    }
  }

  deleteFile(e: FileModel): void {
    this.baseFunctionService.deleteFile(e);
  }

  importFile(e: FileModel[]): void {
    this.baseFunctionService.importFile(e);
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  getTitleLength(): number {
    return this.rfi.Title ? this.rfi.Title.length : 0;
  }

  getQuestionsLength(): number {
    return this.rfi.Question ? this.rfi.Question.length : 0;
  }

  getPriorityNoteLength(): number {
    return this.rfi.PriorityReason
      ? this.rfi.PriorityReason.length
      : 0;
  }

  getProcessingNotesLength(): number {
    return this.rfi.ProcessingNotes
      ? this.rfi.ProcessingNotes.length
      : 0;
  }

  getIsContractRequirementRequied(): boolean {
    return this.IsFacOrTsbContract || this.rfi.ClassificationId === DesignClassificationId;
  }

  getIsUniformatRequired(): boolean {
    return this.IsGARContract || 
      (this.IsFacOrTsbContract && this.rfi.ContractRequirementId === PerformanceContractRequirementId) || 
      (!this.IsFacOrTsbContract && this.rfi.ClassificationId !== DesignClassificationId);
  }
}
