<div class="row" style="margin-bottom: 20px">
  <div class="col-12">
    <aecom-form columns="12">
      <aecom-form-group
        columnSpan="12"
        columns="1"
        title="Rejection History"
      >
        <div columnSpan="1" class="timelineTable" *ngIf="data && data.length > 0; else noneData">
          <div class="row">
            <div class="col-1 th" style="text-align: center;">#</div>
            <div class="col-5 th">Rejection Note</div>
            <div class="col-3 th">Rejected By</div>
            <div class="col-3 th">Rejected Date</div>
          </div>
          <ng-container *ngFor="let row of data">
            <div class="row tr">
              <div class="col-1 td" style="align-items: center;">
                <div class="circle">
                  <div style="margin: 0 auto">{{ row.Sequence }}</div>
                </div>
              </div>
              <div class="col-5 td" [innerHtml]="row.Note"></div>
              <div class="col-3 td hiddenTextOutterBox"><div class="hiddenText">{{ row.RejectedBy }}</div></div>
              <div class="col-3 td hiddenTextOutterBox"><div class="hiddenText">{{ row.RejectedDate }}</div></div>
            </div>
          </ng-container>
        </div>
        <ng-template #noneData>
          <div style="width: 100%;height: 100%;display: flex;align-items: center;">
            <div class="noItem-body">
              <div>
                <div class="noItem-icon-row icon icon-social_user_team_big icon-grey icon-xl"></div>
              </div>
              <div class="noItem-title-row">None</div>
            </div>
          </div>
        </ng-template>
      </aecom-form-group>
    </aecom-form>
  </div>
</div>