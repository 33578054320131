import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import tableHeader from "@content/selectUser/table-header";
import TableHeaderCol from "@content/selectUser/table-header-col";
import RowItem from "@content/selectUser/table-row";
import ColumnType from "@models/columnType";
import BaseFunctionService from "@services/baseFunction.service";

export interface RemoveUserArg{
  row:RowItem,
  isReviewer:boolean
}
@Component({
  selector: "selectedUsersTable",
  templateUrl: "./selectedUsersTable.component.html",
  styleUrls: ["./selectedUsersTable.component.scss"],
})
export default class SelectedUsersTableComponent implements OnInit {
  @Input() isReviewer: boolean;

  @Input() tableData: RowItem[] = [];

  @Output() removeUserClick = new EventEmitter<RemoveUserArg>();

  @Output() openWindowClick = new EventEmitter<boolean>();
  
  tableHeader: TableHeaderCol[] = tableHeader;

  columnType = ColumnType;

  constructor(
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    
  }

  removeUser(row: RowItem): void {
    this.removeUserClick.emit({row, isReviewer: this.isReviewer});
  }

  openWindow(): void {
    this.openWindowClick.emit(this.isReviewer);
  }
}
