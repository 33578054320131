<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-header">
        <div class="col-6" style="padding: 0; display: flex;">
          <div class="header-title">
            <p>
              <a [routerLink]="['/' + rfi.ContractId + '/list']">
                RFI list &gt;
              </a>
              <span> {{ rfi.Guid && rfi.Guid !== "" ? "Edit" : "New" }} RFI</span>
            </p>

            <h1>
              <i class="icon icon-lg icon-pencil"></i>

              <span
                >{{
                  rfi.Guid && rfi.Guid !== ""
                    ? IsRevise
                      ? "Revise"
                      : "Edit Draft"
                    : "CREATE NEW"
                }}
                RFI</span
              >
            </h1>
          </div>
          <div style="flex: 1; display: flex; align-items: flex-end;" *ngIf="IsRevise">
            <div class="header-subTitle" style="margin-left: auto;">{{ rfi.RFINumber }}</div>
          </div>
        </div>
        <div class="col-6" style="padding: 0;">
          <div class="header-actions">
            <aecom-button innerClass="btn lg outline orange" (click)="back()">
              back
            </aecom-button>

            <aecom-button
              *ngIf="rfi.Guid"
              innerClass="btn lg outline orange"
              (click)="baseFunctionService.dbVoidRFI()"
            >
              void
            </aecom-button>

            <!-- <aecom-button
              *ngIf="rfi.guid"
              innerClass="btn lg outline orange"
              (click)="deletageRFI()"
            >
              delegate
            </aecom-button> -->

            <aecom-button
              [theme]="'light'"
              [disabled]="!hasChanges() ? true : null"
              innerClass="btn lg solid green"
              (click)="saveRFIDraft()"
            >
              save draft
            </aecom-button>

            <aecom-button
              [theme]="'light'"
              [disabled]="!canIssue() ? true : null"
              innerClass="btn lg solid blue"
              (click)="issueRFI()"
            >
              issue rfi
            </aecom-button>

          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <aecom-form columns="12">
        <div style="grid-column: span 6 / auto; position: relative">
          <aecom-form-group 
            title="DETAIL"
            columnSpan="6"
            columns="6"
            style="display: block; margin-bottom: 10px; min-height: 60%"
          >
            <aecom-form-item
              *ngIf="!IsGARContract"
              columnSpan="3" [label]="'Internal Reference Number'">
              <aecom-textbox
                [theme]="'light'"
                [(ngValue)]="rfi.ContractorReference"
              ></aecom-textbox>
            </aecom-form-item>
  
            <aecom-form-item
              columnSpan="6"
              [label]="'Title'"
              [property]="getTitleLength() + '/' + titleMaxLength + ' characters'"
              [required]="true"
            >
              <aecom-textbox
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="titleMaxLength"
                [(ngValue)]="rfi.Title"
                [validate]="
                  attemptToIssue &&
                  (!rfi.Title?.length || getTitleLength() > titleMaxLength || baseFunctionService.isInputInvalid(rfi.Title))
                "
              ></aecom-textbox>
            </aecom-form-item>
  
            <aecom-form-item
              columnSpan="6"
              [label]="'Question'"
              [property]="
                getQuestionsLength() + '/' + questionsMaxLength + ' characters'
              "
              [required]="true"
            >
              <aecom-textbox
                rows="20"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="questionsMaxLength"
                [isMultiLine]="true"
                [(ngValue)]="rfi.Question"
                [validate]="
                  attemptToIssue &&
                  (!rfi.Question?.length || getQuestionsLength() > questionsMaxLength || baseFunctionService.isInputInvalid(rfi.Question))
                "
              ></aecom-textbox>
            </aecom-form-item>
  
            <aecom-form-item columnSpan="3" [label]="'Attachments'">
              <aecom-upload
                [isNewStyle]="true"
                [multiFiles]="true"
                [canDelete]="true"
                [data]="(baseFunctionService.fileList$ | async)"
                [theme]="'light'"
                (ngValueChange)="importFile($event)"
                (ngDeleteChange)="deleteFile($event)"
              ></aecom-upload>
              <div class="error-message" *ngIf="!baseFunctionService.isFileNameValid()">
                File Name cannot include special characters other than '-', '_', '.', '@'
              </div>
            </aecom-form-item>
          </aecom-form-group>
        </div>
        <div style="grid-column: span 3 / auto">
          <aecom-form-group columnSpan="3" title="{{ IsGARContract === true ? 'Additional info' : 'Metadata'}}">
            <aecom-form-item 
              *ngIf="!IsOldContract"
              [label]="'Phase'" 
              columnSpan="3"
              [required]="true"
            >
              <aecom-dropdown
                *ngIf="phaseList.length"
                [theme]="'light'"
                placeholder="Select"
                [data]="phaseList"
                [selectedId]="rfi.PhaseId"
                [validate]="attemptToIssue && !rfi.PhaseId"
                (ngValueChange)="setPhaseList($event)"
                (change)="setPhaseList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
            <aecom-form-item [label]="'Classification'" columnSpan="3" [required]="true">
              <aecom-dropdown
                *ngIf="classificationList.length"
                [theme]="'light'"
                placeholder="Select"
                [data]="classificationList"
                [selectedId]="rfi.ClassificationId"
                [validate]="attemptToIssue && !rfi.ClassificationId"
                (ngValueChange)="setClassificationList($event)"
                (change)="setClassificationList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
            <aecom-form-item 
              *ngIf="!IsOldContract"
              [label]="
                'Contract Requirements (DBA/GPR/SPR)'
              " 
              columnSpan="3"
              [required]="IsContractRequirementRequied"
            >
              <aecom-dropdown
                *ngIf="contractRequirementList.length"
                [theme]="'light'"
                placeholder="Select"
                [data]="contractRequirementList"
                [autocomplete]="true"
                [selectedId]="rfi.ContractRequirementId"
                [validate]="
                  attemptToIssue && 
                  (!IsContractRequirementRequied ? false : (rfi.ContractRequirementId === undefined))
                " 
                (ngValueChange)="setContractRequirementList($event)"
                (searchedValue)="setContractRequirementList($event)"
                (change)="setContractRequirementList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
           <aecom-form-item 
              [label]="
                IsGARContract 
                  ? 'Division' 
                  : 'UniFormat Level 1 Category'
              " 
              columnSpan="3"
              [required]="IsGARContract || IsUniformatRequired"
            >
              <aecom-dropdown
                [theme]="'light'"
                placeholder="Select"
                [data]="divisionList"
                [autocomplete]="true"
                [selectedId]="division"
                [validate]="
                  attemptToIssue && 
                  (!IsUniformatRequired ? false: (division === undefined))
                "
                (ngValueChange)="setDivisionList($event)"
                (searchedValue)="setDivisionList($event)"
                (change)="setDivisionList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
            <aecom-form-item 
              [label]="
                IsGARContract 
                  ? 'Specification' 
                  : 'UniFormat Level 2 Category'
              " 
              columnSpan="3"
              [required]="IsGARContract || IsUniformatRequired"
            >
              <aecom-dropdown
                [theme]="'light'"
                placeholder="Select"
                [data]="specificationCleanList"
                [autocomplete]="true"
                [selectedId]="rfi.SpecificationId"
                [validate]="
                  attemptToIssue && 
                    (!IsUniformatRequired ? false : (rfi.SpecificationId === undefined))
                "
                (ngValueChange)="setSpecificationList($event)"
                (searchedValue)="setSpecificationList($event)"
                (change)="setSpecificationList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
            <aecom-form-item 
              *ngIf="!IsGARContract"
              [label]="'MasterFormat Specification Section (6-digit)'" 
              columnSpan="3"
            >
              <aecom-dropdown
                [theme]="'light'"
                placeholder="Select"
                [data]="masterSpecCleanList"
                [autocomplete]="true"
                [selectedId]="rfi.MasterSpecId"
                (ngValueChange)="setMasterSpecList($event)"
                (searchedValue)="setMasterSpecList($event)"
                (change)="setMasterSpecList($event)"
              ></aecom-dropdown>
            </aecom-form-item>
  
            <aecom-form-item [label]="'Anticipated Schedule Impact?'" columnSpan="3" [required]="true">
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.AnticipatedScheduleImpact === true ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setAnticipatedScheduleImpact(true)"
                >
                  Yes
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.AnticipatedScheduleImpact === false ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setAnticipatedScheduleImpact(false)"              >
                  No
                </aecom-button>
              </div>
            </aecom-form-item>
  
            <aecom-form-item [label]="'Anticipated Cost Impact?'" columnSpan="3" [required]="true">
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.AnticipatedCostImpact === true ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setAnticipatedCostImpact(true)"
                >
                  Yes
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.AnticipatedCostImpact === false ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setAnticipatedCostImpact(false)"              >
                  No
                </aecom-button>
              </div>
            </aecom-form-item>
  
            <!-- <aecom-form-item [label]="'Type (Optional)'" columnSpan="3">
              <aecom-checkbox [theme]="'light'" [(ngValue)]="rfi.isConfidential">
                Is Confidential
              </aecom-checkbox>
            </aecom-form-item> -->
          </aecom-form-group>
        </div>
        <div style="grid-column: span 3 / auto">
          <aecom-form-group columnSpan="3" title="{{ IsGARContract === true ? 'References' : 'Additional Details'}}">
            <aecom-form-item [label]="'Priority'" columnSpan="3">
              <div class="button-wrapper d-flex">
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.Priority === 1 ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setPriority(1)"
                >
                  Low
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.Priority === 2 ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setPriority(2)"
                >
                  Medium
                </aecom-button>
  
                <aecom-button
                  innerClass="btn solid btn-tab {{
                    rfi.Priority === 3 ? 'blue' : 'bg-grey-e'
                  }}"
                  (click)="setPriority(3)"
                >
                  High
                </aecom-button>
              </div>
  
            </aecom-form-item>
            <aecom-form-item
              [property]="getPriorityNoteLength() + '/' + priorityNoteMaxLength + ' characters'"
              columnSpan="3"
              class="formItemNoMargin"
              *ngIf="rfi.Priority === 3"
            >
              <aecom-textbox
                rows="5"
                [theme]="'light'"
                [notEmpty]="true"
                [maxlength]="priorityNoteMaxLength"
                [isMultiLine]="true"
                [(ngValue)]="rfi.PriorityReason"
                [validate]="
                  attemptToIssue && 
                  (!rfi.PriorityReason?.length || getPriorityNoteLength() > priorityNoteMaxLength || baseFunctionService.isInputInvalid(rfi.PriorityReason))"
                placeholder="Please enter the reason."
              ></aecom-textbox>
            </aecom-form-item>
      
            <!-- <aecom-form-item [label]="'Reference URL (Optional)'">
              <aecom-textbox
                [theme]="'light'"
                [(ngValue)]="rfi.ReferenceURL"
              ></aecom-textbox>
            </aecom-form-item> -->
  
            <aecom-form-item
              [label]="'Processing Notes'"
              [property]="getProcessingNotesLength() + '/' + processingNotesMaxLength + ' characters'"
              columnSpan="3"
            >
              <aecom-textbox
                rows="5"
                [theme]="'light'"
                [notEmpty]="false"
                [maxlength]="processingNotesMaxLength"
                [isMultiLine]="true"
                [(ngValue)]="rfi.ProcessingNotes"
                [validate]="
                  attemptToIssue && 
                  (getProcessingNotesLength() > processingNotesMaxLength || baseFunctionService.isInputInvalid(rfi.ProcessingNotes))
                "
              >
            </aecom-textbox>
            </aecom-form-item>
            
            <div class="tab-container" *ngIf="IsRevise">
              <div class="tab-header">
                <span class="tab-title"
                  >QA/QC Failed Note by :
                  <span class="color-red" *ngIf="noteBy">{{ noteBy }}</span>
                </span>
              </div>
              <div class="bottom-padding">
                <div class="content-value" [innerHtml]="note"></div>
              </div>
            </div>

            <!-- <div style="display: block; color: black;"> 
              <div>
                IsDesignClassification: {{IsDesignClassification}}
              </div>
              <div>
                IsOldContract: {{IsOldContract}}
              </div>
              <div>
                IsGARContract: {{IsGARContract}}
              </div>
              <div>
                canIssue: {{canIssue()}}
              </div>
              <div>
                attempToIssue: {{attemptToIssue}}
              </div>
              <div>
                Internal Reference Number: {{rfi.ContractorReference}}
              </div>
              <div>
                Title: {{rfi.Title}}
              </div>
              <div>
                Question: {{rfi.Question}}
              </div>
              <div>
                Phase: {{rfi.PhaseId}}
              </div>
              <div>
                Classification: {{rfi.ClassificationId}}
              </div>
              <div>
                Contract Requirements: {{rfi.ContractRequirementId}}
              </div>
              <div>
                Contract Requirements inValid: {{
                  attemptToIssue && 
                  ((!IsDesignClassification || rfi.ClassificationId===undefined) ? false : (rfi.ContractRequirementId === undefined))                }}
              </div>
              <div>
                Division: {{division}}
              </div>
              <div>
                Division inValid: {{
                  attemptToIssue && 
                  ((IsDesignClassification || rfi.ClassificationId===undefined) ? false: (division === undefined))
                }}
              </div>
              <div>
                Specification: {{rfi.SpecificationId}}
              </div>
              <div>
                Specification inValid: {{
                  attemptToIssue && 
                  ((IsDesignClassification || rfi.ClassificationId===undefined) ? false : (rfi.SpecificationId === undefined))
                }}
              </div>
              <div>
                MasterSpec: {{rfi.MasterSpecId}}
              </div>
              <div>
                AnticipatedScheduleImpact: {{rfi.AnticipatedScheduleImpact}}
              </div>
              <div>
                AnticipatedCostImpact: {{rfi.AnticipatedCostImpact}}
              </div> 
              <div>
                Priority: {{rfi.Priority}}
              </div>
              <div>
                Priority Reason: {{rfi.PriorityReason}}
              </div>
              <div>
                Processing Notes: {{rfi.ProcessingNotes }}
              </div>
            </div> -->
          </aecom-form-group>
        </div>
      </aecom-form>
    </div>
  </div>
</div>
