import { FileModel, ModalContainerService, UploadStatus } from "@aecom/core";
import { Component } from "@angular/core";
import AuthService from "@auth/auth.service";
import RFIDocumentUpload from "@models/rfiDocumentUpload";
import RFIReopenStep from "@models/rfiReopenStep";
import RFIRole from "@models/rfiRoles";
import RFIStatus from "@models/rfiStatus";
import AzureBlobService from "@services/azureBlob.service";
import BaseFunctionService from "@services/baseFunction.service";
import LocalContractUserService from "@services/local-contractUser.service";
import { isFileNameInvalid } from "@shared/utils";
import _ from "lodash";
import { IPRFIDocumentUploadReturn, RFIDocumentsService } from "src/app/api-generated";

@Component({
  selector: "app-reopen-modal",
  templateUrl: "./reopen-modal.component.html",
  styleUrls: ["./reopen-modal.component.scss"]
})
export class ReopenModalComponent {
  item: RFIReopenStep;
  attemptToIssue = false;

  fileList: FileModel[] = [];

  fileListNameValid = true;

  private removedFileIds: string[] = [];

  constructor(
    private activeModal: ModalContainerService,
    private rfiDocumentsService: RFIDocumentsService,
    private authService: AuthService,
    public baseFunctionService: BaseFunctionService,
    public localContractUserService: LocalContractUserService,
  ) {}

  save() {
    this.attemptToIssue = true;

    if (this.hasFilesUploading()) {
      this.showFileProgressNotification();
    } else if (this.isValid()) {
      if(!this.item.rfi_reopen.ChangeType.includes('other'))
      {
        delete this.item.rfi_reopen.OtherChangeType;
      }
      this.updateFiles();
      this.activeModal.close(this.item);
    }
  }

  isValid(): boolean {
    const role = this.localContractUserService.currentUserContractRole;
    const isDoc = role === RFIRole.Doc_Control;
    return this.item.rfi_reopen.ChangeType?.length > 0 
      && this.item.rfi_reopen.Reason?.length > 0 
      && this.item.rfi_reopen.Reason.length <= 2000 
      && this.isFileNameValid()
      && (isDoc ? !_.isEmpty(this.item.rfi_reopen.DirectedBy) : true)
      // && this.item.rfi_reopen.Significance != undefined
  }

  cancel() {
    this.activeModal.close(null);
  }

  updateFiles(): void {
    const filesToAdd = [...this.fileList].filter((f) => {
      return f.Status === UploadStatus.LOADED;
    }).map((f) => {
      return f.Guid;
    });

    this.item.docSave = [...new Set([...filesToAdd])];
    this.item.docRemove = [...new Set([...this.removedFileIds])];
  }

  importFile(e: FileModel[]): void {
    const currentUser = this.authService.user.Id;
    if (!_.isEmpty(e)) {
      e.forEach(async (item) => {
        if (item.Status === UploadStatus.UPLOADING && item.Percentage === 0) {
          const fileUploaded = new RFIDocumentUpload(
            item.Name,
            currentUser,
            RFIStatus.Request_to_Reopen,
            this.item.Guid,
            this.item.tempId
          );

          await this.rfiDocumentsService
            .createRFIDocument(fileUploaded)
            .toPromise()
            .then((r: IPRFIDocumentUploadReturn) => {
              item.Guid = r.Guid;
              this.updateFileList(item);
              AzureBlobService.uploadFile(r.URL, item);
            });
        } else if (
          item.Status === UploadStatus.FAILED ||
          item.Status === UploadStatus.CANCELED
        ) {
          this.deleteFile(item);
        }
      });
    }
  }

  deleteFile(e: FileModel): void {
    this.updateFileList(e, true);
  }

  updateFileList(item: FileModel, isRemove = false): void {
    const index = this.fileList.findIndex((file) => {
      return file.Name === item.Name;
    });
    if (isRemove) {
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    } else if (index === -1) {
      this.fileList.push(item);
      this.fileList = this.fileList.slice();
    }
    if(isRemove)
    {
      this.removedFileIds.push(item.Guid);
    }
    console.log("filelise:",this.fileList);
    console.log("removeFiles:",this.removedFileIds);
    this.isFileNameValid();
  }

  isFileNameValid(): boolean {
    this.fileListNameValid = true;
    this.fileList.forEach((f) => {
      if (isFileNameInvalid(f.Name)) {
        this.fileListNameValid = false;
      }
    });
    return this.fileListNameValid;
  }

  hasFilesUploading(): boolean {
    return this.fileList.some((item) => item.Status === UploadStatus.UPLOADING);
  }

  showFileProgressNotification(): void {
    this.baseFunctionService.showFileProgressNotification();
  }
}
