import { IPRFIItem, IRFIReopenStep, RfiReopen } from "../api-generated";
import RFIReopenDetail from "./rfiReopenDetail";
import RFIUpdateBase from "./rfiUpdateBase";

export default class RFIReopenStep
  extends RFIUpdateBase
  implements IRFIReopenStep
{
  public rfi_reopen: RFIReopenDetail;

  public tempId?: string;

  constructor(contractId: string, rfi: IPRFIItem, item?: RfiReopen) {
    super(contractId, rfi);
    this.rfi_reopen = new RFIReopenDetail(item);
  }
}
