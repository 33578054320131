<div class="report-margin">
  <div class="innercontainer no-print-margin col-12" style="padding: 0; height: 100%;">
    <div class="no-print row justify-content-end action-btns" style="margin: 0; padding: 29px 60px;">
      <aecom-button data-cy="back" innerClass="btn lg outline orange" (click)="back()">
        CANCEL
      </aecom-button>
      <aecom-button innerClass="btn solid blue lg unchnage" (click)="download()">
        DOWNLOAD
      </aecom-button>
    </div>
    <!-- report -->
    <div class="no-print-margin" style="overflow-y: auto; height: 100%;" *ngIf="rfi">
      <div class="masthead1">
        <div class="report-page-size relative" id="pdfForm1" #pdfForm1>
          <div>
            <!-- Header -->
            <div class="d-flex">
              <div>
                <span class="header-page-no-text"><img src="./../../../../assets/img/product-icons/ddc_logo.svg">
                </span>
              </div>
              <div class="ml-auto nopadding">
                <span class="header-page-no-text">
                  <img src="./../../../../assets/img/product-icons/aecom_hill_av.svg">
                </span>
              </div>
            </div>
            <!-- First Row -->
            <div class="d-flex">
              <div>
                <span class="title-text">
                  RFI Response Form
                </span>
              </div>
              <div class="ml-auto">
                <span class="title-text">
                  <!-- {{rfiDetail.rfiNumber}} -->
                </span>
              </div>
            </div>
            <!-- Second Row -->
            <div class="d-flex" style="margin-bottom: 30px;">
              <div>
                <span class="h-grey-font">
                  {{productmaintitle}} | CONTRACT: {{contractName}}
                </span>
              </div>
              <div class="ml-auto">
                <span class="h-grey-font">
                  FMS ID: {{fmsId}}
                </span>
              </div>
            </div>

            <!-- Third Row -->
            <div style="margin-bottom: 18px;">
              <div class="blue-border">
                <span class="h-black-font">RFI Details</span>
              </div>
              <div class="grey-bg">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <div class="sub-info-title">ID</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail.rfiNumber}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-5">
                        <div class="sub-info-title">Internal Ref. # (DB)</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail.contractorReference}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <div class="sub-info-title">Title</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail.title}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-5">
                        <div class="sub-info-title">Phase</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail.phase}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <div class="sub-info-title">Issued By</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail.issuedBy}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-5">
                        <div class="sub-info-title">Issue Date</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail?.issuedDate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <div class="sub-info-title">Classification</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail?.classification}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-5">
                        <div class="sub-info-title">UniFormat Level 1</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail?.specification}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-4">
                        <div class="sub-info-title">Contract Req.</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail?.contractRequirement}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-5">
                        <div class="sub-info-title">UniFormat Level 2</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail?.division}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <div style="padding: 0 0 0 10px;">
                          <img src="./../../../../assets/img/checked-box.svg" class="checked" *ngIf="
                          rfiDetail?.anticipatedScheduleImpact==='Yes'; else unchecked"/>
                          &nbsp;<span class="sub-info-text">Anticipated Schedule Impact</span>
                          <ng-template #unchecked>
                            <img src="./../../../../assets/img/unchecked-box.svg" class="unchecked" />
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <div style="padding: 0 0 0 10px;">
                          <img src="./../../../../assets/img/checked-box.svg" class="checked" *ngIf="
                          rfiDetail?.anticipatedCostImpact==='Yes'; else unchecked"/>
                          &nbsp;<span class="sub-info-text">Anticipated Cost Impact</span>
                          <ng-template #unchecked>
                            <img src="./../../../../assets/img/unchecked-box.svg" class="unchecked" />
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Fourth Row -->
            <div class="blackBoxBorder">
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Question</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">{{rfiDetail?.question || "None"}}</div>
                </div>
              </div>
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Attachments</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">
                    <ng-container *ngIf="refFiles && refFiles.length > 0">
                      <ng-container *ngFor="let doc of refFiles">
                        <a class="green" [href]="doc.URL" target="_blank">
                          {{ doc.Name }}
                        </a>
                      </ng-container>
                    </ng-container>
                    <span *ngIf="!refFiles || refFiles.length === 0">None</span>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Fifth Row -->
            <div *ngIf="numberOfPage === 1" style="margin-bottom: 18px;">
              <div class="grey-bg">
                <div class="row">
                  <div class="col-6">
                    <div class="row top-aligned">
                      <div class="col-4">
                        <div class="sub-info-title">Responded By</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail?.responder | userNameCompanyDepartment }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row top-aligned">
                      <div class="col-5">
                        <div class="sub-info-title">{{rfiDetail?.initRespondedDate?'Revise Responded Date':'Responded Date'}}</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail?.respondedDate}}</div>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="rfiDetail?.initRespondedDate">
                    <div class="col-6">
                    
                    </div>
                    <div class="col-6">
                      <div class="row top-aligned">
                        <div class="col-5">
                          <div class="sub-info-title">Initial Responded Date</div>
                        </div>
                        <div class="col-7">
                          <div class="sub-info-text">{{rfiDetail.initRespondedDate}}</div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Sixth Row -->
            <div *ngIf="numberOfPage === 1" class="blackBoxBorder">
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Response</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">{{rfiDetail?.officialResponse || "None"}}</div>
                </div>
              </div>
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Attachments</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">
                    <ng-container *ngIf="resFiles && resFiles.length > 0" >
                      <ng-container *ngFor="let doc of resFiles; let i = index">
                        <div class="col-6" style="padding: 0 0 5px 0">
                          <a [href]="doc.URL" target="_blank"> {{ doc.Name }}</a>
                        </div>
                      </ng-container>
                    </ng-container>
                    <span *ngIf="!resFiles || resFiles.length === 0">None</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- footer -->
          <div class="info" style="position: relative; display: flex; align-items: center; padding-top: 5px;">
            <div>{{footLeft}}</div>
            <div class="mx-auto">{{footMiddle.page1}}</div>
            <div>{{footRight}}</div>
          </div>
        </div>
      </div>

      <div
        class="seperator1 no-print"
        [ngStyle]="{ height: '10pt' }"
      ></div>

      <div *ngIf="numberOfPage !== 1" class="masthead1">
        <div class="report-page-size relative" id="pdfForm2" #pdfForm2>
          <div>
            <!-- Header -->
            <div class="d-flex">
              <div>
                <span class="header-page-no-text"><img src="./../../../../assets/img/product-icons/ddc_logo.svg">
                </span>
              </div>
              <div class="ml-auto nopadding">
                <span class="header-page-no-text">
                  <img src="./../../../../assets/img/product-icons/aecom_hill_av.svg">
                </span>
              </div>
            </div>
            <!-- First Row -->
            <div class="d-flex">
              <div>
                <span class="title-text">
                  RFI Response Form
                </span>
              </div>
              <div class="ml-auto">
                <span class="title-text">
                  <!-- {{rfiDetail.rfiNumber}} -->
                </span>
              </div>
            </div>
            <!-- Second Row -->
            <div class="d-flex" style="margin-bottom: 30px;">
              <div>
                <span class="h-grey-font">
                  {{productmaintitle}} | CONTRACT: {{contractName}}
                </span>
              </div>
              <div class="ml-auto">
                <span class="h-grey-font">
                  FMS ID: {{fmsId}}
                </span>
              </div>
            </div>

            <!-- Fifth Row -->
            <div style="margin-bottom: 18px;">
              <div class="grey-bg">
                <div class="row">
                  <div class="col-6">
                    <div class="row top-aligned">
                      <div class="col-4">
                        <div class="sub-info-title">Responded By</div>
                      </div>
                      <div class="col-8">
                        <div class="sub-info-text">{{rfiDetail?.responder | userNameCompanyDepartment }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="row top-aligned">
                      <div class="col-5">
                        <div class="sub-info-title">Responded Date</div>
                      </div>
                      <div class="col-7">
                        <div class="sub-info-text">{{rfiDetail?.respondedDate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Sixth Row -->
            <div class="blackBoxBorder">
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Response</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">{{rfiDetail?.officialResponse || "None"}}</div>
                </div>
              </div>
              <div class="row top-aligned">
                <div class="col-1">
                  <div class="sub-info-title">Attachments</div>
                </div>
                <div class="col-11">
                  <div class="sub-info-text">
                    <ng-container *ngIf="resFiles && resFiles.length > 0" >
                      <ng-container *ngFor="let doc of resFiles; let i = index">
                        <div class="col-6" style="padding: 0 0 5px 0">
                          <a [href]="doc.URL" target="_blank"> {{ doc.Name }}</a>
                        </div>
                      </ng-container>
                    </ng-container>
                    <span *ngIf="!resFiles || resFiles.length === 0">None</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- footer -->
          <div class="info" style="position: relative; display: flex; align-items: center; padding-top: 5px;">
            <div>{{footLeft}}</div>
            <div class="mx-auto">{{footMiddle.page2}}</div>
            <div>{{footRight}}</div>
          </div>
        </div>
      </div>
      <div
        class="seperator1 no-print"
        [ngStyle]="{ height: '10pt' }"
      ></div>
    </div>
  </div>
</div>
<ng-template #notAvailable>
  <div class="rfi-detail-value-2">N/A</div>
</ng-template>
