import { ITableFilter } from "@aecom/core";

const columnFilters: ITableFilter[] = [
  {
    column: "organization",
    name: "Organization",
    multiselect: false,
  },
  {
    column: "track",
    name: "Track",
    multiselect: false,
  },
];

export default columnFilters;
