/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
import { IRFIRecall } from "../api-generated";
import RFIRecallRequest from "./rfiRecallRequest";
import TimeStamp from "./timeStamp";

export default class RFIRecallBase extends TimeStamp implements IRFIRecall {
  public Guid?: string;

  public Notes: string;

  constructor(recall: RFIRecallRequest) {
    super();

    this.Notes = recall.comments;

  }
}
