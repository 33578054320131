import { FileModel } from "@aecom/core";
import { Component, Input } from "@angular/core";
import RejectHistoryTable from "@models/rejectHistoryTableItem";

@Component({
  selector: "rejectHistoryTable",
  templateUrl: "./rejectHistoryTable.component.html",
  styleUrls: ["./rejectHistoryTable.component.scss"],
})
export class RejectHistoryTableComponent {
  @Input() data: RejectHistoryTable[] = [];
}
