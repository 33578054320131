import { ISelectItem, ModalContainerService } from "@aecom/core";
import { Component, Input } from "@angular/core";
import RFIDelegate from "@models/rfiDelegate";
import RFIRole from "@models/rfiRoles";
import RFIStatus from "@models/rfiStatus";
import LocalContractUserService from "@services/local-contractUser.service";
import { IPRFIItem } from "src/app/api-generated";
import environment from "src/environments/environment";

@Component({
  selector: "app-delegate-modal",
  templateUrl: "./delegate-modal.component.html",
  styleUrls: ["./delegate-modal.component.css"],
})
export class DelegateModalComponent {
  @Input() title = "Reassign Task(s)?";
  @Input() body = "The BIC tasks will be reassigned to the person you select below (the new BIC). Once the reassignment is complete, the former BIC will no longer have the ability to carry out any tasks related to this RFI.";
  @Input() set rfiItem(value: IPRFIItem) {
    this.rfi = new RFIDelegate(value);
    let reassignedRole: string[] = [];
    let bic: string | null = null;
    switch(value.Status)
    {
      case RFIStatus.Distribution:
      case RFIStatus.Review_Period:
        reassignedRole=[RFIRole.Coordinator, RFIRole.Manager];
        bic = value.CoordinatorId;
        break;
      case RFIStatus.PM_Review:
        reassignedRole=[RFIRole.Coordinator, RFIRole.Manager, RFIRole.SR_Manager];
        bic = value.ManagerId;
        break;
      case RFIStatus.SPM_Review:
        reassignedRole=[RFIRole.Manager, RFIRole.SR_Manager];
        bic = value.SrManagerId;
        break;
      case RFIStatus.DDC_Review:
        reassignedRole=[RFIRole.DDC_Manager];
        bic = value.DDCManagerId;
        break;
      default:
        break;
    }
    if(environment.stage === "local")
    {
      reassignedRole.push(RFIRole.Doc_Control);
    }
    if(reassignedRole.length > 0)
    {
      this.userList = this.localContractUserService
      .getUsers(reassignedRole)
      .map((u) => {
        return {
          id: u.UserId,
          name: `${u.UserInfo.displayName ?? ""} (${u.Role})`,
          checked: false,
        };
      }).filter((u)=>{return u.id !== bic}).sort((a, b)=>a.name.localeCompare(b.name));
    }
  }

  rfi: RFIDelegate | null = null;

  userList: ISelectItem[] = [];

  attemptToIssue = false;

  constructor(private activeModal: ModalContainerService, public localContractUserService: LocalContractUserService) {}

  save() {
    this.attemptToIssue = true;
    const isValid = this.rfi.rfi_delegate_detail.UserId && this.rfi.rfi_delegate_detail.UserId!=='';
    if (!isValid) {
      return;
    }
    this.activeModal.close(this.rfi);
  }

  cancel() {
    this.activeModal.close(null);
  }

  setUserSelect(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.rfi.rfi_delegate_detail.UserId = e?.id;
      // this.enableSaveDraft = this.hasChanges();
    }
  }
}
