import { IPRFICopyDocument } from "../api-generated";

export default class RFIDocumentCopy implements IPRFICopyDocument {
  public ItemId: string;

  public ReferenceId: string;

  public Status: string;

  public CopyFrom: string;

  constructor(
    status: string,
    rfiId: string,
    copyFrom: string,
    referenceId?: string,
  ) {
    this.Status = status;
    this.ItemId = rfiId;
    this.ReferenceId = referenceId;
    this.CopyFrom = copyFrom;
  }
}
