import { v4 as uuidv4 } from 'uuid';
import { IPRFIItem, IRFICreateStep } from "../api-generated/model/models";
import RFIUpdateBase from "./rfiUpdateBase";

export default class RFICreateStep
  extends RFIUpdateBase
  implements IRFICreateStep
{
  Title?: string | null;

  Question?: string | null;

  SpecificationId?: string | null;

  ClassificationId?: string | null;

  ContractRequirementId?: string | null;

  PhaseId?: string | null;

  AnticipatedScheduleImpact?: boolean | null;

  AnticipatedCostImpact?: boolean | null;

  ProcessingNotes?: string | null;

  MasterSpecId?: string | null;

  Priority?: number | null;

  PriorityReason?: string | null;

  IsConfidential?: boolean | null;

  ReferenceURL?: string | null;

  ContractorReference?: string | null;

  RFINumber?: string;

  tempId?: string;

  constructor(contractId: string, rfi?: IPRFIItem) {
    super(contractId, rfi);
    if (rfi) {
      this.Title = rfi.Title;
      this.Question = rfi.Question;
      this.SpecificationId = rfi.SpecificationId;
      this.ClassificationId = rfi.ClassificationId;
      this.ContractRequirementId = rfi.ContractRequirementId;
      this.PhaseId = rfi.PhaseId;
      this.AnticipatedScheduleImpact = rfi.AnticipatedScheduleImpact;
      this.AnticipatedCostImpact = rfi.AnticipatedCostImpact;
      this.ProcessingNotes = rfi.ProcessingNotes;
      this.MasterSpecId = rfi.MasterSpecId;
      this.PriorityReason = rfi.PriorityReason;
      this.ReferenceURL = rfi.ReferenceURL;
      this.ContractorReference = rfi.ContractorReference;
    }
    this.Priority = rfi?.Priority ?? 1;
    this.AnticipatedScheduleImpact = rfi?.AnticipatedScheduleImpact ?? false; // default to "false"
    this.AnticipatedCostImpact = rfi?.AnticipatedCostImpact ?? false; // default to "false"

    if (!rfi?.Guid) {
      this.tempId = uuidv4();
    }
  }

  getGuid(): string {
    return this.Guid ?? this.tempId;
  }
}
