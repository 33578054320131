import { ISelectItem } from "@aecom/core";

const reopenSignificance: ISelectItem[] = [
  {
    id: '1',
    name: 'Minor Change',
    checked: false
  },
  {
    id: '2',
    name: 'Major Change',
    checked: false
  }
];

export default reopenSignificance;
