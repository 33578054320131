import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import AuthService from "@auth/auth.service";
import IRemoveUserClick from "@models/IRemoveUserClick";
import FileListType from "@models/fileListType";
import RFIRole from "@models/rfiRoles";
import RFIStatus from "@models/rfiStatus";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import { combineUserInfo, getWorkflowStatusArray } from "@shared/utils";
import { IPRFIDocumentDownload, IPRFIItem, IUser, RfiReviewer, RfiWatcher } from "src/app/api-generated";
import { Router } from "@angular/router";

@Component({
  selector: "leftPanel",
  templateUrl: "./leftPanel.component.html",
})
export default class LeftPanelComponent implements OnInit {
  @Input() entity: IPRFIItem;
  
  @Input() docs: IPRFIDocumentDownload[];

  @Input() defaultTab = "details";

  @Input() allowMovingFiles = false;

  @Input() reviewersResponse: (RfiReviewer & {UserInfo?: IUser})[] = [];

  @Input() watchersResponse: (RfiWatcher & {UserInfo?: IUser})[] = [];

  @Output() moveAttachment = new EventEmitter<FileModel>();

  @Output() openWindow = new EventEmitter<boolean>();

  @Output() removeUser = new EventEmitter<IRemoveUserClick>();

  IsDbAdmin = false;

  showProcessing = false;

  showResponseTab = false;

  fileListType: FileListType = FileListType.View;

  isCompile = false;

  constructor(public authService: AuthService, public localContractUserService: LocalContractUserService, public localUserService: LocalUserService, private router: Router){}
  
  ngOnInit(): void {
    const role = this.localContractUserService.currentUserContractRole;
    const userId = this.authService.user.Id;
    this.fileListType = this.allowMovingFiles ? FileListType.Movable : FileListType.View;
    this.IsDbAdmin = role === RFIRole.DBAdmin;
    this.isCompile = this.entity.Status === RFIStatus.Review_Period && userId === this.entity.CoordinatorId && this.router.url.includes('compilingResponse');

    if(!this.IsDbAdmin)
    {
      this.showProcessing = true;
      const users = this.localUserService.getItems();
      this.reviewersResponse = this.entity.rfi_reviewer.map((item) => {
        const user = users.find((u)=>{return u.id === item.ReviewerId});
        return combineUserInfo<RfiReviewer>(item, user);
      });
      this.watchersResponse = this.entity.rfi_watcher.map((item) => {
        const user = users.find((u)=>{return u.id === item.WatcherId});
        return combineUserInfo<RfiWatcher>(item, user);
      });
      this.showResponseTab = getWorkflowStatusArray(this.entity).includes(RFIStatus.Review_Period);
    }
    
  }

  attachmentOut(item: FileModel): void {
    // console.log(item);
    this.moveAttachment.emit(item);
  }

  openWindowClick(isReviewerWindow: boolean): void {
    this.openWindow.emit(isReviewerWindow);
  }

  removeUserClick(userId: string, isReviewer: boolean): void {
    this.removeUser.emit({userId, isReviewer});
  }
}
