import { IPRFIItem, IRFIDelegate } from "../api-generated";
import RFIDelegateDetail from "./rfiDelegateDetail";
import RFIUpdateBase from "./rfiUpdateBase";


export default class RFIDelegate
  extends RFIUpdateBase
  implements IRFIDelegate
{
  public Guid: string;
  public rfi_delegate_detail: RFIDelegateDetail;

  constructor(item: IPRFIItem) {
    super(item.ContractId, item);
    this.Guid = item.Guid;
    this.rfi_delegate_detail = new RFIDelegateDetail(item);
  }
}
