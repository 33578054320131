enum ColumnType {
  // inputs
  Image = "image",
  Link = "link",
  Route = "route",
  ComboBox = "combobox",
  Text = "text",
  CheckBox = "checkbox",
  Date = "date",
  Number = "number",
  Button = "button",
  Status = "status",
  Hide = "hide",
  UserIcon = "userIcon",
  OrganizationIcon = "organizationIcon",
  BoroughIcon = "boroughIcon",
}

export default ColumnType;
