/* eslint-disable new-cap */
import { FileModel } from "@aecom/core";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { IPRFIDocumentDownload, IPRFIItem } from "src/app/api-generated";
import RFIDetail from "src/app/models/rfiDetail";
import RFIStatus from "src/app/models/rfiStatus";
import LoadingService from "src/app/services/loading.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalUserService from "src/app/services/local-user.service";
import { getAccTimeDate, getDocumentsforFinalResponse } from "src/app/shared/utils";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export default class ReportComponent implements OnInit {
  @Input() rfi: IPRFIItem;

  @Input() rfiDocuments: IPRFIDocumentDownload[];

  @Output() closeWindow = new EventEmitter<File | null>();

  @ViewChild("pdfForm1") pdfForm1: ElementRef;

  @ViewChild("pdfForm2") pdfForm2: ElementRef;

  contractId: string;

  refFiles: FileModel[] = [];

  resFiles: FileModel[] = [];

  rfiDetail: RFIDetail;

  productmaintitle = "BBJ PROGRAM";

  contractName = "";

  fmsId = "";

  numberOfPage: number = 1;

  footLeft: string;

  footMiddle: {
    page1: string;
    page2: string;
  }

  footRight: string;

  rfiReportFileName: string;

  getDocIconClass = ReportComponent.getDocIconClass;

  constructor(
    public activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    private localContractService: LocalContractService,
    private localUserService: LocalUserService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.contractName = this.localContractService.getItem()?.Name ?? "";
    this.contractId = this.rfi.ContractId;
    this.fmsId = this.localContractService.getItem()?.FMSID ?? "";
    const users = this.localUserService.getItems();
    this.rfiReportFileName = `${this.rfi.RFINumber} - Response Form.pdf`
    this.footLeft = this.rfiReportFileName;
    this.footRight = `Created On: ${getAccTimeDate(new Date().toISOString())}`;
    this.numberOfPage = this.getNumberOfPage(this.rfi.Question,this.rfi.OfficialResponse);
    this.footMiddle = {
      page1: `Page 1 of ${this.numberOfPage}`,
      page2: `Page 2 of ${this.numberOfPage}`,
    };    
    this.rfiDetail = new RFIDetail(this.rfi, users);
    this.resFiles = getDocumentsforFinalResponse(this.rfiDocuments)
      .map((item) => {
        return ReportComponent.generateFileModel(item);
      });
    this.refFiles = this.rfiDocuments
      .filter((item) => {
        return item.Status === RFIStatus.Draft;
      })
      .map((item) => {
        return ReportComponent.generateFileModel(item);
      });
    this.loadingService.stop();
  }

  static generateFileModel(item: IPRFIDocumentDownload): FileModel {
    return new FileModel(
      item.Guid,
      item.FileName,
      item.DateCreated,
      item.URL,
      "preloaded",
      0,
      null,
      item.DownloadURL,
    );
  }

  static getDocIconClass(filename: string): string {
    const parts = filename.split(".");
    const ext = parts[parts.length - 1];
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
        return "icon icon-blue icon-document_image icon-lg";
      case "pdf":
        return "icon icon-blue icon-document_pdf icon-lg";
      case "xls":
        return "icon icon-blue icon-document_xls icon-lg";
      case "dwg":
        return "icon icon-blue icon-document_3d icon-xl";
      default:
        return "icon icon-blue icon-document icon-lg";
    }
  }

  download() {
    const form1 = this.pdfForm1;
    const form2 = this.pdfForm2;
    if(form1)
    {
      const pdfElement = form1.nativeElement;
      html2canvas(pdfElement, { scale: 2 }).then((canvas) => {
        const imgWidth = 208;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(contentDataURL, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.addPage();
        if(form2)
        {
          const pdfElement2 = form2.nativeElement;
          html2canvas(pdfElement2, { scale: 2 }).then((canvas2) => {
            const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
            const contentDataURL2 = canvas2.toDataURL("image/png");
            pdf.addImage(contentDataURL2, "PNG", 0, 0, imgWidth, imgHeight2);
            pdf.save(this.rfiReportFileName);
          });
        } else {
          pdf.save(this.rfiReportFileName);
        }
      });
    }
  }

  back() {
    this.closeWindow.emit(null);
  }

  getNumberOfPage(questionText: string, responseText: string) {
    const questionTextWordCount = this.countWords(questionText);
    const responseTextWordCount = this.countWords(responseText);
    return questionTextWordCount + responseTextWordCount <= 2000 ? 1 : 2;
  }

  countWords(text) {
    // Remove leading and trailing whitespaces and split the text into an array of words
    const words = text.trim().split(/\s+/);
    
    // Return the number of words in the array
    return words.length;
  }

}
