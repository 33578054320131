import { ModalContainerService } from "@aecom/core";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-readRecall-modal",
  templateUrl: "./readRecall-modal.component.html",
  styleUrls: ["./readRecall-modal.component.css"],
})
export class ReadRecallModalComponent {
  @Input() title = "";
  @Input() body = "";

  constructor(private activeModal: ModalContainerService) {}

  save() {
    this.activeModal.close(1);
  }
}
