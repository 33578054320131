import { IPDocument, IPRFIDocumentUpload } from "../api-generated";
import RDocument from "./rDocument";

export default class RFIDocumentUpload implements IPRFIDocumentUpload {
  public ItemId?: string;

  public ReferenceId?: string;

  public Status?: string;

  public FileId?: string;

  public Guid?: string;

  public Order?: number;

  public DateCreated?: string;

  public DateUpdated?: string;

  public IsArchived?: boolean;

  public Document: IPDocument;

  constructor(
    name: string,
    userId: string,
    status?: string,
    rfiId?: string,
    referenceId?: string,
  ) {
    this.Status = status;
    this.ItemId = rfiId;
    this.ReferenceId = referenceId;
    this.Document = new RDocument(name, userId);
  }
}
