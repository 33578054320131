import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ApplicationService } from './api/application.service';
import { ApplicationRoleService } from './api/applicationRole.service';
import { BoroughService } from './api/borough.service';
import { ContractService } from './api/contract.service';
import { ContractUserService } from './api/contractUser.service';
import { ContractUserApplicationService } from './api/contractUserApplication.service';
import { CorrespondenceDocumentsService } from './api/correspondenceDocuments.service';
import { CorrespondenceItemService } from './api/correspondenceItem.service';
import { CorrespondenceRefrenceUserService } from './api/correspondenceRefrenceUser.service';
import { CorrespondenceTypeService } from './api/correspondenceType.service';
import { EmailService } from './api/email.service';
import { ExternalLoggingService } from './api/externalLogging.service';
import { GlobalApplicationService } from './api/globalApplication.service';
import { GlobalModuleService } from './api/globalModule.service';
import { HealthChecksService } from './api/healthChecks.service';
import { MasterSpecificationService } from './api/masterSpecification.service';
import { ModuleService } from './api/module.service';
import { NavigationService } from './api/navigation.service';
import { PhaseService } from './api/phase.service';
import { PrintPDFService } from './api/printPDF.service';
import { RFIClassificationService } from './api/rFIClassification.service';
import { RFIContractRequirementService } from './api/rFIContractRequirement.service';
import { RFIDocumentsService } from './api/rFIDocuments.service';
import { RFIItemService } from './api/rFIItem.service';
import { RFIReviewerService } from './api/rFIReviewer.service';
import { RFISpecificationService } from './api/rFISpecification.service';
import { RFIWatcherService } from './api/rFIWatcher.service';
import { ReportService } from './api/report.service';
import { SubmittalService } from './api/submittal.service';
import { SubmittalDocumentTypeService } from './api/submittalDocumentType.service';
import { SubmittalDocumentsService } from './api/submittalDocuments.service';
import { SubmittalPageService } from './api/submittalPage.service';
import { SubmittalReviewerService } from './api/submittalReviewer.service';
import { SubmittalTypeService } from './api/submittalType.service';
import { SubmittalWatcherService } from './api/submittalWatcher.service';
import { TaskService } from './api/task.service';
import { TestOnlyService } from './api/testOnly.service';
import { TransmittalDetailsService } from './api/transmittalDetails.service';
import { TransmittalDocumentTypeService } from './api/transmittalDocumentType.service';
import { TransmittalDocumentsService } from './api/transmittalDocuments.service';
import { TransmittalItemService } from './api/transmittalItem.service';
import { UserService } from './api/user.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
