<section class="signout">
  <div></div>

  <div class="singout-content">
    <i class="icon-signout"></i>

    <h1>Signed out Successful</h1>

    <p>For security reasons we recommend you close all browser windows.</p>

    <aecom-button class="btn btn-solid blue" (click)="login()">Sign in</aecom-button>
  </div>

  <p class="copyright">© NYC BBJ PROGRAM | Designed by AECOM Digital Solutions | Data Privacy</p>
</section>
