/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import { FileModel } from "@aecom/core";
import { Component, Input, OnInit } from "@angular/core";
import LocalUserService from "@services/local-user.service";
import {
  IPRFIDocumentDownload,
  IPRFIItem,
  IUser,
} from "src/app/api-generated";
import FileListType from "src/app/models/fileListType";
import RFIDetail from "src/app/models/rfiDetail";
import RFIStatus from "src/app/models/rfiStatus";

@Component({
  selector: "rfi-detail",
  templateUrl: "./rfi-detail.component.html",
  styleUrls: ["./rfi-detail.component.scss"],
})
export default class RFIDetailComponent implements OnInit {
  @Input() entity: IPRFIItem;

  @Input() docs: IPRFIDocumentDownload[] = [];

  @Input() IsDbAdmin = false;

  rfiDetail: RFIDetail;

  docFiles: FileModel[] = [];

  users: IUser[] = [];

  fileListType: FileListType = FileListType.View;

  getFileIcon = RFIDetailComponent.getFileIcon;

  constructor(public localUserService: LocalUserService){}

  ngOnInit(): void {
    this.users = this.localUserService.getItems();
    this.rfiDetail = new RFIDetail(this.entity, this.users);
    this.docFiles = this.docs
      .filter((item) => {
        return item.Status === RFIStatus.Draft;
      })
      .map((item) => {
        return new FileModel(
          item.Guid,
          item.FileName,
          item.DateCreated,
          item.URL,
          "preloaded",
          0,
          null,
          item.DownloadURL,
        );
      });
    // this.IsDbAdmin = false;
  }

  static getFileIcon(type: string): string {
    return type.includes("image")
      ? " icon icon-image_doc"
      : " icon icon-blank_doc";
  }
}
