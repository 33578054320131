import { Component, Input, OnInit } from "@angular/core";
import RecallHistoryTableItem from "@models/recallHistoryTableItem";
import RejectHistoryTableItem from "@models/rejectHistoryTableItem";
import ReopenHistoryTableItem from "@models/reopenHistoryTableItem";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractUserService from "@services/local-contractUser.service";
import {
  IPRFIDocumentDownload,
  IPRFIItem,
} from "src/app/api-generated";

@Component({
  selector: "rfiLogTab",
  templateUrl: "./rfiLogTab.component.html",
  styleUrls: ["./rfiLogTab.component.scss"],
})
export default class RFILogTabComponent implements OnInit {
  @Input() entity: IPRFIItem;

  @Input() docs: IPRFIDocumentDownload[];

  rejectHistory: RejectHistoryTableItem[] = [];

  recallHistory: RecallHistoryTableItem[] = [];

  reopenHistory: ReopenHistoryTableItem[] = [];

  constructor(public localContractUserService: LocalContractUserService, public localApplicationRoleService: LocalApplicationRoleService) {}

  ngOnInit(): void {
    const users = this.localContractUserService.getItems();
    if (this.entity.rfi_reject_detail && this.entity.rfi_reject_detail.length > 0) {
      const rejects = [
        ...this.entity.rfi_reject_detail
          .sort(
            (a, b) =>
              new Date(a.DateUpdated).getTime() -
              new Date(b.DateUpdated).getTime(),
          )
      ];
      this.rejectHistory = rejects.map((r, i)=>{
        return new RejectHistoryTableItem(r, users, i);
      });
    }
    if (this.entity.rfi_recall?.length > 0 && this.entity.rfi_flow?.length > 0) {
      const flows = [
        ...this.entity.rfi_flow
          .sort(
            (a, b) =>
              new Date(a.DateReceived).getTime() -
              new Date(b.DateReceived).getTime(),
          )
      ];
      const recalls = [
        ...this.entity.rfi_recall
          .sort(
            (a, b) =>
              new Date(a.DateUpdated).getTime() -
              new Date(b.DateUpdated).getTime(),
          )
      ];
      if(this.entity.IsRecalled) {
        recalls.splice(-1);
      }
      this.recallHistory = recalls.map((r, i)=>{
        return new RecallHistoryTableItem(r, users, flows, i);
      });
    }
    if(this.entity.rfi_reopen?.length > 0)
    {
      const reopens = [
        ...this.entity.rfi_reopen
          //.filter((reopen)=>{return reopen.Approved !== null})
          .sort(
            (a, b) =>
              new Date(a.DateRequest).getTime() -
              new Date(b.DateRequest).getTime(),
          )
      ];
      this.reopenHistory = reopens.map((r, i)=>{
        return new ReopenHistoryTableItem(r, this.entity, this.docs, users, this.localApplicationRoleService, i);
      })
    }
  }

}
