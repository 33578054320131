import { ISelectItem } from "@aecom/core";

const OptionsScheduleImpact: ISelectItem[] = [
  {
    id: '1',
    name: 'No',
    checked: false
  },
  {
    id: '2',
    name: 'Yes',
    checked: false
  },
];

export default OptionsScheduleImpact;
