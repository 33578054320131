import { ISelectItem } from "@aecom/core";

const OptionsCostImpact: ISelectItem[] = [
  {
    id: 'no',
    name: 'No',
    checked: false
  },
  {
    id: 'credit',
    name: 'Yes Credit',
    checked: false
  },
  {
    id: 'debit',
    name: 'Yes Debit',
    checked: false
  },
];

export default OptionsCostImpact;
