enum RFIRole {
  DBAdmin = "8a013201-0baf-11ea-917a-027e1b1809fa",
  Doc_Control = "8a013202-0baf-11ea-917a-027e1b1809fa",
  Manager = "8a013203-0baf-11ea-917a-027e1b1809fa",
  Coordinator = "8a013204-0baf-11ea-917a-027e1b1809fa",
  DDC_Manager = "8a013205-0baf-11ea-917a-027e1b1809fa",
  User='8a013206-0baf-11ea-917a-027e1b1809fa',
  SA_User='8a013221-0baf-11ea-917a-027e1b1809fa',
  SR_Manager = '8a013227-0baf-11ea-917a-027e1b1809fa',
  Reviewer = "Reviewer",
  Watcher = "Watcher",
}
export default RFIRole;
