<aecom-modal title="Request to Cancel RFI" class="modal-md" [canClose]="false" theme="light">
  <div class="modal-body">
    <h4>Reason</h4>

    <aecom-form-item>
      <aecom-textbox
        rows="3"
        [theme]="'light'"
        [notEmpty]="true"
        [isMultiLine]="true"
        [ngValue]="result.comments"
        (ngValueChange)="checkInput($event)"
        [validate]="validate"
      ></aecom-textbox>

      <div class="error-message" *ngIf="!isValid && validate && result.comments.length !== 0">
        Field can't be "{{ result.comments }}"
      </div>
    </aecom-form-item>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">Cancel</aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">Submit</aecom-button>
    </div>
  </div>
</aecom-modal>
