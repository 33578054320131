/* eslint-disable security/detect-non-literal-fs-filename */
import {
  ISelectItem,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import _ from "lodash";
import { Observable } from "rxjs";
import {
  IPRFIDocumentDownload,
  IPRFIItem,
  IUser,
} from "src/app/api-generated";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import IContractUserWithUserInfo from "src/app/models/IContractUserWithUserInfo";
import IRFIViewPrepare from "src/app/models/IRFIViewPrepaer";
import LoadingService from "src/app/services/loading.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalContractUserService from "src/app/services/local-contractUser.service";
import LocalUserService from "src/app/services/local-user.service";
import {
  rfiCoordinatorRoleId,
  rfiManagerRoleId,
} from "src/app/shared/staticValue";
import {
  getUsersDropDownByRoleId,
} from "src/app/shared/utils";
import RowItem from "../../selectUser/table-row";
import BaseFunctionService from "@services/baseFunction.service";
import RFIInReviewStep from "@models/rfiInReviewStep";
import RFIStatus from "@models/rfiStatus";
import { RemoveUserArg } from "@shared/selectedUsersTable/selectedUsersTable.component";

@Component({
  selector: "app-inreview-qaqc-rfi",
  templateUrl: "./inreview-qaqc-rfi.component.html",
  styleUrls: ["./inreview-qaqc-rfi.component.scss"],
})
export default class InreviewQaqcRfiComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  enableSaveDraft = false;

  enableSubmit = false;

  oldRFI: RFIInReviewStep;

  rfi: RFIInReviewStep;

  entity: IPRFIItem;

  rfiDocuments: IPRFIDocumentDownload[];

  users: IUser[];

  contractId: string;

  managerList: ISelectItem[] = [];

  coordinatorList: ISelectItem[] = [];

  canIssueRFI = false;

  private contractUsers: IContractUserWithUserInfo[] = [];

  public logtab = true;

  isQaQc = false;

  internalDueDate: Date;

  dueDate: Date;

  public maxInternalDueDate: string | undefined = undefined;

  public minDueDate: string | undefined = undefined;

  defaultTab = "details";

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activeModal: ModalContainerService,
    public localContractService: LocalContractService,
    private localContractUserService: LocalContractUserService,
    private localUserService: LocalUserService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.users = this.localUserService.getItems();
    const data: IRFIViewPrepare = this.activatedRoute.snapshot.data.RFIPrepare;
    this.entity = data.rfi;
    this.isQaQc = this.entity.Status === RFIStatus.Submitted;
    this.contractId = this.localContractService.currentContract.Guid;
    this.rfiDocuments = data.rfiDocuments;
    this.contractUsers = this.localContractUserService.getItems();
    this.baseFunctionService.setReviewerWatcherTableView();
    this.oldRFI = new RFIInReviewStep(this.contractId, this.entity);
    this.internalDueDate = this.oldRFI.InternalDueDate
      ? new Date(
          this.oldRFI.InternalDueDate.replace("00:00:00.000Z", "12:00:00.000Z")
        )
      : new Date();
    this.dueDate = this.oldRFI.DueDate
      ? new Date(this.oldRFI.DueDate.replace("00:00:00.000Z", "12:00:00.000Z"))
      : new Date();
    
    this.managerList = getUsersDropDownByRoleId(
      this.contractUsers,
      rfiManagerRoleId,
    );

    this.coordinatorList = getUsersDropDownByRoleId(
      this.contractUsers,
      rfiCoordinatorRoleId,
    );

    this.rfi = JSON.parse(JSON.stringify(this.oldRFI));
    console.log("rfi", this.rfi);
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldRFI);
    const currentRFI = JSON.stringify(this.rfi);

    return old !== currentRFI;
  }

  IsValid(): boolean {
    if (this.rfi.QAQCCompliance) {
      if(this.isQaQc)
      {
        return (
          !(
            _.isEmpty(this.rfi.ManagerId) ||
            _.isEmpty(this.rfi.CoordinatorId)
          )
        );
      }
      else {
        return !_.isEmpty(this.rfi.InternalDueDate)
      }
    }

    return !_.isEmpty(this.rfi.rfi_reject_detail?.Note) && !this.baseFunctionService.isInputInvalid(this.rfi.rfi_reject_detail.Note);
  }

  submitRFI(): void {
    this.canIssueRFI = true;
    const validation = this.IsValid();

    if (validation) {
      const modalInstance = this.activeModal.open(NotificationComponent);

      modalInstance.instance.theme = "light";
      modalInstance.instance.title = this.rfi.QAQCCompliance
        ? "Confirm Action?"
        : "QA/QC Fail?";
      modalInstance.instance.body = this.rfi.QAQCCompliance
        ? "Once submitted, your task will be completed and you will no longer be able to make updates. The RFI will be distributed and the reviewers and observers will be notified."
        : "The RFI will be sent back to the DB User.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.rfi.IsDraft = false;
          this.update();
        }
      });
    }
  }

  saveDraft(): void {
    this.enableSaveDraft = this.hasChanges();

    if (!this.hasChanges()) return;

    const modalInstance = this.activeModal.open(NotificationComponent);

    modalInstance.instance.theme = "light";
    modalInstance.instance.title = "Save as Draft?";
    modalInstance.instance.body = "Your RFI will save as draft.";

    modalInstance.result.then((result) => {
      if (result === 1) {
        this.rfi.IsDraft = true;
        this.update();
      }
    });
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  update(): void {
    this.loadingService.start();

    // if (!this.rfi.QAQCCompliance) {
    //   this.rfi.ManagerId = null;
    //   this.rfi.CoordinatorId = null;
    //   this.rfi.Reviewers = [];
    //   this.rfi.Watchers = [];
    // } else {
    //   this.rfi.QAQCComplianceReason = null;
    // }

    this.baseFunctionService.update(this.rfi);
  }

  setQaqcCompliance(res: boolean): void {
    this.rfi.QAQCCompliance = res;
    // if (res) {
    //   this.rfi.ManagerId = null;
    // }
    this.enableSaveDraft = this.hasChanges();
  }

  assignManger(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.rfi.ManagerId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  assignCoordinator(e: ISelectItem): void {
    if (e && !Array.isArray(e)) {
      this.rfi.CoordinatorId = e?.id;
      this.enableSaveDraft = this.hasChanges();
    }
  }

  setDate(e: Date): void {
    // console.log("setDate", e);
    this.internalDueDate = new Date(e);
    this.rfi.InternalDueDate = new Date(e).toISOString();
    this.minDueDate = this.internalDueDate.toLocaleDateString();
    this.enableSaveDraft = this.hasChanges();
  }

  setDueDate(e: Date): void {
    // console.log("setDate", e);
    this.dueDate = new Date(e);
    this.rfi.DueDate = new Date(e).toISOString();
    this.maxInternalDueDate = this.dueDate.toLocaleDateString();
    this.enableSaveDraft = this.hasChanges();
  }

  removeUserClick(remove: RemoveUserArg): void {
    this.baseFunctionService.removeUserClick(this.rfi, remove);
    this.enableSaveDraft = this.hasChanges();
  }

  openWindowClick(isReviewerWindow: boolean): void {
    this.baseFunctionService.openWindowClick(isReviewerWindow);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  closeWindow(e?: RowItem[]): void {
    this.baseFunctionService.closeWindow(this.rfi, e);
    this.enableSaveDraft = this.hasChanges();
  }

  reassign(): void {
    this.baseFunctionService.reassign();
  }
}
