export default class LoginUser {
  constructor(
    public FirstName: string,
    public LastName: string,
    public Id: string,
    public Email: string,
    public Organization: string,
    public Permissions: string,
  ) {}
}
