<div class="maincontainer">
  <section class="contentcontainer">
    <div class="row">
      <div class="col-12">
        <div class="page-header">
          <div class="header-title">
            <h1 class="title">RFI List</h1>

            <span>{{ subtitle }}</span>
          </div>
          <div class="header-action" style="margin-left: auto;">
            <aecom-button data-cy="new" innerClass="btn solid lg blue" (click)="download()">Download Log</aecom-button>
          </div>
          <div class="header-action" style="margin-left: 20px;" *ngIf="isDBAdmin || isDoc">
            <aecom-button innerClass="btn solid lg blue" (click)="createRFI()"> Create new RFI </aecom-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row " [ngClass]="{'tablestyle': fixedheadercss}" >
      <div class="col-12">
        <aecom-table
          [ngValue]="tableData"
          (ngValueChange)="tableRows = $event"
          [theme]="'light'"
          innerClass="list rows-lg"
        >
          <div class="filters-section">
            <div class="d-flex">
              <div class="pr-15" style="width: 406px">
                <aecom-textbox
                  [theme]="'light'"
                  [(ngValue)]="searchText"
                  name="table-search"
                  placeholder="Search"
                  iconLeft="icon-basic_magnifier"
                  (keyupEnter)="filterData()"
                ></aecom-textbox>
              </div>
              <div class="pr-15">
                <aecom-dropdown
                  *ngIf="!IsOldContract"
                  [theme]="'light'"
                  [data]="phaseDropdownFilter"
                  [placeholder]="'All Phases'"
                  [(selectedId)]="phaseFilterId"
                  (ngValueChange)="changePhaseFilter($event)"
                ></aecom-dropdown>
              </div>
              
              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="classificationDropdownFilter"
                  [placeholder]="'All Classifications'"
                  [(selectedId)]="classificationFilterId"
                  (ngValueChange)="changeClassificationFilter($event)"
                ></aecom-dropdown>
              </div>
              
              <div class="pr-15">
                <aecom-dropdown
                  [theme]="'light'"
                  [data]="statusDropdownFilter"
                  [placeholder]="'Status'"
                  [(selectedId)]="statusFilterId"
                  (ngValueChange)="changeStatusFilter($event)"
                ></aecom-dropdown>
              </div>
            </div>

            <div class="rfi-status">
              <span></span>
              <div>
                <ng-container *ngFor="let filterBtn of statusFilters; let i = index">
                  <aecom-button
                    [title]="filterBtn.name"
                    innerClass="btn btn-icon clear sm"
                    icon="{{ filterBtn.icon }} {{ filterBtn.active ? '' : 'disabled' }}"
                    (click)="toggleStatus(i)"
                  ></aecom-button>
                </ng-container>
              </div>
            </div>
          </div>

          <thead>
            <tr>
              <th class="sort" id="priority" [ngStyle]="{'width.px':widthPriority}">Priority</th>
              <th class="sort" id="rfiNumber" [ngStyle]="{'width.px':widthId}">ID</th>
              <th class="sort" id="rfiTitle">Title</th>
              <th *ngIf="!IsOldContract" class="sort" id="phase" [ngStyle]="{'width.px':widthPhase}">Phase</th>
              <th class="sort" id="classification">Classification</th>
              <th *ngIf="!IsFacOrTsbContract" class="sort" id="specification">UniFormat Level 2</th>
              <th *ngIf="IsFacOrTsbContract" class="sort" id="contractRequirement">Contract Requirement</th>
              <th class="sort" id="issuedOn" [ngStyle]="{'width.px':widthDate}">Issue Date</th>
              <th *ngIf="!isDBAdmin" class="sort" id="internalDueOn" [ngStyle]="{'width.px':widthDate}">Internal Due</th>
              <th class="sort" id="dueOn" [ngStyle]="{'width.px':widthDate}">Due Date</th>
              <th class="sort" id="reponseDate" [ngStyle]="{'width.px':widthDate}">Response Date</th>
              <!-- <th class="sort" id="ballInCourtInfo.name">Ball in Court</th> -->
              <th class="sort" id="bicSort">Ball in Court</th>
              <th class="sort" id="status">Step</th>
              <th class="no-print" id="response" [ngStyle]="{'width.px':widthAction}">Action</th>
              <th *ngIf="showAltUrl" class="no-print" id="altUrl" style="padding: 0" [ngStyle]="{'width.px':widthAlt}"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let row of tableRows">
              <tr *ngIf="!row.isHidden">
                <td [ngStyle]="{'width.px':widthPriority}">
                  <aecom-button *ngIf="row.amended" innerClass="btn solid sm blue mb4 unchange w100"> Amended </aecom-button>
                  <aecom-button *ngIf="row.priority === 1" innerClass="btn solid sm grey unchange w100"> Low </aecom-button>

                  <aecom-button *ngIf="row.priority === 2" innerClass="btn solid sm orange unchange w100"> Medium </aecom-button>

                  <aecom-button *ngIf="row.priority === 3" innerClass="btn solid sm red unchange w100"> High </aecom-button>
                </td>

                <td [ngStyle]="{'width.px':widthId}">{{ row.rfiNumber }}</td>
                <td>{{ row.rfiTitle }}</td>
                <td *ngIf="!IsOldContract" [ngStyle]="{'width.px':widthPhase}">{{ row.phase }}</td>
                <td>{{ row.classification || '-' }}</td>
                <td *ngIf="!IsFacOrTsbContract">{{ row.specification || '-' }}</td>
                <td *ngIf="IsFacOrTsbContract">{{ row.contractRequirement || '-' }}</td>
                <td [ngStyle]="{'width.px':widthDate}">{{ getFormattedDate(row.issuedOn) }}</td>
                <td [ngStyle]="{'width.px':widthDate}" *ngIf="!isDBAdmin">{{ getFormattedDate(row.internalDueOn) }}</td>
                <td [ngStyle]="{'width.px':widthDate}">{{ getFormattedDate(row.dueOn) }}</td>
                <td [ngStyle]="{'width.px':widthDate}">{{ getFormattedDate(row.reponseDate) }}</td>
                <td class="ball-in-court">
                  <span>{{ row.bicSort }}</span>
                </td>

                <td>
                  <div class="rfi-status">
                    <div>
                      <div style="display: flex; align-items: center">
                        <i class="icon icon-lg" [ngClass]="row.rfiIcon" style="margin-right: 5px"></i>
                        <span style="line-height: 40px">{{ row.statusText }}</span>
                      </div>

                      <div style="padding-bottom: 5px" *ngIf="row.compilingValue != -1 && !isDBAdmin">
                        <mat-progress-bar mode="determinate" [value]="row.compilingValue"></mat-progress-bar>
                      </div>
                      <div
                        *ngIf="isDBAdmin && row.showRecallRejected"
                        style="color: #9b247f"
                      >
                        Recall Rejected
                      </div>
                    </div>
                  </div>

                </td>

                <td class="no-print" [ngStyle]="{'width.px':widthAction}">
                  <aecom-button [innerClass]="row.btnClass" (click)="goTo(row.btnUrl)">
                    {{ row.btnText }}
                  </aecom-button>
                </td>
                <td *ngIf="showAltUrl" class="no-print" style="padding: 0" [ngStyle]="{'width.px':widthAlt}">
                  <i
                    *ngIf="row.altUrl"
                    class="icon-action_more_option_circle icon-blue"
                    style="
                      cursor: pointer;
                      display: block;
                      height: 30px;
                    "
                    (click)="goTo(row.altUrl)"
                  ></i>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </aecom-table>
      </div>
    </div>
  </section>
</div>
