<aecom-form-group
    columnSpan="12"
    columns="1"
    [title]="isReviewer?'REVIEWER(S) - Optional':'OBSERVER(S) - Optional'"
    style="margin-bottom: 15px; position: relative"
  >
    <div style="width: 100%; height: 250px" class="noTableFilter">
      <aecom-table
        class="asignUserTable"
        [(ngValue)]="tableData"
        [theme]="'light'"
        innerClass="list"
      >
        <tbody>
          <tr
            *ngFor="let bRow of tableData"
            class="assignUser_tr_hover"
          >
            <ng-container *ngFor="let hCol of tableHeader">
              <td *ngIf="hCol.Type !== columnType.Hide">
                <span class="assignUser_td_Item">
                  {{ bRow[hCol.Name] }}
                </span>
              </td>
            </ng-container>
            <td>
              <a
                class="btn"
                (click)="removeUser(bRow)"
                ><i
                  class="
                    icon
                    icon-social_user_remove
                    icon-orange icon-lg
                  "
                ></i
              ></a>
            </td>
          </tr>
        </tbody>
      </aecom-table>

      <div
        style="
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        "
        *ngIf="tableData.length === 0"
      >
        <div class="noItem-body">
          <div>
            <div
              class="
                noItem-icon-row
                icon
                icon-social_user_team_big
                icon-grey icon-xl
              "
            ></div>
          </div>

          <div class="noItem-title-row">None</div>
        </div>
      </div>
    </div>

    <div
      style="
        position: absolute;
        top: 7px;
        right: 0;
        cursor: pointer;
      "
    >
      <div
        class="formGroupManu"
        (click)="openWindow()"
      >
        {{isReviewer?'+ Add REVIEWER':'+ Add OBSERVERS'}}
      </div>
    </div>
  </aecom-form-group>