import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import environment from "src/environments/environment";
import AccessDeniedComponent from "./content/auth/access-denied/access-denied.component";
import BaseComponent from "./content/base/base.component";
import ErrorComponent from "./content/error/error.component";
import CompilingRfiComponent from "./content/rfi/compiling-rfi/compiling-rfi.component";
import CreateRfiComponent from "./content/rfi/create-rfi/create-rfi.component";
import InreviewQaqcRfiComponent from "./content/rfi/inreview-qaqc-rfi/inreview-qaqc-rfi.component";
import ReportComponent from "./content/rfi/report/report.component";
import ReviewRfiComponent from "./content/rfi/review-rfi/review-rfi.component";
import RFIListComponent from "./content/rfi/rfi-list/rfi-list.component";
import ViewRFIComponent from "./content/rfi/view-rfi/view-rfi.component";
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import LoginUserResolve from "./resolvers/login-user-resolver";
import RfiListResolver from "./resolvers/rfi-list.resolver";
import RFIViewResolver from "./resolvers/rfi-view.resolver";
import RouteParams from "./shared/route-params";
import WaitForUserGuard from "./guards/wait-for-user-guard";
import { SignoutComponent } from "./content/signout/signout.component";
import ErrorResolve from "./resolvers/error-resolver";

const routes: Routes = [
  {
    path: "unauthorized",
    component: AccessDeniedComponent,
    pathMatch: "full",
  },
  {
    path: `error/:${RouteParams.ErrorCode}`,
    component: ErrorComponent,
    canActivate: [MsalGuard],
    resolve: { loginuser: ErrorResolve },
  },
  {
    path: "sign-out",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: "logout",
    component: SignoutComponent,
    pathMatch: "full",
  },
  {
    path: `:${RouteParams.ContractId}`,
    component: BaseComponent,
    canActivate: [MsalGuard, WaitForUserGuard],
    runGuardsAndResolvers: "always",
    resolve: { loginuser: LoginUserResolve },
    children: [
      {
        path: "list",
        component: RFIListComponent,
        resolve: { RFIList: RfiListResolver },
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: "rfi-list",
        redirectTo: "list",
      },
      {
        path: `create/:${RouteParams.ItemId}`,
        component: CreateRfiComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: `rfi/:${RouteParams.ItemId}`,
        redirectTo: `create/:${RouteParams.ItemId}`,
      },
      {
        path: `view/:${RouteParams.ItemId}`,
        component: ViewRFIComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        runGuardsAndResolvers: "always",
        // canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard, WaitForUserGuard],
        // new path for view only pages
      },
      {
        path: `viewonly/:${RouteParams.ItemId}`,
        redirectTo: `view/:${RouteParams.ItemId}`,
      },
      {
        path: `distribution/:${RouteParams.ItemId}`,
        component: InreviewQaqcRfiComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: `inReviewAssign/:${RouteParams.ItemId}`,
        redirectTo: `distribution/:${RouteParams.ItemId}`,
      },
      {
        path: `inReviewqaqcRFI/:${RouteParams.ItemId}`,
        redirectTo: `distribution/:${RouteParams.ItemId}`,
      },
      {
        path: `reviewRFI/:${RouteParams.ItemId}`,
        component: ReviewRfiComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: `compilingResponse/:${RouteParams.ItemId}`,
        component: CompilingRfiComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        runGuardsAndResolvers: "always",
        canDeactivate: [PendingChangesGuard],
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: `compilingRFI/:${RouteParams.ItemId}`,
        redirectTo: `compilingResponse/:${RouteParams.ItemId}`,
      },
      {
        path: `finalResponseRFI/:${RouteParams.ItemId}`,
        redirectTo: `compilingResponse/:${RouteParams.ItemId}`,
      },
      {
        path: `ddcReviewRFI/:${RouteParams.ItemId}`,
        redirectTo: `compilingResponse/:${RouteParams.ItemId}`,
      },
      {
        path: `report/:${RouteParams.ItemId}`,
        component: ReportComponent,
        resolve: {
          RFIPrepare: RFIViewResolver,
        },
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: `error/:${RouteParams.ErrorCode}`,
        component: ErrorComponent,
        canActivate: [MsalGuard, WaitForUserGuard],
      },
      {
        path: "**",
        redirectTo: "list",
        canDeactivate: [PendingChangesGuard],
        pathMatch: "full",
      },
    ],
  },
  {
    path: "report",
    component: ReportComponent,
    canActivate: [MsalGuard, WaitForUserGuard],
    resolve: { loginuser: LoginUserResolve },
  },
  {
    path: "**",
    redirectTo:
      environment.url.logoutURL.includes("localhost") ||
      environment.url.logoutURL.includes("http://127.0.0.1")
        ? "1ead764b-793c-4751-ba19-bd23622594df/list"
        : environment.url.portal,
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export default class AppRoutingModule {}
