import { ISelectItem } from "@aecom/core";

const reopenChangeType: ISelectItem[] = [
  {
    id: '1',
    name: 'Update comment/response',
    checked: false
  },
  {
    id: '2',
    name: 'Add comment/response',
    checked: false
  },
  {
    id: '3',
    name: 'Replace attachment',
    checked: false
  },
  {
    id: '4',
    name: 'Add attachment',
    checked: false
  },
  {
    id: 'other',
    name: 'Other',
    checked: false
  },
];

export default reopenChangeType;
