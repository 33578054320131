import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import FileListType from "src/app/models/fileListType";
import AzureBlobService from "src/app/services/azureBlob.service";
import environment from "src/environments/environment";

@Component({
  selector: "file-list",
  templateUrl: "./fileList.component.html",
  styleUrls: ["./fileList.component.scss"],
})
export default class FileListComponent {
  @Input() fileList: FileModel[];

  @Input() title?: string;

  @Input() listType: FileListType;

  @Output() fileListChange = new EventEmitter<FileModel[]>();

  @Output() movedFile = new EventEmitter<FileModel>();

  fileListType = FileListType;

  download = FileListComponent.download;

  imageExtentions = [".bmp", ".png", ".jpg", ".jpeg", ".gif", ".tiff", ".svg"];

  constructor(private router: Router) {}

  getFileIcon(filename: string): string {
    // console.log(this.fileList);

    const name = filename.toLowerCase();
    return this.imageExtentions.some((a) => name.includes(a))
      ? "icon icon-image_doc"
      : "icon icon-blank_doc";
  }

  removeFile(i: number): void {
    this.fileList.splice(i, 1);
    this.fileListChange.emit(this.fileList);
  }

  moveFile(item: FileModel): void {
    // console.log(item);
    this.movedFile.emit(item);
  }

  static async download(item: FileModel): Promise<void> {
    if (environment.fileService === "azure") {
      const body = await AzureBlobService.downloadBlobToFile(item);
      const blob = await body.blobBody;
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = item.Name;
      link.click();
    } else {
      window.open(item.DownloadURL, "_blank");
    }
  }

  async downloadAll(): Promise<void> {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    if (environment.fileService === "azure") {
      const bodys = await Promise.all(
        this.fileList.map((item) => {
          return AzureBlobService.downloadBlobToFile(item);
        }),
      );
      const blobs = await Promise.all(
        bodys.map((body) => {
          return body.blobBody;
        }),
      );
      blobs.forEach((item, i) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(item);
        link.download = this.fileList[i].Name;
        link.click();
      });
    } else {
      this.fileList.forEach((item) => {
        window.open(item.DownloadURL, "_blank");
      });
    }
  }
}
