import { FileModel } from "@aecom/core";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import AuthService from "@auth/auth.service";
import IWorkflowHistoryItem from "@models/IWorkflowHistoryItem";
import FileListType from "@models/fileListType";
import NotesListItem from "@models/notesListItem";
import RFIRole from "@models/rfiRoles";
import RFIStatus from "@models/rfiStatus";
import LocalApplicationRoleService from "@services/local-application_role.service";
import LocalContractService from "@services/local-contract.service";
import LocalContractUserService from "@services/local-contractUser.service";
import LocalUserService from "@services/local-user.service";
import { getAccTimeDate, getBusinessDatesCount, getCostImpact, getFixTimeDate, getScheduleImpace, getStatusBeforeReopen, isFinalStep, shouldShowCoordinator, shouldShowDDCManager, shouldShowDocControl, shouldShowManager, shouldShowSrManager } from "@shared/utils";
import {
  IPRFIDocumentDownload,
  IPRFIItem,
  IUser,
} from "src/app/api-generated";

@Component({
  selector: "rfiProcessingTab",
  templateUrl: "./rfiProcessingTab.component.html",
  styleUrls: ["./rfiProcessingTab.component.scss"],
})
export default class RFIProcessingTabComponent implements OnInit {
  @Input() entity: IPRFIItem;
  @Input() docs: IPRFIDocumentDownload[];
  @Input() allowMovingFiles = false;

  @Output() moveAttachment = new EventEmitter<FileModel>();

  items: NotesListItem[] = [];

  isLoaded = false;

  fileListType: FileListType = FileListType.View;

  manager: IUser;
  srManager: IUser;
  docControlUser: IUser;
  coordinator: IUser;
  ddcManager: IUser;

  showManager: boolean;
  showSrManager: boolean;
  showDocControl: boolean;
  showCoordinator: boolean;
  showDDCManager: boolean;

  scheduleImpace: string | undefined;
  costImpace: string | undefined;

  keywords: string[] = [];

  workflowHistorys: IWorkflowHistoryItem[] = [];

  showWorkflow: boolean;
  
  constructor(
    public authService: AuthService,
    public localUserService: LocalUserService,
    public localContractUserService: LocalContractUserService,
    public localApplicationRoleService: LocalApplicationRoleService,
    public localContractService: LocalContractService,
  ) {}

  ngOnInit(): void {
    const users = this.localUserService.getItems();
    const currentUser = this.authService.user.Id;
    this.fileListType = this.allowMovingFiles ? FileListType.Movable : FileListType.View;
    const userRole =
      this.localContractUserService.getUserById(currentUser)?.RolesId || "User";
    this.showWorkflow = userRole !== RFIRole.DBAdmin;
    this.docControlUser = users.find((u)=>{return u.id === this.entity.DocControlId});
    this.manager = users.find((u)=>{return u.id === this.entity.ManagerId});
    this.srManager = users.find((u)=>{return u.id === this.entity.SrManagerId});
    this.coordinator = users.find((u)=>{return u.id === this.entity.CoordinatorId});
    this.ddcManager = users.find((u)=>{return u.id === this.entity.DDCManagerId});
    this.showManager = shouldShowManager(this.entity);
    this.showSrManager = shouldShowSrManager(this.entity);
    this.showCoordinator = shouldShowCoordinator(this.entity);
    this.showDDCManager = shouldShowDDCManager(this.entity);
    this.showDocControl = shouldShowDocControl(this.entity);
    
    if (
      this.entity.rfi_compiled_response?.length > 0
    ) {
      const compile = this.entity.rfi_compiled_response
        .filter((item) => {
          return !item.IsDraft && item.DeletedBy === null;
        });
      const NotesItems = compile.map((note)=>{
        return new NotesListItem(note, this.localContractUserService, this.localApplicationRoleService, this.docs);
      });
      this.items = NotesItems.sort((a,b)=>b.Sort-a.Sort);
      if(this.items.length > 0)
      {
        this.items[0].Collapse = false;
      }
      const isResponded = getStatusBeforeReopen(this.entity) === RFIStatus.Responded;
      if(isResponded)
      {
        const finalCompile = this.entity.rfi_compiled_response
          .find((item) => {
            return !item.IsDraft && item.DeletedBy !== null && item.To === RFIStatus.Responded;
          });
          if(finalCompile)
          {
            this.scheduleImpace = getScheduleImpace(finalCompile);
            this.costImpace = getCostImpact(finalCompile);
          }
        
      }
    }

    if(this.entity.rfi_flow?.length > 0)
    {
      let flow = this.entity.rfi_flow.filter((f)=>{return f.Status !== RFIStatus.Draft}).sort((a, b)=>{
        const timestampA = new Date(a.DateReceived).getTime();
        const timestampB = new Date(b.DateReceived).getTime();
        if(timestampA === timestampB)
        {
          console.log(a.Status,b.Status);
          return b.Status === RFIStatus.Request_to_Reopen ? 1 : -1;
        } else {
          return timestampA - timestampB;
        }
      });

      if(flow.length > 0)
      {
        console.log(flow);
        this.workflowHistorys = flow.map((f, i, a)=>{
          const sequence = i + 1;
          const step = f.Status;
          const timeSubmitted = getAccTimeDate(f.DateReceived);
          const submittedBy = this.localContractUserService.getUserById(f.SubmittedBy)?.UserInfo?.displayName;
          let bicUser = this.localContractUserService.getUserById(f.BICUserId)?.UserInfo?.displayName;
          let roleName = this.localApplicationRoleService.getRoleById(f.BIC);
          let daysInBic = "-";
          let actionDate: string | null = null;
          if(isFinalStep(f.Status))
          {
            daysInBic = "N/A";
          }
          else {
            if(i !== a.length - 1)
            {
              const actionStep = a[i+1];
              actionDate = actionStep.DateReceived;
            }
            daysInBic = getBusinessDatesCount(f.DateReceived, actionDate??new Date().toLocaleDateString()).toString();
          }
          const bic = bicUser? `${bicUser} (${roleName})` : roleName;

          return {
            sequence,
            step,
            timeSubmitted,
            submittedBy,
            bic,
            daysInBic,
          }
        });
      }
    }
    this.isLoaded = true;
  }

  getFixedDateTime(a: string): string {
    return getFixTimeDate(a);
  }

  attachmentOut(item: FileModel): void {
    // console.log(item);
    this.moveAttachment.emit(item);
  }
}

