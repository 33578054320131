<aecom-modal
  [title]="title"
  class="modal-md"
  [canClose]="false"
  theme="light"
>
  <div class="modal-body">
    <h4>{{body}}</h4>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="blue lg solid" (click)="save()">
        OK
      </aecom-button>
    </div>
  </div>
</aecom-modal>
