import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/app/api-generated/model/iUser';


@Pipe({
  name: 'userNameCompanyDepartment'
})
export class UserNameCompanyDepartmentPipe implements PipeTransform {
  transform(user: IUser): string {
    return user ? `${user.displayName ?? ''}, ${user.companyName ?? ''}/${user.department ?? ''}` : null;
  }
}
