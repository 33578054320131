import { IPRFIItem, IRFICompile, RfiCompiledResponse } from "../api-generated";
import TimeStampWithDraft from "./timeStampWithDraft";

export default class RFICompilingBase
  extends TimeStampWithDraft
  implements IRFICompile
{
  public Guid?: string;

  public Status?: string;

  public BIC?: string;

  public To?: string;

  public ScheduleImpact?: boolean | null;

  public CostImpact?: string | null;

  public Response?: string;

  constructor(
    rfi: IPRFIItem,
    item?: RfiCompiledResponse,
  ) {
    super(item);
    this.Guid = (item?.IsDraft === true && item?.Guid) ? item.Guid : undefined;

    this.BIC = item?.BIC ?? rfi.BallInCourt;

    this.Status = item?.Status ?? rfi.Status;

    this.To = item?.To;

    this.ScheduleImpact = item?.ScheduleImpact === true;

    this.CostImpact = item?.CostImpact ?? 'no';

    this.Response = item?.Response ?? '';
  }
}
