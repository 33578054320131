import { IPRFIItem, IRFIRecallStep } from "../api-generated";
import RFIRecallBase from "./rfiRecallBase";
import RFIRecallRequest from "./rfiRecallRequest";
import RFIUpdateBase from "./rfiUpdateBase";


export default class RFIRecallStep
  extends RFIUpdateBase
  implements IRFIRecallStep
{
  public rfi_recall: RFIRecallBase;

  public IsRecalled: boolean;

  constructor(contractId: string, recall: RFIRecallRequest, item?: IPRFIItem) {
    super(contractId, item);

    this.rfi_recall = new RFIRecallBase(recall);

    this.IsRecalled = true;
  }
}
