<div class="row" style="margin: 20px -15px;">
  <div class="col-12">
    <aecom-form columns="12">
      <aecom-form-group
        columnSpan="12"
        columns="1"
        title="Recall History"
      >
        <div columnSpan="1" class="timelineTable" *ngIf="data && data.length > 0; else noneData">
          <div class="row">
            <div class="col-1 th" style="text-align: left; position: relative; left: 40px;">#</div>
            <div class="col-3 th">Status</div>
            <div class="col-2 th">Requested By</div>
            <div class="col-2 th">Requested On</div>
            <div class="col-2 th">Reviewed By</div>
            <div class="col-2 th">Reviewed On</div>
          </div>
          <ng-container *ngFor="let row of data">
            <div class="row tr">
              <div class="col-1 td">
                <div (click)="row.Collapse = !row.Collapse"style="cursor: pointer; margin-left: 10px">
                  <i [ngClass]="row.Collapse ? 'icon-action_sort_desc' : 'icon-action_sort_asc offsetTop'" class="icon icon-lg" style="background-color: #c4c4c4"></i>
                </div>
                <div class="circle">
                  <div style="margin: 0 auto">{{ row.Sequence }}</div>
                </div>
              </div>
              <div class="col-3 td"><div class="hiddenText">{{ row.Status }}</div></div>
              <div class="col-2 td"><div class="hiddenText">{{ row.RequestedBy }}</div></div>
              <div class="col-2 td"><div class="hiddenText">{{ row.RequestedOn }}</div></div>
              <div class="col-2 td"><div class="hiddenText">{{ row.ReviewedBy }}</div></div>
              <div class="col-2 td"><div class="hiddenText">{{ row.ReviewedOn }}</div></div>
              <div class="col-1 td" *ngIf="!row.Collapse"></div>
              <div class="col-11 td" *ngIf="!row.Collapse">
                <div class="row" style="padding-bottom: 30px">
                  <div class="col-12 innerTh">Note from DB</div>
                  <div class="col-12" [innerHtml]="row.Note" style="line-height: 1.4;"></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #noneData>
          <div style="width: 100%;height: 100%;display: flex;align-items: center;">
            <div class="noItem-body">
              <div>
                <div class="noItem-icon-row icon icon-social_user_team_big icon-grey icon-xl"></div>
              </div>
              <div class="noItem-title-row">None</div>
            </div>
          </div>
        </ng-template>
      </aecom-form-group>
    </aecom-form>
  </div>
</div>