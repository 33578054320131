import { IMenuItem } from "@aecom/core";

// global module table
export const bbjModuleId = "f595fd2c-e029-11eb-a7b4-ace2d34b68ef";
export const ahjvModuleId = "f595f5f4-e029-11eb-a7b4-ace2d34b68ef";
export const srfpModuleId = "f5960487-e029-11eb-a7b4-ace2d34b68ef";
export const onboardingModuleId = "f5960107-e029-11eb-a7b4-ace2d34b68ef";
export const dashboardModuleId = "f595ff7c-e029-11eb-a7b4-ace2d34b68ef";

// They are the same as settings
export const settingProgramAdminModuleId =
  "f5960485-e029-11eb-a7b4-ace2d34b68ef";
export const settingContarctAdminModuleId =
  "f5960485-e029-11eb-a7b4-ace2d34b68ef";

// module table
export const docModuleId = "9c012357-0baf-11ea-917a-027e1b1809fa";
export const settingModuleId = "8c0123b4-0baf-11ea-917a-027e1b1809fa";

// global application table
export const costAppId = "8a013205-0baf-11ea-917a-027e1b1809bd";
export const settingAppId = "8a011294-0baf-11ea-917a-027e1b1809fa";

// application table
export const rfiAppId = "8a011299-0baf-11ea-917a-027e1b1809fa";
export const submittalAppId = "8a011101-0baf-11ea-917a-027e1b1809fa";
export const correspondenceAppId = "8a011297-0baf-11ea-917a-027e1b1809fa";

// active directory role
// export const globalAdmin = 'PW-ALL';
export const globalAdmin = "BBJ-Program_Admin";
export const contract_DDC_Admin = "DDC_Admin";
export const contract_AHJV_Admin = "AHJV_Admin";

/* old one */
export const coordinatorRole = "PMC Coordinator";
export const dbRole = "DB Admin";
export const rfiCoordinatorRoleId = "8a013204-0baf-11ea-917a-027e1b1809fa";
export const rfiDDCRepresentativeRoleId = "8a013205-0baf-11ea-917a-027e1b1809fa";
export const rfiDbRoleId = "8a013201-0baf-11ea-917a-027e1b1809fa";
export const rfiDocRoleId = "8a013202-0baf-11ea-917a-027e1b1809fa";
export const rfiInReviewStatus = "In Review";
export const queensGarage = "037c7f4d-0562-46b1-ac09-d4c35c0d9705";
export const rfiManagerRoleId = "8a013203-0baf-11ea-917a-027e1b1809fa";

export const productName = "RFI";

export const productSubtitle = "";

export const productMaintitle = "NYC BBJ PROGRAM";

export const appitems: IMenuItem[] = [
  {
    name: "Support",
    icon: "icon-action_app_support",
    id: "support",
    externalurl:
      "https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
  },
  {
    name: "Onboarding",
    icon: "bbj_onboarding_icon",
    id: "onboarding",
    externalurl:
      "https://apps.powerapps.com/play/e7e45d8e-50c2-4d1b-bafb-dd3cdafd5b66?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a",
  },
];

export const UniformatSpecType = "Uniformat";
export const MasterSpecType = "Master";
export const DesignClassificationId = "e549a260-e02a-11eb-a7b4-ace2d34b68ef";
export const PerformanceContractRequirementId = "b6b35939-da44-4be1-80ec-f80e0f7b3f53";

export const assignReviewersTitle = "Assign Reviewers";

export const assignWatchersTitle = "Assign Observers";