import { Injectable } from "@angular/core";
import IContractUserWithUserInfo from "@models/IContractUserWithUserInfo";
import { rfiAppId } from "@shared/staticValue";
import { BehaviorSubject, Observable } from "rxjs";
import BaseService from "./base.service";

@Injectable({
  providedIn: "root",
})
export default class LocalContractUserService extends BaseService<IContractUserWithUserInfo> {
  public currentUserContractRole: string;

  public defaultDoc: string | null;

  protected contractUsersSubject = new BehaviorSubject<
    IContractUserWithUserInfo[]
  >(null);

  contractUsers$: Observable<IContractUserWithUserInfo[]> =
    this.contractUsersSubject.asObservable();

  getItems(): IContractUserWithUserInfo[] {
    return this.contractUsersSubject.getValue();
  }

  public setItems(item: IContractUserWithUserInfo[]): void {
    const users = item.filter((user)=>{
      return user.contract_user_application_role?.find((ar)=>{return ar.ApplicationId === rfiAppId})
    }).map((user)=>{
      const cuar = user.contract_user_application_role.find((ar)=>{return ar.ApplicationId === rfiAppId});
      const id = cuar?.ApplicationRoleId;
      const role = cuar?.application_role.Role;
      return {...user, RolesId: id, Role: role }
    })
    this.contractUsersSubject.next(users);
  }

  public getUsers(
    userTypes: string[] = [],
    excludeUserTypes: string[] = [],
  ): IContractUserWithUserInfo[] {
    return this.contractUsersSubject
      .getValue()
      .filter((user: IContractUserWithUserInfo) => {
        return !excludeUserTypes.find((role) => {
          return role === user.RolesId;
        }) && (userTypes.find((role) => {
          return role === user.RolesId;
        }) || userTypes.length === 0);
      });
  }

  public getUserById(userId: string): IContractUserWithUserInfo | undefined {
    return this.getUsers().find((user: IContractUserWithUserInfo) => {
      return user.UserId == userId;
    });
  }
}
