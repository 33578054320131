import { IRFIRecall, RfiRecall } from "../api-generated";
import TimeStamp from "./timeStamp";

export default class RFIRecallItem extends TimeStamp implements IRFIRecall {
  public Guid: string;

  public Notes: string;

  constructor(recall: RfiRecall) {
    super(recall);

    this.Guid = recall.Guid;

    this.Notes = recall.Notes;
  }
}
