/* eslint-disable prettier/prettier */
/* eslint-disable security/detect-object-injection */
/* eslint-disable security/detect-non-literal-fs-filename */
import {
  FileModel,
  ModalContainerService,
  NotificationComponent,
} from "@aecom/core";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import BaseFunctionService from "@services/baseFunction.service";
import _ from "lodash";
import { Observable } from "rxjs";
import { IPRFIDocumentDownload, IPRFIListItem, IUser } from "src/app/api-generated";
import { ComponentCanDeactivate } from "src/app/guards/pending-changes.guard";
import IRFIViewPrepare from "src/app/models/IRFIViewPrepaer";
import RFIReview from "src/app/models/rfiReview";
import RFIStatus from "src/app/models/rfiStatus";
import LoadingService from "src/app/services/loading.service";
import LocalContractService from "src/app/services/local-contract.service";
import LocalUserService from "src/app/services/local-user.service";
import RouteParams from "src/app/shared/route-params";
import AuthService from "../../auth/auth.service";

@Component({
  selector: "app-review-rfi",
  templateUrl: "./review-rfi.component.html",
  styleUrls: ["./review-rfi.component.scss"],
})
export default class ReviewRfiComponent
  implements OnInit, ComponentCanDeactivate
{
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    return !this.hasChanges();
  }

  enableSaveDraft = false;

  canIssueRFI = false;

  oldItem: RFIReview;

  newItem: RFIReview;

  entity: IPRFIListItem;

  contractId: string;

  rfiDocuments: IPRFIDocumentDownload[];

  users: IUser[];

  currentUser: string;

  defaultTab = "details";

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    public activemodal: ModalContainerService,
    public localContractService: LocalContractService,
    private localUserService: LocalUserService,
    private authService: AuthService,
    public baseFunctionService: BaseFunctionService,
  ) {}

  ngOnInit(): void {
    this.loadingService.start();
    this.users = this.localUserService.getItems();
    this.currentUser = this.authService.user.Id;
    const data: IRFIViewPrepare = this.activatedRoute.snapshot.data.RFIPrepare;
    this.entity = data.rfi;
    this.contractId = this.activatedRoute.snapshot.parent.params[RouteParams.ContractId];
    this.rfiDocuments = data.rfiDocuments;
    this.users = this.localUserService.getItems();
    this.defaultTab = this.entity.rfi_reviewer?.length > 0
      ? "responses"
      : "details";
    const myReviewer = this.entity.rfi_reviewer.find((r)=>{return r.ReviewerId === this.currentUser});
    this.oldItem = new RFIReview(myReviewer);
    const documnetsForThis = this.baseFunctionService.getFileListForCurrentStep(RFIStatus.Review_Period, this.oldItem.Guid);

    this.baseFunctionService.setFileList(documnetsForThis);
    this.baseFunctionService.setRefId(this.oldItem.Guid);
    this.newItem = JSON.parse(JSON.stringify(this.oldItem));
    this.loadingService.stop();
  }

  hasChanges(): boolean {
    const old = JSON.stringify(this.oldItem);
    const currentRFI = JSON.stringify(this.newItem);
    // true means no changes
    return old !== currentRFI || this.baseFunctionService.areFilesChanged();
  }

  setResponse(e: string): void {
    this.newItem.Response = e;
    this.enableSaveDraft = this.hasChanges();
  }

  setHasComments(e: boolean): void {
    this.newItem.hasComments = e;
    this.enableSaveDraft = this.hasChanges();
  }

  IsValid(): boolean {
    if (this.newItem.hasComments &&
      (!this.newItem.Response ||
      this.newItem.Response.trim() === "" ||
      this.baseFunctionService.isInputInvalid(this.newItem.Response) ||
      !this.baseFunctionService.isFileNameValid())
    ) {
      return false;
    }
    return true;
  }

  submitRFI(): void {
    this.canIssueRFI = true;
    // console.log("RFI", this.newItem);

    const validation = this.IsValid();
    // console.log(validation);
    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else if (validation) {
      const modalInstance = this.activemodal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Submit RFI Response?";
      modalInstance.instance.body =
        "Your RFI response will be sent to the Coordinator.";

      modalInstance.result.then((result) => {
        if (result === 1) {
          this.newItem.IsDraft = false;
          this.baseFunctionService.reviewerSubmit(this.newItem);
        }
      });
    }
  }

  saveDraft(): void {
    if (!this.hasChanges() || !this.baseFunctionService.isFileNameValid()) return;
    
    if (this.baseFunctionService.hasFilesUploading()) {
      this.baseFunctionService.showFileProgressNotification();
    } else {
      const modalInstance = this.activemodal.open(NotificationComponent);
      modalInstance.instance.theme = "light";
      modalInstance.instance.title = "Save as Draft?";
      modalInstance.instance.body = "Your RFI will save as draft.";
      modalInstance.result.then((result) => {
        if (result === 1) {
          this.newItem.IsDraft = true;
          this.baseFunctionService.reviewerSubmit(this.newItem);
        }
      });
    }
  }

  importFile(e: FileModel[]): void {
    this.baseFunctionService.importFile(e);
    this.enableSaveDraft = this.hasChanges();
  }

  deleteFile(e: FileModel): void {
    this.baseFunctionService.deleteFile(e);
    this.enableSaveDraft = this.hasChanges();
  }

  back(): void {
    if (this.hasChanges()) {
      this.baseFunctionService.backAfterConfirm();
    } else {
      this.baseFunctionService.back();
    }
  }

  addFileToAttachment(prevItem: FileModel): void {
    this.baseFunctionService.addFileToAttachment(prevItem);
    this.enableSaveDraft = this.hasChanges();
  }

}
