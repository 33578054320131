<ng-container>
  <rfi-header [entity]="entity" [IsDbAdmin]="IsDbAdmin"></rfi-header>

  <aecom-tabs class="cusTab" [defaultTab]="defaultTab">
    <aecom-tab title="details">
      <div style="overflow-y: auto; overflow-x: hidden">
        <rfi-detail
          [entity]="entity"
          [docs]="docs"
          [IsDbAdmin]="IsDbAdmin"
        ></rfi-detail>
      </div>
    </aecom-tab>
    <aecom-tab title="processing" *ngIf="showProcessing">
      <div style="overflow-y: auto; overflow-x: hidden">
        <rfiProcessingTab
          [entity]="entity"
          [docs]="docs"
          [allowMovingFiles]="allowMovingFiles"
          (moveAttachment)="attachmentOut($event)"
        ></rfiProcessingTab>
      </div>
    </aecom-tab>
    <aecom-tab title="responses" *ngIf="showResponseTab">
      <div style="overflow-y: auto; overflow-x: hidden">
        <rfiResponse-detail
          [isReviewer]="true"
          [showAddUserIcon]="isCompile"
          [turnOnNotifyUser]="isCompile"
          [rfiReviews]="reviewersResponse"
          [docs]="docs"
          [fileListType]="fileListType"
          (openReviewerWindow)="openWindowClick($event)"
          (removeUser)="removeUserClick($event, true)"
          (movedAttachment)="attachmentOut($event)"
        ></rfiResponse-detail>

        <rfiResponse-detail
          [isReviewer]="false"
          [showAddUserIcon]="isCompile"
          [turnOnNotifyUser]="isCompile"
          [rfiReviews]="watchersResponse"
          (openReviewerWindow)="openWindowClick($event)"
          (removeUser)="removeUserClick($event, false)"
        ></rfiResponse-detail>
      </div>
    </aecom-tab>
    <aecom-tab title="log">
      <div style="overflow-y: auto; overflow-x: hidden">
        <rfiLogTab
          [entity]="entity"
          [docs]="docs"
        ></rfiLogTab>
      </div>
    </aecom-tab>
  </aecom-tabs>
</ng-container>