import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export default class BaseService<T> {
  protected itemsSubject = new BehaviorSubject<T[]>(null);

  items$: Observable<T[]> = this.itemsSubject.asObservable();

  public getItems(): T[] {
    return this.itemsSubject.getValue();
  }

  public setItems(items: T[]): void {
    this.itemsSubject.next(items);
  }

  protected itemSubject = new BehaviorSubject<T>(null);

  item$: Observable<T> = this.itemSubject.asObservable();

  public getItem(): T {
    return this.itemSubject.getValue();
  }

  public setItem(item: T): void {
    this.itemSubject.next(item);
  }
}
