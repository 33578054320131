import { prepareMutiLineText } from "@shared/utils";
import { RfiRejectDetail } from "../api-generated";
import IContractUserWithUserInfo from "./IContractUserWithUserInfo";
import { FileModel } from "@aecom/core";
import { rfiAppId } from "@shared/staticValue";
export default class RejectHistoryTableItem
{
  public Sequence: number;
  public CoverSheet: FileModel[];
  public RejectedBy: string;
  public RejectedDate: string;
  public Note: string;
  public Collapse: boolean;
  
  constructor(item: RfiRejectDetail, users: IContractUserWithUserInfo[], index: number) {
    this.Sequence = index + 1;

    const rejectedBy = users.find((u)=>{return u.UserId === item.UpdatedBy});
    if(rejectedBy)
    {
      const role = rejectedBy.contract_user_application_role.find((cr)=>{return cr.ApplicationId === rfiAppId});
      this.RejectedBy = `${rejectedBy?.UserInfo?.displayName ?? ""} (${role?.application_role?.Role ?? ""})`;
    }
    else {
      this.RejectedBy = "N/A";
    }
    this.RejectedDate = item.DateUpdated ? this.getFormatedDateAndTime(item.DateUpdated) : "N/A";
    this.Note = item.Note ? prepareMutiLineText(item.Note) : "N/A";
    this.Collapse = true;
  }

  private getFormatedDateAndTime(date: string): string {
    return `${new Date(date)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      })
      .replace(", ", "/")
      .replace(" ", "/")} ${new Date(date).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit"
    })}`;
  }
}
