<div *ngIf="data" class="grid-container noTableFilter timelineTable">
  <table class="timelineTable">
    <thead>
      <tr>
        <th id="sequence">#</th>
        <th id="status">Step</th>
        <th id="bic">Time Submitted</th>
        <th id="submittedBy">Submitted By</th>
        <th id="submitted">Ball in Court (BIC)</th>
        <th *ngIf="showDays" id="days">Days in BIC</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let row of data">
        <tr>
          <td>
            <div class="circle">
              <div style="margin: 0 auto">{{ row.sequence }}</div>
            </div>
          </td>
          <td>{{ row.step }}</td>
          <td>{{ row.timeSubmitted }}</td>
          <td>{{ row.submittedBy }}</td>
          <td>{{ row.bic }}</td>
          <td *ngIf="showDays">{{ row.daysInBic }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
