/* eslint-disable import/no-extraneous-dependencies */
import { FileModel, UploadStatus } from "@aecom/core";
import {
  BlockBlobClient,
  BlobDownloadResponseParsed,
} from "@azure/storage-blob";
import { Injectable } from "@angular/core";
import mime from "mime";
import { RFIDocumentsService } from "../api-generated";

@Injectable({
  providedIn: "root",
})
export default class AzureBlobService {

  constructor(public rfiDocumentsService: RFIDocumentsService) {}

  static async uploadFile(url: string, file: FileModel): Promise<void> {
    try {
      const blockBlobClient = new BlockBlobClient(url);
      const type = mime.getType(file.Name) || "application/octet-stream";
      await blockBlobClient.uploadData(file.File, {
        blobHTTPHeaders: { blobContentType: type },
        blockSize: 104857600,
        onProgress: (ev) => {
          file.Percentage = ev.loadedBytes / file.File.size;
          file.Percentage = Math.floor(file.Percentage * 100);
          if (file.Percentage >= 100) {
            file.Status = UploadStatus.LOADED;
          }
        },
      }); // Upload completed successfully file.Status = UploadStatus.COMPLETED;
    } catch (error) {
      // Handle errors here
      file.Status = UploadStatus.FAILED;
      console.error("Error uploading file:", error); // You can also set an error message or code to provide more information about the error.
    }
  }

  // static async uploadFile(url: string, file: FileModel): Promise<void> {
  //   const blockBlobClient = new BlockBlobClient(url);
  //   const type = mime.getType(file.Name) || "application/octet-stream";
  //   await blockBlobClient.uploadData(file.File, {
  //     blobHTTPHeaders: { blobContentType: type },
  //     onProgress: (ev) => {
  //       file.Percentage = ev.loadedBytes / file.File.size;
  //       file.Percentage = Math.floor(file.Percentage * 100);

  //       if (file.Percentage >= 100) {
  //         file.Status = UploadStatus.LOADED;
  //       }
  //     },
  //   });
  // }

  static async downloadBlobToFile(
    item: FileModel,
  ): Promise<BlobDownloadResponseParsed> {
    const blobClient = new BlockBlobClient(item.DownloadURL);
    return blobClient.download();
  }
}
