<aecom-modal
  title="Send Back to Coordinator"
  class="modal-md"
  [canClose]="false"
  theme="light"
  data-cy="modal"
>
  <div class="modal-body">
    <h4>Please add a note below with instructions for the Coordinator to address prior to resubmitting.</h4>

    <aecom-form-item>
      <aecom-textbox
        rows="3"
        [theme]="'light'"
        [isMultiLine]="true"
        [(ngValue)]="comments"
        [placeholder]="defaultText"
      ></aecom-textbox>
    </aecom-form-item>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">
        Cancel
      </aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">
        Submit
      </aecom-button>
    </div>
  </div>
</aecom-modal>
