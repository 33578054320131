import { Injectable } from "@angular/core";
import { ApplicationRole } from "../api-generated";
import BaseService from "./base.service";
import { rfiAppId } from "@shared/staticValue";

@Injectable({
  providedIn: "root",
})
export default class LocalApplicationRoleService extends BaseService<ApplicationRole> {
  public getAppRoles(): ApplicationRole[] {
    const items = this.getItems();
    if(!items)
    {
      return items;
    } else{
      return items.filter((role: ApplicationRole) => {
        return role.ApplicationId === rfiAppId
      });
    }
    
  }

  public getRoleById(id: string): string {
    return this.getAppRoles().find((role)=>{return role.Guid === id})?.Role ?? '';
  }
}
