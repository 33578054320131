<ng-container>
  <aecom-form-group columnSpan="12" columns="1" title="RFI Reopen Request" [ngClass]="{'hideHeader': !readonly}">
    <aecom-form-item [label]="'Directed By'" columnSpan="1" [required]="true" *ngIf="isDoc && !readonly">
      <aecom-dropdown
        data-cy="directedBy"
        [theme]="'light'"
        placeholder="Select"
        [data]="userList"
        (ngValueChange)="setUserSelect($event)"
        [validate]="attemptToIssue && (!reopen.DirectedBy || reopen.DirectedBy==='')"
      ></aecom-dropdown>
    </aecom-form-item>
    <aecom-form-item class="mb12" [label]="'Change Type'" columnSpan="1" [required]="true">
      <ng-container *ngFor="let item of changeTypes">
        <aecom-checkbox *ngIf="!readonly" class="cbxReopen" [(ngValue)]="item.checked" (ngValueChange)="setChangeType($event)">{{item.name}}</aecom-checkbox>
        <div *ngIf="readonly" class="cbxReopen">
          <div class="input-div">
            <input type="checkbox" [checked]="item.checked" disabled> {{item.name}}
          </div>
        </div>
      </ng-container>
      <div class="error-message" *ngIf="attemptToIssue && (!reopen.ChangeType || reopen.ChangeType.length === 0)">
        Required Field
      </div>
    </aecom-form-item>
    <aecom-form-item *ngIf="showOtherType()" columnSpan="1" [label]="'Other Change Type'">
      <aecom-textbox
        *ngIf="!readonly"
        data-cy="otherType"
        [theme]="'light'"
        [(ngValue)]="reopen.OtherChangeType"
      ></aecom-textbox>
      <div *ngIf="readonly" class="itemText">
        {{reopen.OtherChangeType}}
      </div>
    </aecom-form-item>
    <aecom-form-item [label]="'Detailed Reason for Change'" columnSpan="1" [required]="true">
      <aecom-textbox
        *ngIf="!readonly"
        data-cy="reason"
        rows="3"
        [theme]="'light'"
        [notEmpty]="true"
        [maxlength]="reasonMaxLength"
        [isMultiLine]="true"
        [(ngValue)]="reopen.Reason"
        [validate]="
          attemptToIssue &&
          (!reopen.Reason?.length ||
            getReasonLength() > reasonMaxLength)
        "
      ></aecom-textbox>
      <div *ngIf="readonly" class="itemText" [innerHtml]="getReasonText()">
        
      </div>
    </aecom-form-item>
    <!-- <aecom-form-item [label]="'Revision Significance'" columnSpan="1" [required]="true">
      <ng-container *ngFor="let item of significances">
        <aecom-radio *ngIf="!readonly" class="rdoReopen" [ngValue]="item" group="significance" (change)="setSignificance(item)">{{item.name}}</aecom-radio>
        <div *ngIf="readonly" class="rdoReopen">
          <div class="input-div">
            <input type="radio" [checked]="item.checked" disabled> {{item.name}}
          </div>
        </div>
        
      </ng-container>
      <div class="error-message" *ngIf="attemptToIssue && !reopen.Significance">
        Required Field
      </div>
    </aecom-form-item> -->
  </aecom-form-group>
</ng-container>

