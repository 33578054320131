<aecom-modal data-cy="modal" title="Reopen RFI?" class="modal-md" [canClose]="false" theme="light">
  <div class="modal-body">
    <h4 style="color: #000;">Please fill out below info for the reopen request, once proceeded, all related personnel on this RFI will be notified by email.</h4>
    <br />
    <h4 style="color: #000;">Once the acknowledgement from design builder is complete, the RFI will be reopened back to the last disposition step for review.</h4>
    <reopen-detail [reopen]="item.rfi_reopen" [attemptToIssue]="attemptToIssue"></reopen-detail>
    <aecom-form-group columnSpan="12" columns="1" *ngIf="item.tempId" class="hideHeader">
      <aecom-form-item columnSpan="1" label="Upload Supporting Documents">
        <aecom-upload
          [isNewStyle]="true"
          [multiFiles]="true"
          [canDelete]="true"
          [data]="fileList"
          [theme]="'light'"
          (ngValueChange)="importFile($event)"
          (ngDeleteChange)="deleteFile($event)"
        ></aecom-upload>
        <div class="error-message" *ngIf="!fileListNameValid">
          File Name cannot include special characters other than '-', '_', '.', '@'
        </div>
      </aecom-form-item>
    </aecom-form-group>
  </div>

  <div class="modal-footer">
    <div></div>

    <div>
      <aecom-button innerClass="orange lg outline" (click)="cancel()">Cancel</aecom-button>

      <aecom-button innerClass="blue lg solid" (click)="save()">Proceed</aecom-button>
    </div>
  </div>
</aecom-modal>
