import { IPRFIItem, IRFIReopenApprovalStep, RfiReopen } from "../api-generated";
import RFIReopenApprovalDetail from "./rfiReopenApprovalDetail";
import RFIUpdateBase from "./rfiUpdateBase";

export default class RFIReopenApproval
  extends RFIUpdateBase
  implements IRFIReopenApprovalStep
{
  public rfi_reopen: RFIReopenApprovalDetail;

  constructor(contractId: string, sub: IPRFIItem, reopen:RfiReopen) {
    super(contractId, sub);
    this.rfi_reopen = new RFIReopenApprovalDetail(reopen);
  }
}
